import React, { type ReactElement } from 'react'
import TeacherHomeworkSolutionForm from '../../components/teachers/homeworks/TeacherHomeworkSolutionForm'
import { API_TEACHER_EDIT_HOMEWORK_SOLUTION_PATH } from '../../constants/apiRoutes'
import { useApiFetch } from '../../hooks/api/useApiFetch'
import { useParams } from 'react-router-dom'

const TeacherHomeworkSolutionEditView = (): ReactElement => {
  const queryParams = useParams()
  const apiUrl = API_TEACHER_EDIT_HOMEWORK_SOLUTION_PATH.replace(':id', queryParams.id as string)
  const { data: homeworkSolution } = useApiFetch({ url: apiUrl })

  return (
    <div className="grid h-screen">
      <TeacherHomeworkSolutionForm url={apiUrl} homeworkSolution={homeworkSolution} method={'patch'}/>
    </div>
  )
}

export default TeacherHomeworkSolutionEditView
