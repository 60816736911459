import React, { useContext, useRef, type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useApiPost } from '../../hooks/api/useApiPost'
import { MessagesContext } from '../../contexts/messages/MessagesContext'
import { API_MESSAGES_PATH } from '../../constants/apiRoutes'

const CreateMessageForm = (): ReactElement => {
  const { sendData, setInput, input } = useApiPost({ url: API_MESSAGES_PATH })
  const inputRef = useRef<HTMLInputElement>(null)

  const { messages, setMessages, receiverEmail } = useContext(MessagesContext)
  const { t } = useTranslation('translations')

  const createMessage = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    const message = await sendData({ additionalParams: { receiver_email: receiverEmail } })

    setMessages([message, ...messages])
    if (inputRef.current) inputRef.current.value = ''
    setInput({})
  }

  return (
    <form onSubmit={createMessage}>
      <div className="flex justify-between p-1">
        <input
          type="text"
          className="w-full border rounded-lg p-2"
          ref={inputRef}
          onChange={(e) => { setInput({ ...input, body: e.target.value }) }}
        />
        <button
          type="submit"
          className="ml-2 bg-blue-500 text-white rounded-lg p-2"
          disabled={!input.body}
        >
          {t('messages_view.create_form.submit_button')}
        </button>
      </div>
    </form>
  )
}

export default CreateMessageForm
