import React, { useState, useEffect, type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import Dropdown from '../../assets/icons/dropdown.svg'
import { type PaginationProps } from '../../constants/types'

const Pagination = ({ paginationMeta, fetchData, filters = {}, sortParams = {} }: PaginationProps): ReactElement => {
  const { t } = useTranslation('translations')
  const [inputPage, setInputPage] = useState<string>('')

  useEffect(() => {
    setInputPage('')
  }, [paginationMeta?.page])

  const fetchPage = async (page: number): Promise<void> => {
    if (!isPageAvailable(page)) return

    // Include filters and sort parameters when fetching a new page
    await fetchData({ page, ...filters, ...sortParams })
  }

  const pageClass = (page: number): string => {
    const defaultClass = 'flex items-center'
    return isPageAvailable(page) ? `${defaultClass} cursor-pointer` : `${defaultClass} opacity-50`
  }

  const isPageAvailable = (page: number): boolean => {
    return page > 0 && page <= paginationMeta?.pages
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setInputPage(event.target.value)
  }

  const handleKeyPress = async (event: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
    if (event.key === 'Enter') {
      const page = parseInt(inputPage)
      if (isNaN(page)) return
      await fetchPage(page)
    }
  }

  const handleInputFocus = (event: React.FocusEvent<HTMLInputElement>): void => {
    event.target.placeholder = ''
  }

  const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    if (inputPage === '') {
      event.target.placeholder = String(paginationMeta?.page)
    }
  }

  return (
    <div className="flex justify-between">
      <div />
      <div className="flex gap-6 font-['Inter'] text-sm items-center">
        <div
          onClick={async () => { await fetchPage(paginationMeta?.page - 1) }}
          className={pageClass(paginationMeta?.page - 1)}
        >
          <img src={Dropdown} alt="previous.svg" className="rotate-90" />
        </div>
        <div>
          <input
            type="text"
            value={inputPage}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onKeyPress={handleKeyPress}
            className="w-[38px] h-[38px] bg-white rounded-md border border-gray-200 text-center inline-flex"
            placeholder={String(paginationMeta?.page)}
          />
        </div>
        <div>
          {t('pagination.from')}
        </div>
        <div>
          {paginationMeta?.pages}
        </div>
        <div
          onClick={async () => { await fetchPage(paginationMeta?.page + 1) }}
          className={pageClass(paginationMeta?.page + 1)}
        >
          <img src={Dropdown} alt="next.svg" className="rotate-[270deg]" />
        </div>
      </div>
    </div>
  )
}

export default Pagination
