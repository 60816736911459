import React, { type ReactElement } from 'react'
import { Scheduler } from '@aldabil/react-scheduler'
import { useApiFetch } from '../../hooks/api/useApiFetch'
import { API_TEACHER_CALENDAR_EVENTS_PATH, API_TEACHER_LESSON_INSTANCES_PATH } from '../../constants/apiRoutes'
import ParseCalendarItemsService from '../../services/calendar/ParseCalendarItemsService'

const TeacherDashboardView = (): ReactElement => {
  const { data: calendarEvents } = useApiFetch({ url: API_TEACHER_CALENDAR_EVENTS_PATH })
  const { data: lessonInstances } = useApiFetch({ url: API_TEACHER_LESSON_INSTANCES_PATH })

  return (
    <div className="p-10">
      <Scheduler view="week" events={ParseCalendarItemsService.call(calendarEvents, lessonInstances)} />
    </div>
  )
}

export default TeacherDashboardView
