import React, { type ReactElement } from 'react'
import { type SchoolListItemData } from '../../constants/types'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const SchoolListItem = ({ school }: { school: SchoolListItemData }): ReactElement => {
  const { t } = useTranslation('translations')

  return (
    <div className="card grid grid-cols-4 lg:grid-cols-10 py-3 px-5 text-sm flex items-center">
      <div className="col-span-1 truncate hidden lg:block">{school?.id}</div>
      <div className="col-span-4 truncate hidden lg:block">{school?.name}</div>
      <div className="col-span-4 opacity-50 truncate hidden lg:block">{school?.patron}</div>
      <div className="col-span-3 block lg:hidden pr-2">
        ID {school?.id}
        <br />
        <div className="truncate">{school?.name}</div>
        <div className="opacity-50 truncate">{school?.patron}</div>
      </div>
      <div className="col-span-1">
        <Link
          to=""
          className="px-4 py-1 w-max border border-1 border-[#3758F9] rounded-2xl text-[#3758F9]"
        >
          {t('admin.schools.edit_button')}
        </Link>
      </div>
    </div>
  )
}

export default SchoolListItem
