import React, { type ReactElement } from 'react'
import Dropdown from '../../../assets/icons/dropdown.svg'

const AdminNavbarLink = ({ tab }: { tab: string }): ReactElement => {
  return (
    <div className="flex items-center justify-between">
      {tab}
      <img src={Dropdown} alt="Dropdown" className="rotate-[270deg] px-2 h-2" />
    </div>
  )
}

export default AdminNavbarLink
