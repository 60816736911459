import React, { type ReactElement, useState } from 'react'
import { type MessageData, type ConversationData } from '../../constants/types'
import { MessagesContext } from './MessagesContext'

const MessagesContextProvider = ({ children }: { children: ReactElement }): ReactElement => {
  const [messages, setMessages] = useState([] as MessageData[])
  const [receiverEmail, setReceiverEmail] = useState('')
  const [conversations, setConversations] = useState([] as ConversationData[])
  const [messagesPagination, setMessagesPagination] = useState({})
  const [messagesPaginationMeta, setMessagesPaginationMeta] = useState({})

  return (
    <MessagesContext.Provider value={
      {
        messages,
        setMessages,
        receiverEmail,
        setReceiverEmail,
        conversations,
        setConversations,
        messagesPagination,
        setMessagesPagination,
        messagesPaginationMeta,
        setMessagesPaginationMeta
      }
    }>
      {children}
    </MessagesContext.Provider>
  )
}

export default MessagesContextProvider
