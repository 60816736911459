import React, { type ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { ADMIN_EDIT_DOCUMENT_PATH } from '../../../constants/adminRoutes'
import { type DocumentItemData } from '../../../constants/types'
import { useTranslation } from 'react-i18next'
import { useParseDate } from '../../../hooks/useParseDate'

const DocumentListItem = ({ document }: { document: DocumentItemData }): ReactElement => {
  const { t } = useTranslation('translations')
  const { toLocalDate } = useParseDate()

  return (
    <div className="card grid grid-cols-4 lg:grid-cols-12 py-3 px-5 text-sm flex items-center">
      <div className="col-span-1 truncate hidden lg:block">{document?.id}</div>
      <div className="col-span-3 truncate hidden lg:block">{document?.name}</div>
      <div className="col-span-4 opacity-50 truncate hidden lg:block">{document?.files.map((file) => file.filename).join(', ')}</div>
      <div className="col-span-3 truncate hidden lg:block">{toLocalDate(document?.created_at)}</div>
      <div className="col-span-3 block lg:hidden">
        ID {document?.id}
        <br />
        <div className="truncate">{document?.name}</div>
        <div className="opacity-50">{document?.files.map((file) => file.filename).join(', ')}</div>
        <br />
        Utworzono {toLocalDate(document?.created_at)}
      </div>
      <div className="col-span-1">
        <Link
          to={ADMIN_EDIT_DOCUMENT_PATH.replace(':id', document?.id?.toString())}
          className="px-4 py-1 w-max border border-1 border-[#3758F9] rounded-2xl text-[#3758F9]"
        >
          {t('admin.documents.edit_button')}
        </Link>
      </div>
    </div>
  )
}

export default DocumentListItem
