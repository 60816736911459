import React, { type ReactElement } from 'react'
import TeacherHomeworkWithSolutionsList from '../../components/teachers/homeworks/TeacherHomeworkWithSolutionsList'
import { API_TEACHER_HOMEWORK_PATH } from '../../constants/apiRoutes'
import { useApiFetch } from '../../hooks/api/useApiFetch'
import { useParams } from 'react-router-dom'
import { useParseDate } from '../../hooks/useParseDate'
import { useTranslation } from 'react-i18next'

const TeacherHomeworkView = (): ReactElement => {
  const queryParams = useParams()
  const apiUrl = API_TEACHER_HOMEWORK_PATH.replace(':id', queryParams.id as string)
  const { data: homework, fetchData } = useApiFetch({ url: apiUrl, paginated: true })
  const { toLocalDateWithHour } = useParseDate()
  const { t } = useTranslation('translations')

  return (
    <div className="grid grid-auto-rows auto-rows-min h-screen">
      <div className="pb-10 pt-8 mx-1 lg:px-12 px-5 text-left">
        <h1 className="text-3xl text-left">
          {homework?.data?.title}
        </h1>
        <div className="flex flex-row space-x-4 lg:mx-4 pt-10 text-sm">
          <div className="flex flex-col space-y-3 items-end">
            <div className="lg:text-sm text-base">
              {t('teachers.homeworks.heading.deadline')}
            </div>
            <div className="lg:text-sm text-base">
              {t('teachers.homeworks.heading.files_deadline')}
            </div>
          </div>
          <div className="flex flex-col space-y-3 items-start">
            <div className="lg:text-sm text-base">
              {toLocalDateWithHour(homework?.data?.deadline)}
            </div>
            <div className="lg:text-sm text-base">
              {toLocalDateWithHour(homework?.data?.file_upload_deadline)}
            </div>
          </div>
        </div>
      </div>
      <TeacherHomeworkWithSolutionsList homework={homework?.data} paginationMeta={homework?.meta} fetchData={fetchData}/>
    </div>
  )
}

export default TeacherHomeworkView
