import React, { type ReactElement } from 'react'
import { type MessageData } from '../../constants/types'

const MessageListItem = ({ message }: { message: MessageData }): ReactElement => {
  const justifyMessage = (): string => {
    return message.is_sender ? 'justify-end' : 'justify-start'
  }

  return (
    <div className={`flex ${justifyMessage()}`}>
      <h1 className="m-1 px-3 w-auto rounded-xl text-white bg-blue-500">{message.body}</h1>
    </div>
  )
}

export default MessageListItem
