import React, { type ReactElement, useState, useEffect } from 'react'
import { map, omit } from 'underscore'
import { type HomeworkData, type UseApiGetReturnParams, type HomeworksCounters, type StudentData } from '../../../constants/types'
import { useTranslation } from 'react-i18next'
import TeacherHomeworkWithSolutionsListItem from './TeacherHomeworkWithSolutionsListItem'
import Pagination from '../../common/Pagination'
import TabFilter from '../../filters/tabs/TabFilter'
import HSSelect from '@preline/select'

const TeacherHomeworkWithSolutionsList = (
  { homework, paginationMeta, fetchData }:
  { homework: HomeworkData, paginationMeta: Record<string, any>, fetchData: UseApiGetReturnParams['fetchData'] }
): ReactElement => {
  const { t } = useTranslation('translations')
  const [sortParams, setSortParams] = useState<{ sort_by: string, sort_order: string }>({ sort_by: '', sort_order: '' })
  const [filters, setFilters] = useState<{ filter_by: string }>({ filter_by: '' })
  const [counters, setCounters] = useState({} as HomeworksCounters)

  useEffect(() => {
    fetchData({ ...sortParams, ...filters }).then((result: any) => {
      setCounters(result?.meta?.counters)
    })
    HSSelect.autoInit()
  }, [sortParams, filters])

  const handleSort = (field: string, order: string): void => {
    setSortParams({ sort_by: field, sort_order: order })
  }

  const handleTabSetFilters = (value: string | null): void => {
    if (value) {
      setFilters((prevFilters: any) => ({ ...prevFilters, filter_by: value }))
    } else {
      setFilters((prevFilters: any) => omit(prevFilters, 'filter_by'))
    }
  }

  return (
    <div className="w-full flex items-start justify-center">
      <div className="w-screen lg:pb-10 lg:pt-5 lg:px-14">
        <div className="flex space-x-2">
          <div className="justify-start items-center gap-3 inline-flex mb-6 lg:w-auto w-full">
            <div className="h-fit lg:p-1 px-3 bg-white lg:rounded-xl justify-start items-start gap-2.5 flex w-full shadow-md">
              <div className="overflow-x-auto lg:overflow-visible lg:py-0 py-2">
                <div className="flex lg:flex-row flex-nowrap lg:w-max w-auto">
                  <TabFilter
                    setFilters={() => { handleTabSetFilters(null) } }
                    text='Wszystkie'
                    counter={counters?.sum}
                    active={!filters.filter_by}
                  />
                  <TabFilter
                    setFilters={() => { handleTabSetFilters('to_check') } }
                    text='Do sprawdzenia'
                    counter={counters?.to_check}
                    active={filters.filter_by === 'to_check'}
                  />
                  <TabFilter
                    setFilters={() => { handleTabSetFilters('checked') } }
                    text='Sprawdzone'
                    counter={counters?.checked}
                    active={filters.filter_by === 'checked'}
                  />
                  <TabFilter
                    setFilters={() => { handleTabSetFilters('not_sent') } }
                    text='Niewysłane'
                    counter={counters?.not_sent}
                    active={filters.filter_by === 'not_sent'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="lg:hidden block mb-5 px-5">
          <div className="relative">
            <select data-hs-select='{
                "placeholder": "Sortuj według...",
                "toggleTag": "<button type=\"button\" aria-expanded=\"false\"></button>",
                "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 ps-4 pe-9 flex gap-x-2 text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-base focus:outline-none focus:ring-2 focus:ring-blue-500",
                "dropdownClasses": "mt-2 z-50 w-full max-h-72 p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto shadow-xl",
                "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100",
                "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"shrink-0 size-3.5 text-blue-600 \" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" strokeWidth=\"2\" strokeLinecap=\"round\" strokeLinejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
            }'
            onChange={(e) => {
              const [field, order] = e.target.value.split('-')
              handleSort(field, order)
            }}
            defaultValue=""
            >
              <option value="" disabled>{t('Sortuj według...')}</option>
              <option value="student_name-asc">Użytkownik A-Z</option>
              <option value="student_name-desc">Użytkownik Z-A</option>
              <option value="deadline-asc">Data wysłania rosnąco</option>
              <option value="deadline-desc">Data wysłania malejąco</option>
            </select>

            <div className="absolute top-1/2 end-2.5 -translate-y-1/2">
              <svg className="shrink-0 h-4 w-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="m7 15 5 5 5-5"></path>
                <path d="m7 9 5-5 5 5"></path>
              </svg>
            </div>
          </div>
        </div>

        <div className="lg:rounded-md lg:shadow-md lg:border border-gray-200 lg:mb-0 mb-36">
          <div className="card grid grid-cols-12 lg:border lg:border-gray-100 border-b-gray-300 lg:px-5 lg:py-3 bg-gray-100 bg-gray-100 text-sm text-gray-500 rounded-t-md">
            <div className="col-span-3 lg:block hidden">
              <div className="flex space-x-2" onClick={() => { handleSort('student_name', sortParams.sort_order === 'asc' ? 'desc' : 'asc') }}>
                <div className="font-['DM Sans']">
                  {t('teachers.homeworks.heading.student')}
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5 hover:cursor-pointer">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                </svg>
              </div>
            </div>
            <div className="col-span-2 lg:block hidden">
              <div className="font-['DM Sans']">
                {t('teachers.homeworks.heading.status')}
              </div>
            </div>
            <div className="col-span-2 lg:block hidden">
              <div className="flex space-x-2" onClick={() => { handleSort('date_sent', sortParams.sort_order === 'asc' ? 'desc' : 'asc') }}>
                <div className="font-['DM Sans']">
                  {t('teachers.homeworks.heading.date_sent')}
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5 hover:cursor-pointer">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                </svg>
              </div>
            </div>
            <div className="col-span-2 lg:block hidden">
              <div className="font-['DM Sans']">
                {t('teachers.homeworks.heading.comment')}
              </div>
            </div>
          </div>
          <div className="lg:divide-y">
            {
              map(homework?.students, (student: StudentData): ReactElement => {
                return <TeacherHomeworkWithSolutionsListItem student={student} key={student.id} />
              })
            }
          </div>
          <div className="p-4 rounded-b-lg border border-transparent lg:border-t-gray-200">
            <Pagination
              paginationMeta={paginationMeta}
              fetchData={fetchData}
              filters={filters}
              sortParams={sortParams}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TeacherHomeworkWithSolutionsList
