import React, { useContext, type ReactElement } from 'react'
import { type ConversationData } from '../../constants/types'
import { useApiFetch } from '../../hooks/api/useApiFetch'
import { MessagesContext } from '../../contexts/messages/MessagesContext'
import { API_MESSAGES_PATH } from '../../constants/apiRoutes'

const SelectConversationItem = ({ conversation }: { conversation: ConversationData }): ReactElement => {
  const {
    setMessages,
    setReceiverEmail,
    setMessagesPaginationMeta,
    setMessagesPagination,
    messagesPagination
  } = useContext(MessagesContext)

  const { fetchData } = useApiFetch({ url: API_MESSAGES_PATH, onRender: false, paginated: true })

  const fetchMessages = async (): Promise<void> => {
    const fetchedMessages = await fetchData({ receiver_email: conversation.email, page: 1 })

    // FIXME: Debug why this is not working: setMessagesPagination({ ...messagesPagination, page: 1 })
    setMessagesPagination({ ...messagesPagination, page: 2 })
    setReceiverEmail(conversation.email)
    setMessages(fetchedMessages.data)
    setMessagesPaginationMeta(fetchedMessages.meta)
  }

  return (
    <div
      className="my-1 p-1 card border rounded-md flex justify-center cursor-pointer w-full"
      onClick={fetchMessages}
    >
      <div>{conversation.email}</div>
    </div>
  )
}

export default SelectConversationItem
