import React from 'react'
import { useApiFetch } from '../hooks/api/useApiFetch'
import {
  API_PINNED_NEWS_PATH,
  API_NEWS_PATH
} from '../constants/apiRoutes'
import Pagination from '../components/common/Pagination'
import NewsListItem from '../components/news/NewsListItem'
import { type NewsData } from '../constants/types'
import { isEmpty, map } from 'underscore'
import { useTranslation } from 'react-i18next'

const NewsView = (): React.ReactElement => {
  const { t } = useTranslation('translations')
  const { data: newsList, fetchData } = useApiFetch({ url: API_NEWS_PATH, paginated: true })
  const { data: pinnedNewsList } = useApiFetch({ url: API_PINNED_NEWS_PATH, paginated: true })

  return (
    <div className="flex justify-center lg:m-10 m-5 lg:mb-16 mb-32">
      <div className="space-y-4 lg:w-3/4 w-full">
        {
          !isEmpty(pinnedNewsList?.data) && (
            <>
              <div className="text-2xl font-bold leading-tight">
                {t('news.pinned.h1')}
              </div>
              {map(pinnedNewsList.data, (pinnedNews: NewsData) => <NewsListItem news={pinnedNews} key={pinnedNews.id} />)}
            </>
          )
        }
        <div className="text-2xl font-bold leading-tight">
          {t('news.h1')}
        </div>
        {map(newsList?.data, (news: NewsData) => <NewsListItem news={news} key={news.id} />)}
        <Pagination paginationMeta={newsList?.meta} fetchData={fetchData} />
      </div>
    </div>
  )
}

export default NewsView
