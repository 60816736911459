import React, { type ReactElement } from 'react'

const NewsFilters = ({ fetchNews, setQuery, query }: { fetchNews: () => void, setQuery: (prevQuery: any) => void, query: Record<string, string> }): ReactElement => {
  const filterNews = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault()
    fetchNews()
  }

  return (
    <form onSubmit={filterNews}>
      <div className="w-full flex justify-end p-5">
        <div className="flex flex-col items-center">
          <label>Data utworzenia od</label>
          <input
            type="date"
            value={query.created_at_from}
            className="border rounded-lg p-3 mx-5"
            onChange={(e) => { setQuery((prevQuery: any) => ({ ...prevQuery, created_at_from: e.target.value })) }}
          />
        </div>
        <div className="flex flex-col items-center">
          <label>Data utworzenia do</label>
          <input
            type="date"
            value={query.created_at_to}
            className="border rounded-lg p-3 mx-5"
            onChange={(e) => { setQuery((prevQuery: any) => ({ ...prevQuery, created_at_to: e.target.value })) }}
          />
        </div>

        <div className="flex flex-col items-center">
          <label>Data aktualizacji od</label>
          <input
            type="date"
            value={query.updated_at_from}
            className="border rounded-lg p-3 mx-5"
            onChange={(e) => { setQuery((prevQuery: any) => ({ ...prevQuery, updated_at_from: e.target.value })) }}
          />
        </div>
        <div className="flex flex-col items-center">
          <label>Data aktualizacji do</label>
          <input
            type="date"
            value={query.updated_at_to}
            className="border rounded-lg p-3 mx-5"
            onChange={(e) => { setQuery((prevQuery: any) => ({ ...prevQuery, updated_at_to: e.target.value })) }}
          />
        </div>

        <button type="submit" className="rounded-lg bg-primary px-5 py-2 text-white">Flitruj</button>
      </div>
      <hr />
    </form>
  )
}

export default NewsFilters
