import React, { type ReactElement, useState } from 'react'
import { type CurrentUserData } from '../../constants/types'
import { CurrentUserContext } from './CurrentUserContext'
import { LOGIN_PATH } from '../../constants/routes'
import { useApiFetch } from '../../hooks/api/useApiFetch'
import { API_USERS_PROFILE_PATH } from '../../constants/apiRoutes'
import { jwtDecode } from 'jwt-decode'

const CurrentUserContextProvider = ({ children }: { children: ReactElement }): ReactElement => {
  const [currentUser, setCurrentUser] = useState(null as CurrentUserData | null)

  const fetchCurrentUserId = (): string => {
    const token = localStorage.getItem('access_token')
    const currentUserData = (token ? jwtDecode(token) : null) as CurrentUserData

    return currentUserData?.user_id?.toString()
  }

  const { fetchData } = useApiFetch({ url: API_USERS_PROFILE_PATH.replace(':id', fetchCurrentUserId()), onRender: false })

  const logout = (): void => {
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    window.location.href = LOGIN_PATH
  }

  const fetchCurrentUser = async (): Promise<void> => {
    const response = await fetchData()

    setCurrentUser(
      {
        user_id: response.id,
        role: response.role,
        name: response.name,
        avatar_url: response.avatar?.url,
        admin: response.admin
      }
    )
  }

  return (
    <CurrentUserContext.Provider value={{ currentUser, setCurrentUser, logout, fetchCurrentUser }}>
      {children}
    </CurrentUserContext.Provider>
  )
}

export default CurrentUserContextProvider
