import React, { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useApiFetch } from '../../../hooks/api/useApiFetch'
import { map } from 'underscore'
import { Link } from 'react-router-dom'
import { API_ADMIN_DOCUMENTS_PATH } from '../../../constants/apiRoutes'
import { ADMIN_NEW_DOCUMENT_PATH } from '../../../constants/adminRoutes'
import { type DocumentItemData } from '../../../constants/types'
import Plus from '../../../assets/icons/plus.svg'
import Pagination from '../../../components/common/Pagination'
import DocumentListItem from '../../../components/admin/documents/DocumentListItem'

const DocumentsView = (): ReactElement => {
  const { data: documents, fetchData } = useApiFetch({ url: API_ADMIN_DOCUMENTS_PATH, paginated: true })
  const { t } = useTranslation('translations')

  return (
    <div className="w-full flex justify-center">
      <div className="w-5/6 pb-10 pt-10">
        <div className="flex justify-between pb-8 pt-4">
          <div className="text-xl font-bold font-['Inter'] leading-tight lg:block hidden">
            {t('admin.documents.h1')}
          </div>
          <div>
            <Link
              to={ADMIN_NEW_DOCUMENT_PATH}
              className="px-5 py-3 w-max bg-blue-500 hover:bg-blue-700 rounded py-3 px-5 text-white flex"
            >
              {t('admin.documents.add_button')}
              <img src={Plus} alt="plus.svg" className="ml-2" />
            </Link>
          </div>
        </div>
        <div className="shadow-lg rounded-lg">
          <div className="card border border-gray-100 grid lg:grid-cols-12 rounded-t-lg py-6 px-5 bg-[#F9FAFB]">
            <div className="font-['Inter'] col-span-1 lg:block hidden">{t('admin.documents.attributes.id')}</div>
            <div className="font-['Inter'] col-span-3 lg:block hidden">{t('admin.documents.attributes.name')}</div>
            <div className="font-['Inter'] col-span-4 lg:block hidden">{t('admin.documents.attributes.filename')}</div>
            <div className="font-['Inter'] col-span-3 lg:block hidden">{t('admin.documents.attributes.created_at')}</div>
            <div className="font-['Inter'] font-extrabold lg:hidden block">{t('admin.documents.h1')}</div>
            <div className="font-['Inter'] col-span-1" />
          </div>
          <div className="border border-gray-100 divide-y">
            {
              map(documents?.data, (document: DocumentItemData): ReactElement => {
                return <DocumentListItem document={document} key={document.id} />
              })
            }
          </div>
          <div className="border border-gray-100">
            <div className="p-4 rounded-lg shadow-lg">
              <Pagination paginationMeta={documents?.meta} fetchData={fetchData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DocumentsView
