import React, { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import TeacherHomeworkForm from '../../components/teachers/homeworks/TeacherHomeworkForm'
import { API_TEACHER_EDIT_HOMEWORK_PATH } from '../../constants/apiRoutes'
import { useApiFetch } from '../../hooks/api/useApiFetch'
import { useParams } from 'react-router-dom'

const TeacherHomeworkEditView = (): ReactElement => {
  const { t } = useTranslation('translations')
  const queryParams = useParams()
  const apiUrl = API_TEACHER_EDIT_HOMEWORK_PATH.replace(':id', queryParams.id as string)
  const { data: homework } = useApiFetch({ url: apiUrl })

  return (
    <div className="grid h-screen" >
      <h1 className="lg:text-3xl text-2xl text-left pb-10 pt-8 mx-1 lg:px-12 px-5">{t('teachers.homeworks_edit_view.header')}</h1>
      <TeacherHomeworkForm url={apiUrl} homework={homework} method={'patch'}/>
    </div>
  )
}

export default TeacherHomeworkEditView
