import React, { type ReactElement, useContext, useEffect } from 'react'
import Navbar from './Navbar'
import FooterMobileNavbar from './navbar/FooterMobileNavbar'
import Flash from './Flash'
import { Outlet } from 'react-router-dom'
import { CurrentUserContext } from '../../contexts/users/CurrentUserContext'
import { FlashContext } from '../../contexts/flash/FlashContext'
import { MobileMenuContext } from '../../contexts/common/MobileMenuContext'
import ActiveTabContextProvider from '../../contexts/common/ActiveTabContextProvider'
import LessonViewContextProvider from '../../contexts/common/LessonViewContextProvider'
import NavigationBreadcrumbs from './NavigationBreadcrumbs'
import NotReadRequiredContextProvider from '../../contexts/news/NotReadRequiredNewsContextProvider'
import { LOGIN_PATH } from '../../constants/routes'

const Layout = (): ReactElement => {
  const { currentUser, fetchCurrentUser } = useContext(CurrentUserContext)
  const { flash } = useContext(FlashContext)
  const { renderMobileNavbar } = useContext(MobileMenuContext)

  useEffect(() => {
    if (currentUser ?? window.location.pathname === LOGIN_PATH) return

    void fetchCurrentUser()
  }, [flash])

  return (
    <>
      <ActiveTabContextProvider>
        <NotReadRequiredContextProvider>
          <LessonViewContextProvider>
            <>
              <div>
                <style>{'body { background-color: #FAFAF9; }'}</style>
                {currentUser && <Navbar />}
                <Flash visible={Boolean(flash.message)} />
                {currentUser && <NavigationBreadcrumbs />}
                <main>
                  <Outlet />
                </main>
                {currentUser && !renderMobileNavbar && <FooterMobileNavbar />}
              </div>
            </>
          </LessonViewContextProvider>
        </NotReadRequiredContextProvider>
      </ActiveTabContextProvider>
    </>
  )
}

export default Layout
