import React, { type ReactElement, useContext, useState } from 'react'
import { useParseDate } from '../../hooks/useParseDate'
import { useApiPost } from '../../hooks/api/useApiPost'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { map } from 'underscore'
import DefaultAvatar from '../../assets/default_avatar.svg'
import { CurrentUserContext } from '../../contexts/users/CurrentUserContext'
import Dropdown, { DropdownHead, DropdownItem, DropdownMenu } from '../common/Dropdown'
import CommentReplyItem from './CommentReplyItem'
import CommentForm from './CommentForm'
import { USER_PROFILE_PATH } from '../../constants/routes'
import { API_NEWS_COMMENTS_REPLIES_PATH, API_NEWS_EDIT_COMMENTS_PATH } from '../../constants/apiRoutes'
import { type CommentData } from '../../constants/types'

const CommentItem = ({ comment }: { comment: CommentData }): ReactElement => {
  const { toShortHumanizedDate } = useParseDate()
  const [showReplyForm, setShowReplyForm] = useState(false)
  const [visibleRepliesCount, setVisibleRepliesCount] = useState(3)
  const queryParams = useParams()
  const { t } = useTranslation('translations')
  const { currentUser } = useContext(CurrentUserContext)

  const { sendData: sendDeleteComment } = useApiPost({})

  const createReplyApiUrl = API_NEWS_COMMENTS_REPLIES_PATH.replace(':comment_id', comment.id.toString()).replace(':news_id', queryParams.id as string)

  const deleteComment = async (commentId: string): Promise<void> => {
    const deleteCommentApiUrl = API_NEWS_EDIT_COMMENTS_PATH.replace(':id', commentId)
    await sendDeleteComment({ method: 'delete', apiUrl: deleteCommentApiUrl })

    window.location.reload()
  }

  return (
    <>
      <article className="p-6 text-base bg-white rounded-lg">
        <footer className="flex justify-between items-center mb-2">
          <div className="flex items-center">
            <Link
              to={USER_PROFILE_PATH.replace(':id', comment.written_by.id.toString())}
              className="inline-flex items-center mr-3 text-sm text-gray-900 font-semibold"
            >
              <img
                className="mr-2 w-6 h-6 rounded-full"
                src={comment.written_by.avatar?.url ?? DefaultAvatar}
                alt={comment.written_by.name}
              />
              {comment.written_by.name}
            </Link>
            <p className="text-sm text-gray-600">
              <time dateTime={comment.created_at.toString()}>{toShortHumanizedDate(comment.created_at)}</time>
            </p>
          </div>
          {
            currentUser?.admin && (
              <Dropdown>
                <DropdownHead>
                  <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                    viewBox="0 0 16 3">
                    <path
                      d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z"/>
                  </svg>
                </DropdownHead>
                <DropdownMenu>
                  <DropdownItem action={async () => { await deleteComment(comment.id.toString()) }} text={t('comments.delete')}/>
                </DropdownMenu>
              </Dropdown>
            )
          }

        </footer>
        <p className="text-gray-500">{comment.body}</p>
        <div className="flex items-center mt-4 space-x-4">
          <button type="button"
            onClick={() => { setShowReplyForm(true) }}
            className="flex items-center text-sm text-gray-500 hover:underline font-medium">
            <svg className="mr-1.5 w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 20 18">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                d="M5 5h5M5 8h2m6-3h2m-5 3h6m2-7H2a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h3v5l5-5h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z"/>
            </svg>
            <div>
              {t('comments.reply')}
            </div>
          </button>
        </div>
      </article>
      {map(comment.replies.slice(0, visibleRepliesCount), (reply: CommentData) => {
        return <CommentReplyItem reply={reply} key={reply.id} deleteComment={async () => { await deleteComment(reply.id.toString()) }} />
      })}
      {
        visibleRepliesCount < comment.replies.length && (
          <div
            className="p-6 ml-6 lg:ml-12 text-sm text-blue-500 hover:underline font-medium cursor-pointer"
            onClick={() => { setVisibleRepliesCount(visibleRepliesCount + 3) }}
          >
            {t('comments.replies.load_more')}
          </div>
        )
      }

      {showReplyForm && <CommentForm apiUrl={createReplyApiUrl} reply={true} />}
    </>
  )
}

export default CommentItem
