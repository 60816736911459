import React, { type ReactElement, useEffect, useState, useRef } from 'react'
import { map } from 'underscore'
import { useApiPost } from '../../../hooks/api/useApiPost'
import { useApiFetch } from '../../../hooks/api/useApiFetch'
import { TEACHER_HOMEWORKS_PATH } from '../../../constants/teacherRoutes'
import { API_TEACHER_LESSONS_PATH } from '../../../constants/apiRoutes'
import { useTranslation } from 'react-i18next'
import { type LessonData, type HomeworkData } from '../../../constants/types'
import AttachmentsFileInput from '../../common/inputs/AttachmentsFileInput'
import MultiSelect from '../../common/MultiSelect'
import Select from '../../common/Select'
import DatePickerWithHour from '../../common/inputs/DatePickerWithHour'
import { useParseDate } from '../../../hooks/useParseDate'

const TeacherHomeworkForm = ({ url, homework, method = 'post' }: { url: string, homework?: HomeworkData, method?: string }): ReactElement => {
  const { setInput, sendDataWithFiles, validationErrors, input } = useApiPost({ url, redirect: TEACHER_HOMEWORKS_PATH, successFlashMessage: 'Pomyślnie zapisano' })
  const { data: lessons } = useApiFetch({ url: API_TEACHER_LESSONS_PATH })
  const { t } = useTranslation('translations')
  const [fileRequired, setFileRequired] = useState(false)
  const [showDeadlineDatePicker, setShowDeadlineDatePicker] = useState(false)
  const [showFileUploadDeadlineDatePicker, setShowFileUploadDeadlineDatePicker] = useState(false)
  const inputRef = useRef<HTMLDivElement>(null)
  const fileUploadInputRef = useRef<HTMLDivElement>(null)
  const { toLocalDateWithHour } = useParseDate()

  const handleDeadlineChange = (newDate: string): void => {
    setInput((prevInput: any) => {
      return { ...prevInput, deadline: newDate }
    })
    setShowDeadlineDatePicker(false)
  }

  const handleFileUploadDeadlineChange = (newDate: string): void => {
    setInput((prevInput: any) => {
      return { ...prevInput, file_upload_deadline: newDate }
    })
    setShowFileUploadDeadlineDatePicker(false)
  }

  useEffect(() => {
    const initialInput = {
      required: false,
      graded: true,
      file_required: false
    }

    if (homework) {
      setInput({
        ...homework,
        lesson_id: homework.lesson?.id
      })
    } else {
      setInput(initialInput)
    }
  }, [homework])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
        setShowDeadlineDatePicker(false)
      }
      if (fileUploadInputRef.current && !fileUploadInputRef.current.contains(event.target as Node)) {
        setShowFileUploadDeadlineDatePicker(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [inputRef, fileUploadInputRef])

  const saveHomework = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault()
    await sendDataWithFiles({ fileKeys: ['files_to_add', 'files_to_remove'], method })
  }

  return (
    <form onSubmit={saveHomework}>
      <div className="lg:px-12 px-5 lg:py-8 lg:pb-0 pb-8 mx-1">
        <div className='grid grid-cols-1 lg:grid-cols-2 pb-20 lg:pb-0'>
          <div>
            <label className="text-lg font-medium ml-1">
              {t('teachers.homeworks.inputs.lesson')}
            </label>
            <br />
            <div className='relative w-full lg:w-5/6 mt-3'>
              {homework
                ? <Select
                  id="type-select"
                  className="w-full shadow-sm rounded-lg"
                  value={homework?.lesson?.id.toString()}
                  options={map(lessons, (lesson: LessonData) => ({
                    value: lesson.id.toString(),
                    text: lesson?.class_units?.map(unit => unit.name).join(', ') + ' / ' + lesson.subject.name
                  }))}
                  placeholder={t('teachers.homeworks.inputs.lesson_placeholder')}
                  onChange={(selectedValue: string) => {
                    setInput((prevInput: any) => ({
                      ...prevInput,
                      lesson_id: selectedValue
                    }))
                  }}
                />
                : <MultiSelect
                  id="type-select"
                  search={true}
                  className="w-full shadow-sm rounded-lg"
                  options={map(lessons, (lesson: LessonData) => ({
                    value: lesson.id.toString(),
                    text: lesson?.class_units?.map(unit => unit.name).join(', ') + ' / ' + lesson.subject.name
                  }))}
                  placeholder={t('teachers.homeworks.inputs.lesson_placeholder')}
                  onChange={(selectedValues: string) => {
                    const selectedLessonIds = selectedValues.split(',')
                    setInput((prevInput: any) => ({
                      ...prevInput,
                      lesson_ids: selectedLessonIds
                    }))
                  }}
                />
              }
            </div>
            <div className="text-sm text-red-500 mt-3">
              {map(validationErrors.lesson_id, (error: string): string => `${t('teachers.homeworks.inputs.lesson')} ${error}`).join(', ')}
            </div>

            <br />

            <label className="text-lg font-medium ml-1">{t('teachers.homeworks.inputs.title')}</label>
            <br />
            <input
              value={input.title}
              className='px-2 py-1 border border-gray-300 rounded-lg mt-3 h-12 w-full lg:w-5/6 shadow-sm'
              onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, title: e.target.value })) }}
            />
            <div className="text-sm text-red-500 mt-3">
              {map(validationErrors.title, (error: string): string => `${t('teachers.homeworks.inputs.title')} ${error}`).join(', ')}
            </div>

            <br />

            <label className="text-lg font-medium ml-1">{t('teachers.homeworks.inputs.description')}</label>
            <br />
            <textarea
              rows={6}
              value={input.description}
              className='px-2 py-1 border border-gray-300 rounded-lg mt-3 mb-3 py-2 resize-none w-full lg:w-5/6 shadow-sm'
              onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, description: e.target.value })) }}
            />
            <div className="text-sm text-red-500">
              {map(validationErrors.description, (error: string): string => `${t('teachers.homeworks.inputs.description')} ${error}`).join(', ')}
            </div>
            <br />

            <label className="text-lg font-medium ml-1">{t('teachers.homeworks.inputs.deadline')}</label>
            <br />
            <div className="pb-10 pt-2" ref={inputRef}>
              <div className="relative w-5/6">
                <input
                  value={toLocalDateWithHour(input.deadline)}
                  className="bg-gray-50 border border-gray-300 cursor-pointer bg-white rounded-lg shadow-sm px-3 py-3 w-full"
                  onFocus={() => { setShowDeadlineDatePicker(true) }}
                  onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, deadline: e.target.value })) }}
                  readOnly
                />
                <div className="absolute inset-y-0 right-3 flex items-center pointer-events-none mx-2">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
                  </svg>
                </div>
              </div>

              {showDeadlineDatePicker && (
                <DatePickerWithHour
                  selectedDate={input.deadline ? new Date(input.deadline) : null}
                  onSelectDate={handleDeadlineChange}
                  closeDatePicker={() => { setShowDeadlineDatePicker(false) }}
                />
              )}
              <div className="text-sm text-red-500 mt-3">
                {map(validationErrors.deadline, (error: string): string => `${t('teachers.homeworks.inputs.deadline')} ${error}`).join(', ')}
              </div>
            </div>

            <label className="block text-lg font-medium ml-2">{t('teachers.homeworks.inputs.files')}</label>
            <div className='pb-6 mt-3 w-5/6'>
              <AttachmentsFileInput defaultInput={input.files} setInput={setInput} inputAddKey="files_to_add" inputRemoveKey="files_to_remove" />
              <div className="text-sm text-red-500 mt-3">
                {map(validationErrors.files_to_add, (error: string): string => `${t('teachers.homeworks.inputs.files')} ${error}`).join(', ')}
                {map(validationErrors.files_to_remove, (error: string): string => `${t('teachers.homeworks.inputs.files')} ${error}`).join(', ')}
              </div>
            </div>
          </div>

          <div className='lg:pt-20 lg:pl-28 lg:pt-0'>
            <label className="block text-2xl font-medium ml-2">{t('teachers.homeworks.inputs.files_sending')}</label>
            <div className='flex flex-col mt-6 lg:ml-2'>
              <label className="flex items-center cursor-pointer justify-start">
                <input
                  type="checkbox"
                  checked={input.file_required}
                  className="sr-only peer"
                  onChange={(e) => {
                    setInput((prevInput: any) => ({ ...prevInput, file_required: !input.file_required }))
                  }}
                />
                <div
                  className="relative w-11 h-6 bg-gray-200 ring-gray-500 rounded-full
                             peer peer-checked:ring-blue-600 bg-gray-500
                             peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full
                             peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px]
                             after:start-[2px] after:bg-white after:border-gray-300 after:rounded-full
                             after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"
                  onClick={() => { setFileRequired(!fileRequired) }}
                >
                </div>
                <div className="col-span-1 block lg:hidden"></div>
                <span className='text-base text-gray-600 col-span-4 lg:col-span-5 ml-3'>
                  {t('teachers.homeworks.inputs.add_file_required')}
                </span>
              </label>

              <div className={`pt-9 ${input.file_required === true ? '' : 'hidden'}`}>
                <label htmlFor="deadline" className="text-base text-gray-700">
                  {t('teachers.homeworks.inputs.file_upload_deadline')}
                </label>
                <div className="py-3 text-gray-500 text-sm">
                  {t('teachers.homeworks.inputs.file_upload_tip')}
                </div>
                <div className="pb-3 pt-2" ref={fileUploadInputRef}>
                  <div className="relative w-5/6">
                    <input
                      value={toLocalDateWithHour(input.file_upload_deadline)}
                      className="bg-gray-50 border border-gray-300 cursor-pointer bg-white rounded-lg shadow-sm px-3 py-3 w-full"
                      onFocus={() => { setShowFileUploadDeadlineDatePicker(true) }}
                      onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, file_upload_deadline: e.target.value })) }}
                      readOnly
                      onKeyDown={(e) => {
                        if (e.key === 'Backspace') {
                          setInput((prevInput: any) => ({ ...prevInput, file_upload_deadline: '' }))
                        }
                      }}
                    />
                    <div className="absolute inset-y-0 right-3 flex items-center pointer-events-none mx-2">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
                      </svg>
                    </div>
                  </div>

                  {showFileUploadDeadlineDatePicker && (
                    <DatePickerWithHour
                      selectedDate={input.file_upload_deadline ? new Date(input.file_upload_deadline) : null}
                      onSelectDate={handleFileUploadDeadlineChange}
                      closeDatePicker={() => { setShowFileUploadDeadlineDatePicker(false) }}
                    />
                  )}
                </div>
                <div className="text-sm text-red-500">
                  {map(validationErrors.file_upload_deadline, (error: string): string => `${t('teachers.homeworks.inputs.file_upload_deadline')} ${error}`).join(', ')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:sticky lg:bottom-0 mb-28 lg:mb-0 lg:bg-white w-full py-4 lg:border-t lg:border-gray-300 flex gap-3 justify-end items-center px-4 lg:px-8">
        <button
          type="button"
          onClick={() => { window.history.back() }}
          className="text-blue-800 hover:text-white flex bg-white justify-center py-2 px-3 border border-blue-700 rounded-lg hover:bg-blue-700"
        >
          Anuluj
        </button>

        <button
          type="submit"
          className="bg-blue-500 content-center hover:bg-blue-700 text-white py-3 px-3 rounded-lg md:w-fit"
        >
          <div className="flex gap-1 justify-center">
            {homework
              ? <>
                <span className="px-2">
                  {t('teachers.homeworks.inputs.update')}
                </span>
              </>
              : <>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>
                <span className="pr-2">
                  {t('teachers.homeworks.inputs.submit')}
                </span>
              </>
            }
          </div>
        </button>
      </div>
    </form>
  )
}

export default TeacherHomeworkForm
