import React, { type FormEvent, type ReactElement, useEffect } from 'react'
import { map } from 'underscore'
import { useTranslation } from 'react-i18next'
import { useApiPost } from '../../../hooks/api/useApiPost'
import { ADMIN_DOCUMENTS_PATH } from '../../../constants/adminRoutes'
import { type DocumentItemData } from '../../../constants/types'
import AttachmentsFileInput from '../../common/inputs/AttachmentsFileInput'

const DocumentForm = (
  { document, url, method = 'post' }:
  { url: string, document?: DocumentItemData, method?: string }
): ReactElement => {
  const { t } = useTranslation('translations')
  const { setInput, sendDataWithFiles, validationErrors, input } = useApiPost({ url, redirect: ADMIN_DOCUMENTS_PATH, successFlashMessage: 'Pomyślnie zapisano' })

  useEffect(() => {
    if (document) setInput((_p: any) => document)
  }, [document])

  const handleSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault()
    await sendDataWithFiles({ fileKeys: ['files_to_add', 'files_to_remove'], method })
  }

  return (
    <form method={method} onSubmit={handleSubmit}>
      <label className="text-lg font-medium ml-1">{t('admin.documents.attributes.name')}</label>
      <br />
      <input
        value={input.name}
        className='px-2 py-1 border border-gray-300 rounded-lg mb-3 mt-3 h-12 w-full'
        onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, name: e.target.value })) }}
      />
      <div className="text-sm text-red-500">
        {map(validationErrors.title, (error: string): string => `${t('admin.documents.attributes.name')} ${error}`).join(', ')}
      </div>
      <br />
      <AttachmentsFileInput defaultInput={input.files} setInput={setInput} inputAddKey="files_to_add" inputRemoveKey="files_to_remove" />
      <div className="text-sm text-red-500">
        {map(validationErrors.files_to_add, (error: string): string => `${t('admin.documents.attributes.files')} ${error}`).join(', ')}
        {map(validationErrors.files_to_remove, (error: string): string => `${t('admin.documents.attributes.files')} ${error}`).join(', ')}
      </div>
      <br />
      <div className="grid place-items-end">
        <button type="submit" className='bg-blue-500 hover:bg-blue-700 text-white py-3 px-6 rounded md:mt-10'>
          {t('admin.documents.submit_button')}
        </button>
      </div>
    </form>
  )
}

export default DocumentForm
