import React, { type ReactElement, useContext, useEffect } from 'react'
import SelectConversationItem from './SelectConversationItem'
import { type ConversationData } from '../../constants/types'
import { useTranslation } from 'react-i18next'
import { MessagesContext } from '../../contexts/messages/MessagesContext'
import { API_CONVERSATIONS_PATH, API_USERS_PATH } from '../../constants/apiRoutes'
import { useApiFetch } from '../../hooks/api/useApiFetch'

const MessagesSideBar = (): ReactElement => {
  const { conversations, setConversations } = useContext(MessagesContext)
  const { fetchData: fetchApiConversations } = useApiFetch({ url: API_CONVERSATIONS_PATH, onRender: false })
  const { fetchData: fetchUsers } = useApiFetch({ url: API_USERS_PATH, onRender: false })
  const { t } = useTranslation('translations')

  const fetchConversations = async (): Promise<void> => {
    const fetchedConversations = await fetchApiConversations()

    setConversations(fetchedConversations)
  }

  const fetchConversationPartners = async (searchedConversationPartnerEmail: string): Promise<void> => {
    const fetchedConversationPartners = await fetchUsers({ query: searchedConversationPartnerEmail })

    setConversations(fetchedConversationPartners)
  }

  useEffect(() => {
    void fetchConversations()
  }, [])

  const findConversationPartner = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    e.preventDefault()
    const searchedConversationPartnerEmail = e.target.value

    if (searchedConversationPartnerEmail) {
      void fetchConversationPartners(searchedConversationPartnerEmail)
    } else {
      void fetchConversations()
    }
  }

  return (
    <div className="p-3 divide-y">
      <div className="my-2">
        <h1 className="text-3xl font-bold text-center p-3">{t('messages_view.header')}</h1>
        <input
          type="text"
          className="px-2 py-1 border rounded-lg w-full"
          onChange={findConversationPartner}
        />
      </div>
      <div className="pt-3 h-full">
        <div className="overflow-auto h-4/5">
          {conversations.map((conversation: ConversationData): ReactElement => {
            return <SelectConversationItem key={conversation.id} conversation={conversation} />
          })}
        </div>
      </div>
    </div>
  )
}

export default MessagesSideBar
