import React, { type ReactElement } from 'react'
import LessonView from '../../components/lessons/LessonView'
import { API_TEACHER_LESSON_PATH } from '../../constants/apiRoutes'
import { useApiFetch } from '../../hooks/api/useApiFetch'
import { useParams } from 'react-router-dom'

const TeacherLessonView = (): ReactElement => {
  const queryParams = useParams()
  const apiUrl = API_TEACHER_LESSON_PATH.replace(':id', queryParams.id as string)
  const { data: lesson } = useApiFetch({ url: apiUrl })

  return (
    <div className="grid h-screen">
      <div className="lg:px-12 px-5 pb-8 pt-2 card lg:mx-1 w-screen">
        <LessonView lesson={lesson} />
      </div>
    </div>
  )
}

export default TeacherLessonView
