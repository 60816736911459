import React, { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { API_ADMIN_CLASSROOMS_PATH } from '../../../constants/apiRoutes'
import ClassroomForm from '../../../components/admin/classrooms/ClassroomForm'

const NewClassroomView = (): ReactElement => {
  const { t } = useTranslation('translations')

  return (
    <div className="grid h-screen place-items-center w-full" >
      <div className="p-12 card border rounded-md">
        <h1 className="text-3xl font-bold text-center pb-3">{t('admin.classrooms.new_header')}</h1>
        <ClassroomForm url={API_ADMIN_CLASSROOMS_PATH} />
      </div>
    </div>
  )
}

export default NewClassroomView
