import React, { type ReactElement } from 'react'
import HomeworksList from '../../components/homeworks/HomeworksList'
import { API_TEACHER_LESSON_HOMEWORKS_PATH } from '../../constants/apiRoutes'
import { useApiFetch } from '../../hooks/api/useApiFetch'
import { useParams } from 'react-router-dom'

const TeacherLessonHomeworkListView = (): ReactElement => {
  const queryParams = useParams()
  const { data: homeworks, fetchData } = useApiFetch({ url: API_TEACHER_LESSON_HOMEWORKS_PATH.replace(':id', queryParams.id as string), paginated: true })

  return (
    <HomeworksList homeworks={homeworks?.data} paginationMeta={homeworks?.meta} fetchData={fetchData} />
  )
}

export default TeacherLessonHomeworkListView
