import React, { type ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { ADMIN_EDIT_LESSON_SLOT_PATH } from '../../../constants/adminRoutes'
import { type LessonSlotItemData } from '../../../constants/types'
import { useTranslation } from 'react-i18next'

const LessonSlotListItem = ({ lessonSlot }: { lessonSlot: LessonSlotItemData }): ReactElement => {
  const { t } = useTranslation('translations')

  return (
    <div className="card grid grid-cols-12 py-3 px-5 text-sm flex items-center">
      <div className="col-span-1 truncate">{lessonSlot?.id}</div>
      <div className="col-span-3 truncate">{lessonSlot?.day_of_week}</div>
      <div className="col-span-4 truncate">{lessonSlot?.starts_at}</div>
      <div className="col-span-3 truncate">{lessonSlot?.ends_at}</div>
      <div className="col-span-1">
        <Link
          to={ADMIN_EDIT_LESSON_SLOT_PATH.replace(':id', lessonSlot?.id?.toString())}
          className="px-4 py-1 w-max border border-1 border-[#3758F9] rounded-2xl text-[#3758F9]"
        >
          {t('admin.lesson_slots.edit_button')}
        </Link>
      </div>
    </div>
  )
}

export default LessonSlotListItem
