import React, { type ReactElement } from 'react'
import { useApiFetch } from '../../hooks/api/useApiFetch'
import { API_STUDENT_GRADES_LESSONS_PATH } from '../../constants/apiRoutes'
import GradesList from '../../components/grades/GradesList'

export const StudentGradesView = (): ReactElement => {
  const { data: lessons } = useApiFetch({ url: API_STUDENT_GRADES_LESSONS_PATH, paginated: false })

  return (
    <GradesList lessons={lessons} />
  )
}

export default StudentGradesView
