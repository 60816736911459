import React, { type ReactElement, useEffect } from 'react'
import { STUDENT_HOMEWORKS_PATH } from '../../../constants/studentRoutes'
import { map } from 'underscore'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useApiPost } from '../../../hooks/api/useApiPost'
import { useApiFetch } from '../../../hooks/api/useApiFetch'
import { useParseDate } from '../../../hooks/useParseDate'
import { type StudentHomeworkSolutionData } from '../../../constants/types'
import AttachmentsFileInput from '../../common/inputs/AttachmentsFileInput'
import { API_STUDENT_HOMEWORK_PATH } from '../../../constants/apiRoutes'

const StudentHomeworkSolutionForm = ({ url, homeworkSolution, method = 'post' }: { url: string, homeworkSolution?: StudentHomeworkSolutionData, method?: string }): ReactElement => {
  const queryParams = useParams()
  const apiSolutionUrl = url.replace(':homeworkId', queryParams.homeworkId as string)
  const { setInput, sendDataWithFiles, validationErrors, input } = useApiPost({ url: apiSolutionUrl, redirect: STUDENT_HOMEWORKS_PATH, successFlashMessage: 'Pomyślnie zapisano' })
  const { t } = useTranslation('translations')
  const { toLocalDateWithHour } = useParseDate()
  const { data: homework } = useApiFetch({ url: API_STUDENT_HOMEWORK_PATH.replace(':id', queryParams.homeworkId as string) })

  useEffect(() => {
    if (homeworkSolution) {
      setInput({
        ...homeworkSolution
      })
    }
  }, [homeworkSolution])

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault()

    await sendDataWithFiles({ fileKeys: ['files_to_add', 'files_to_remove'], method })
  }

  const handleAutonomousWorkAcceptedAt = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const autonomousWorkAcceptedAt = e.target.checked ? new Date().toJSON() : null

    setInput((prevInput: any) => ({ ...prevInput, autonomous_work_accepted_at: autonomousWorkAcceptedAt }))
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="pb-10 lg:pt-4 pt-6 mx-1 lg:px-12 px-5 text-left">
        <h1 className="lg:text-[32px] text-3xl font-bold text-left">
          {homework.title}
        </h1>
        <div className="flex flex-row space-x-4 lg:mx-4 pt-10 text-sm">
          <div className="flex flex-col space-y-3 items-end">
            <div className="text-base">
              {t('teachers.homeworks.heading.deadline')}
            </div>
            <div className="text-base">
              {t('teachers.homeworks.heading.files_deadline')}
            </div>
          </div>
          <div className="flex flex-col space-y-3 items-start">
            <div className="text-base">
              {toLocalDateWithHour(homework?.deadline)}
            </div>
            <div className="text-base">
              {toLocalDateWithHour(homework?.file_upload_deadline)}
            </div>
          </div>
        </div>
      </div>
      <div className="lg:px-14 px-5">
        <label className="text-lg font-medium ml-1">
          {t('students.homework_solutions.student_answer')}
        </label>
        <br />
        <textarea
          rows={6}
          value={input.student_answer}
          className='px-2 py-1 border border-gray-200 rounded-lg mt-3 mb-3 py-2 resize-none w-full lg:w-1/2 shadow-sm'
          onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, student_answer: e.target.value })) }}
        />
        <div className="text-sm text-red-500">
          {map(validationErrors.description, (error: string): string => `${t('teachers.homeworks.inputs.description')} ${error}`).join(', ')}
        </div>
        <label className="block text-lg font-medium ml-2">
          {t('students.homework_solutions.files')}
        </label>
        <div className='pb-6 mt-3 lg:w-1/2'>
          <AttachmentsFileInput defaultInput={input.files} setInput={setInput} inputAddKey="files_to_add" inputRemoveKey="files_to_remove" />
          <div className="text-sm text-red-500 mt-3">
            {map(validationErrors.files_to_add, (error: string): string => `${t('students.homework_solutions.files')} ${error}`).join(', ')}
            {map(validationErrors.files_to_remove, (error: string): string => `${t('students.homework_solutions.files')} ${error}`).join(', ')}
          </div>
        </div>
        <div className="flex items-center">
          <label className="p-2 group w-full inline-flex gap-3 items-center cursor-pointer lg:text-sm text-base rounded-lg">
            <input
              type="checkbox"
              className="shrink-0 lg:h-5 lg:w-5 h-7 w-7"
              onChange={handleAutonomousWorkAcceptedAt}
            />
            <span className="ms-2">
              {t('students.homework_solutions.autonomous_work_accepted')}
            </span>
          </label>
        </div>
        <div className="text-sm text-red-500 lg:pb-32">
          {map(validationErrors.autonomous_work_accepted_at, (error: string): string => `Akceptacja ${error}`).join(', ')}
        </div>
        <br />
      </div>
      <div className="lg:sticky lg:bottom-0 mb-28 lg:mb-0 lg:bg-white w-full py-4 lg:border-t lg:border-gray-300 flex gap-3 justify-end items-center px-4 lg:px-8">
        <button
          type="button"
          onClick={() => { window.history.back() }}
          className="text-blue-800 hover:text-white flex bg-white justify-center py-2 px-3 border border-blue-700 rounded-lg hover:bg-blue-700"
        >
          Anuluj
        </button>

        <button
          type="submit"
          className="bg-blue-500 content-center hover:bg-blue-700 text-white py-3 px-3 rounded-lg md:w-fit"
        >
          <div className="flex gap-1 justify-center">
            {homeworkSolution
              ? <>
                <span className="px-2">
                  {t('students.homework_solutions.update')}
                </span>
              </>
              : <>
                <span className="px-2">
                  {t('students.homework_solutions.submit')}
                </span>
              </>
            }
          </div>
        </button>
      </div>
    </form>
  )
}

export default StudentHomeworkSolutionForm
