import React, { type ReactElement } from 'react'

const DisabledTabFilter = ({ text }: { text: string }): ReactElement => {
  return (
    <div className="opacity-50 rounded-lg flex-col justify-center items-start inline-flex min-w-fit">
      <div className="px-3 py-2 justify-start items-center gap-2 inline-flex">
        <div className="justify-center items-center gap-2 flex">
          <div className="text-gray-800 lg:text-sm text-base font-medium font-['DM Sans'] leading-normal">{text}</div>
        </div>
      </div>
    </div>
  )
}

export default DisabledTabFilter
