import React, { type ReactElement, useState } from 'react'
import { NotReadRequiredNewsContext } from './NotReadRequiredNewsContext'

const NotReadRequiredContextProvider = ({ children }: { children: ReactElement }): ReactElement => {
  const [notReadRequiredNewsCount, setNotReadRequiredNewsCount] = useState(0)

  return (
    <NotReadRequiredNewsContext.Provider value={
      {
        notReadRequiredNewsCount,
        setNotReadRequiredNewsCount
      }
    }>
      {children}
    </NotReadRequiredNewsContext.Provider>
  )
}

export default NotReadRequiredContextProvider
