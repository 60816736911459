import React, { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useApiFetch } from '../../../hooks/api/useApiFetch'
import ClassroomForm from '../../../components/admin/classrooms/ClassroomForm'
import { API_ADMIN_EDIT_CLASSROOM_PATH } from '../../../constants/apiRoutes'

const EditClassroomView = (): ReactElement => {
  const queryParams = useParams()
  const apiUrl = API_ADMIN_EDIT_CLASSROOM_PATH.replace(':id', queryParams.id as string)
  const { data: classroom } = useApiFetch({ url: apiUrl })
  const { t } = useTranslation('translations')

  return (
    <div className="grid h-screen place-items-center w-full" >
      <div className="p-12 card border rounded-md">
        <h1 className="text-3xl font-bold text-center pb-3">{t('admin.classrooms.edit_header')}</h1>
        <ClassroomForm url={apiUrl} classroom={classroom} method={'patch'} />
      </div>
    </div>
  )
}

export default EditClassroomView
