import React, { type ReactElement } from 'react'
import { type NewsData } from '../../../constants/types'
import { ADMIN_EDIT_NEWS_PATH } from '../../../constants/adminRoutes'
import { API_NEWS_EXPIRE_PATH } from '../../../constants/apiRoutes'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useParseDate } from '../../../hooks/useParseDate'
import { useApiPost } from '../../../hooks/api/useApiPost'

const NewsListItem = ({ news }: { news: NewsData }): ReactElement => {
  const { t } = useTranslation('translations')
  const { toLocalDate } = useParseDate()
  const { sendData: expireNews } = useApiPost({ url: API_NEWS_EXPIRE_PATH, successFlashMessage: 'Pomyślnie zarchiwizowano' })

  const sendExpireNewsRequest = async (): Promise<void> => {
    await expireNews({ additionalParams: { news_id: news.id } })
    window.location.reload()
  }

  return (
    <div className="card grid grid-cols-12 py-3 px-5 text-sm flex items-center">
      <div className="col-span-1 truncate">{news?.id}</div>
      <div className="col-span-2 truncate">{news?.title}</div>
      <div className="col-span-2 truncate flex justify-center">{toLocalDate(news?.expires_at)}</div>
      <div className="col-span-2 truncate flex justify-center">{toLocalDate(news?.pinned_until)}</div>
      <div className="col-span-1 truncate">{toLocalDate(news?.created_at)}</div>
      <div className="col-span-1 truncate flex justify-center">{toLocalDate(news?.updated_at)}</div>
      <div className="col-span-2 flex justify-center">
        {
          news.expired
            ? (
              <div
                className="px-4 py-1 w-max border border-1 border-gray-500 rounded-2xl text-gray-500"
              >
                {t('admin.news.statuses.expired')}
              </div>
            )

            : (
              <div
                onClick={sendExpireNewsRequest}
                className="px-4 py-1 w-max border border-1 border-[#3758F9] rounded-2xl text-[#3758F9] cursor-pointer"
              >
                {t('admin.news.expire_button')}
              </div>
            )
        }

      </div>
      <div className="col-span-1">
        <Link
          to={ADMIN_EDIT_NEWS_PATH.replace(':id', news?.id?.toString())}
          className="px-4 py-1 w-max border border-1 border-[#3758F9] rounded-2xl text-[#3758F9]"
        >
          {t('admin.news.edit_button')}
        </Link>
      </div>
    </div>
  )
}

export default NewsListItem
