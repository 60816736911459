import React, { type ReactElement } from 'react'
import LessonsList from '../../components/lessons/LessonsList'
import { API_TEACHER_LESSONS_PATH } from '../../constants/apiRoutes'
import { useApiFetch } from '../../hooks/api/useApiFetch'

const TeacherLessonListView = (): ReactElement => {
  const { data: lessons, fetchData } = useApiFetch({ url: API_TEACHER_LESSONS_PATH, paginated: true })

  return (
    <LessonsList lessons={lessons?.data} paginationMeta={lessons?.meta} fetchData={fetchData} />
  )
}

export default TeacherLessonListView
