import React, { type ReactElement, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { DASHBOARD_PATH } from '../../constants/rolePaths'
import { LOGIN_PATH } from '../../constants/routes'
import { CurrentUserContext } from '../../contexts/users/CurrentUserContext'

const NotFoundView = (): ReactElement => {
  const { t } = useTranslation('translations')
  const { currentUser } = useContext(CurrentUserContext)

  return (
    <div className="bg-gray-50">
      <div className="min-h-screen flex flex-col justify-center">
        <div className="p-2 sm:p-5 sm:pb-0 space-y-5 text-center">
          <div className="max-w-md mx-auto space-y-3">
            <h1 className="leading-none text-8xl font-semibold text-gray-800">
              404
            </h1>
            <h2 className="text-3xl font-medium tracking-tight text-gray-800">
              {t('errors.not_found.title')}
            </h2>
            <p className="text-gray-500">
              {t('errors.not_found.message')}
            </p>
            <div>
              <Link
                to={currentUser ? DASHBOARD_PATH : LOGIN_PATH}
                className="py-2 px-3 inline-flex justify-center items-center gap-x-2 text-start bg-blue-600 border border-blue-600 text-white text-xs sm:text-sm font-medium rounded-lg shadow-sm align-middle hover:bg-blue-700 focus:outline-none focus:ring-1 focus:ring-blue-300">
                <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                  viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round">
                  <path d="m12 19-7-7 7-7"></path>
                  <path d="M19 12H5"></path>
                </svg>
                {t('errors.not_found.back_button')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotFoundView
