import React, { useState, useEffect, useRef, type ReactElement } from 'react'
import { map } from 'underscore'
import { type InfiniteScrollProps } from '../../constants/types'

const InfiniteScroll = (
  {
    items,
    fetchMoreItems,
    renderComponent,
    paginationMeta,
    setPagination,
    pagination
  }: InfiniteScrollProps
): ReactElement => {
  const [loading, setLoading] = useState(false)
  const scrollContainerRef = useRef(null)
  const sentinelRef = useRef(null)

  const loadMoreItems = async (): Promise<void> => {
    if (loading || pagination.page > paginationMeta.pages) return

    setLoading(true)
    await fetchMoreItems(pagination.page)
    setPagination({ ...pagination, page: pagination.page + 1 })
    setLoading(false)
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          void loadMoreItems()
        }
      },
      { threshold: 1.0 }
    )

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current)
    }

    return () => { observer.disconnect() }
  }, [loading, items])

  return (
    <>
      <div className="h-full flex flex-col-reverse overflow-y-auto" ref={scrollContainerRef}>
        {map(items, (item: any) => renderComponent(item))}
        {loading && <div>Loading...</div>}
        <div ref={sentinelRef} style={{ height: '1px' }}></div>
      </div>
    </>
  )
}

export default InfiniteScroll
