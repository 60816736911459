import React, { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { type StudentHomeworkSolutionData } from '../../../constants/types'
import { useParseDate } from '../../../hooks/useParseDate'

const StudentHomeworkSolution = ({ url, homeworkSolution, method = 'get' }: { url: string, homeworkSolution?: StudentHomeworkSolutionData, method?: string }): ReactElement => {
  const { t } = useTranslation('translations')
  const { toLocalDateWithHour } = useParseDate()

  return (
    <div className="min-h-screen flex flex-col justify-between">
      <div className="lg:px-12 px-5 py-8 mx-1">
        <div className='grid grid-cols-1 lg:grid-cols-2 lg:pb-20 lg:pb-0'>
          <div>
            <h1 className="text-3xl text-left pb-14 mx-1">{homeworkSolution?.homework?.title}</h1>
            <div className="inline-flex ml-2 gap-3 mb-10">
              <div className="flex flex-col space-y-3 items-end">
                <div className="text-base">
                  {t('teachers.homeworks.heading.deadline')}
                </div>
                <div className="text-base">
                  {t('teachers.homeworks.heading.files_deadline')}
                </div>
              </div>
              <div className="flex flex-col space-y-3 items-start">
                <div className="text-base">
                  {homeworkSolution?.homework?.deadline && toLocalDateWithHour(homeworkSolution?.homework?.deadline)}
                </div>
                <div className="text-base">
                  {homeworkSolution?.homework?.file_upload_deadline && toLocalDateWithHour(homeworkSolution?.homework?.file_upload_deadline)}
                </div>
              </div>
            </div>
            <br />
            <label className="text-lg font-extrabold ml-1">{t('teachers.homeworks.inputs.title')}</label>
            <br />
            <div className="ml-2 mt-4 mb-5 font-thin text-sm">
              {homeworkSolution?.homework?.description}
            </div>
            <div className="border bg-white border-gray-200 rounded-2xl pt-5 pb-7 mb-5 ml-1 shadow-sm">
              <label className="text-lg font-medium ml-1 px-3">{t('teachers.homework_solutions.inputs.student_answer')}</label>
              <div className="pt-5 px-4 font-thin text-sm lg:h-60 h-40 overflow-y-auto">
                {homeworkSolution?.student_answer}
              </div>
            </div>
            <label className="text-lg font-medium ml-1">{t('teachers.homework_solutions.files')}</label>
            <div className="py-5 px-1 max-h-40 overflow-y-auto scrollbar-thin scrollbar-thumb-rounded">
              {homeworkSolution?.solution_files && homeworkSolution.solution_files.length > 0
                ? (
                  <div>
                    {homeworkSolution?.solution_files.map((file) => (
                      <div key={file.id} className="flex justify-between items-center">
                        <div className="text-sm">
                          {file.filename}
                        </div>
                        <a
                          href={file.url}
                          download
                          className="text-blue-800 hover:text-white flex gap-3 justify-center w-fit py-2 px-3 border border-blue-800 rounded-lg hover:bg-blue-800"
                        >
                          <div>
                            {t('teachers.homework_solutions.download_file')}
                          </div>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                          </svg>
                        </a>
                      </div>
                    ))}
                  </div>
                )
                : (
                  <p>{t('teachers.homework_solutions.no_files')}</p>
                )
              }
            </div>
          </div>
          <div className="lg:pl-36 lg:pt-0 lg:pb-0 pb-32">
            <div className="text-2xl lg:mb-10 mb-5">
              {t('teachers.homework_solutions.comment')}
            </div>
            <label className="font-extralight">{t('teachers.homework_solutions.inputs.teacher_review')}</label>
            <div className="border bg-white border-gray-200 rounded-2xl pt-5 pb-7 mb-5 mt-5 ml-1 shadow-sm">
              <div className="px-4 font-thin text-sm lg:h-60 h-40 overflow-y-auto">
                {homeworkSolution?.teacher_review}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StudentHomeworkSolution
