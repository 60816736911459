import React, { type ReactElement, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import NavbarLink from '../navbar/NavbarLink'
import AdminNavbarLink from './AdminNavbarLink'
import {
  ADMIN_NEWS_PATH,
  ADMIN_DASHBOARD_PATH,
  ADMIN_CALENDAR_EVENTS_PATH,
  ADMIN_DOCUMENTS_PATH,
  ADMIN_LESSON_SLOTS_PATH,
  ADMIN_CLASSROOMS_PATH,
  ADMIN_LESSONS_PATH
} from '../../../constants/adminRoutes'
import { CurrentUserContext } from '../../../contexts/users/CurrentUserContext'
import GetRootFromRoleService from '../../../services/users/GetRootFromRoleService'

const Navbar = (): ReactElement => {
  const { t } = useTranslation('translations')
  const { currentUser } = useContext(CurrentUserContext)
  const navbarLinkClass = 'py-3 px-7 hover:bg-gray-100'
  const navbarLinkActiveTabClass = 'py-3 px-7 hover:bg-gray-100 bg-gray-100'

  return (
    <div className="border-r lg:flex lg:flex-col w-1/5 lg:block hidden">
      <NavbarLink path={ADMIN_DASHBOARD_PATH} className={navbarLinkClass} activeTabClass={navbarLinkActiveTabClass}>
        <AdminNavbarLink tab={t('admin.navbar.dashboard')} />
      </NavbarLink>
      <NavbarLink path={ADMIN_NEWS_PATH} className={navbarLinkClass} activeTabClass={navbarLinkActiveTabClass}>
        <AdminNavbarLink tab={t('admin.navbar.news')} />
      </NavbarLink>
      <NavbarLink path={ADMIN_CALENDAR_EVENTS_PATH} className={navbarLinkClass} activeTabClass={navbarLinkActiveTabClass}>
        <AdminNavbarLink tab={t('admin.navbar.calendar_events')} />
      </NavbarLink>
      <NavbarLink path={ADMIN_DOCUMENTS_PATH} className={navbarLinkClass} activeTabClass={navbarLinkActiveTabClass}>
        <AdminNavbarLink tab={t('admin.navbar.documents')} />
      </NavbarLink>
      <NavbarLink path={ADMIN_CLASSROOMS_PATH} className={navbarLinkClass} activeTabClass={navbarLinkActiveTabClass}>
        <AdminNavbarLink tab={t('admin.navbar.classrooms')} />
      </NavbarLink>
      <NavbarLink path={ADMIN_LESSON_SLOTS_PATH} className={navbarLinkClass} activeTabClass={navbarLinkActiveTabClass}>
        <AdminNavbarLink tab={t('admin.navbar.lesson_slots')} />
      </NavbarLink>
      <NavbarLink path={ADMIN_LESSONS_PATH} className={navbarLinkClass} activeTabClass={navbarLinkActiveTabClass}>
        <AdminNavbarLink tab={t('admin.navbar.lessons')} />
      </NavbarLink>
      <NavbarLink path={GetRootFromRoleService.call(currentUser, false)} className={navbarLinkClass} activeTabClass={navbarLinkActiveTabClass}>
        <AdminNavbarLink tab={t('admin.navbar.leave')} />
      </NavbarLink>
    </div>
  )
}

export default Navbar
