import React, { type ReactElement, useContext, useEffect } from 'react'
import LoginForm from '../components/users/LoginForm'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { CurrentUserContext } from '../contexts/users/CurrentUserContext'
import GetRootFromRoleService from '../services/users/GetRootFromRoleService'

const LoginView = (): ReactElement => {
  const { t } = useTranslation('translations')
  const { currentUser } = useContext(CurrentUserContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (!localStorage.getItem('refresh_token')) return

    const root = GetRootFromRoleService.call(currentUser)
    navigate(root)
  }, [currentUser])

  return (
    <div className="grid h-screen place-items-center" >
      <div className="p-12 card border rounded-md">
        <h1 className="text-3xl font-bold text-center pb-3">{t('login_view.header')}</h1>
        <LoginForm />
      </div>
    </div>
  )
}

export default LoginView
