import React, { type ReactElement, type FormEvent, useState, useEffect } from 'react'
import { renderToString } from 'react-dom/server'
import { Link } from 'react-router-dom'
import { ADVANCED_SEARCHING_PATH } from '../../constants/routes'
import HSComboBox from '@preline/combobox'
// import { PARENT_ROLE, TEACHER_ROLE } from '../../constants/roles'

export const SearchBar = ({ apiFetchResultsUrl }: { apiFetchResultsUrl: string }): ReactElement => {
  const [query, setQuery] = useState('')
  const [isMobile, setIsMobile] = useState(false)

  const comboBoxConfig = {
    groupingType: 'default',
    isOpenOnFocus: true,
    preventAutoPosition: true,
    gap: 10,
    apiGroupField: 'category',
    apiDataPart: 'data',
    apiUrl: process.env.REACT_APP_API_URL + apiFetchResultsUrl,
    apiHeaders: { headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } },
    apiSearchQuery: 'query',
    // FIXME: Move this to props
    apiQuery: 'status=active',
    outputItemTemplate: renderToString(<OutputItemTemplate />),
    groupingTitleTemplate: renderToString(<GroupingTitleTemplate />),
    outputEmptyTemplate: renderToString(<OutputEmptyTemplate />),
    outputLoaderTemplate: renderToString(<OutputLoaderTemplate />)
  }

  const redirectToAdvancedSearch = (e: FormEvent): void => {
    e.preventDefault()
    window.location.href = `${ADVANCED_SEARCHING_PATH}?query=${query}`
  }

  useEffect(() => {
    const checkIfMobile = (): void => {
      setIsMobile(window.matchMedia('(max-width: 768px)').matches)
    }

    checkIfMobile()
    window.addEventListener('resize', checkIfMobile)

    return () => {
      window.removeEventListener('resize', checkIfMobile)
    }
  }, [])

  useEffect(() => {
    HSComboBox.autoInit()

    if (isMobile) {
      const searchBarElement = document.getElementById('search-bar')
      if (searchBarElement) {
        searchBarElement.setAttribute('data-hs-combo-box-open', 'true')
      }
    }
  }, [isMobile])

  // const searchBorder = currentUser?.role === PARENT_ROLE ? 'border-white' : 'border-zinc-200'
  // const searchBackground = currentUser?.role === PARENT_ROLE ? 'bg-[#084DC5]' : 'bg-blue-600'
  // const borderClass = currentUser?.role === TEACHER_ROLE ? '' : 'border-y border-l'

  return (
    <div className="lg:flex h-10 text-black rounded-lg items-center w-full max-w-2xl mx-auto">
      <div
        className={`relative w-full ${isMobile ? 'flex-col' : ''}`}
        id="search-bar"
        data-hs-combo-box={JSON.stringify(comboBoxConfig)}
      >
        <form
          className="relative flex items-center w-full"
          onSubmit={redirectToAdvancedSearch}
        >
          <input
            className="block lg:py-2 py-3 lg:px-4 px-5 w-full rounded-l-md border-2 border-gray-100 border-r-0 lg:text-sm text-md focus:outline-none disabled:opacity-50 disabled:pointer-events-none"
            type="text"
            role="combobox"
            aria-expanded="false"
            placeholder="Szukaj..."
            data-hs-combo-box-input=""
            onChange={(e) => { setQuery(e.target.value) }}
          />
          <Link
            className="bg-blue-500 rounded-r-lg lg:p-2 p-3.5 flex items-center"
            onClick={() => { window.location.href = `${ADVANCED_SEARCHING_PATH}?query=${query}` }}
            to="#"
          >
            <svg
              className="shrink-0 h-6 w-6 text-white"
              data-hs-combo-box-toggle
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle cx="11" cy="11" r="8"></circle>
              <path d="m21 21-4.3-4.3"></path>
            </svg>
          </Link>
        </form>

        <div
          className={`absolute ${isMobile ? 'bottom-full mb-1' : 'mt-1'} w-full z-[200] bg-white border border-gray-200 rounded-lg hidden hs-combo-box-selected:block`}
          data-hs-combo-box-output=""
        >
          <div
            className="max-h-[400px] rounded-b-lg overflow-hidden overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300"
            data-hs-combo-box-output-items-wrapper=""
          />
          <div className="w-full flex justify-center">
            <div className="w-full" />
          </div>
          <SearchBarFooter query={query} />
        </div>
      </div>
    </div>
  )
}

const SearchBarFooter = ({ query }: { query: string }): ReactElement => {
  return (
    <Link
      onClick={() => { window.location.href = `${ADVANCED_SEARCHING_PATH}?query=${query}` }}
      to="#"
      className="h-11 rounded-lg justify-end items-center gap-3 inline-flex w-full"
      data-hs-combo-box-close
    >
      <div className="h-5 justify-end items-center flex">
        <div className="flex justify-end items-center gap-1.5">
          <div className="text-blue-800 text-sm font-medium font-['DM Sans']">Zobacz wszystkie wyniki</div>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="darkblue" className="w-4 h-4 mr-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
          </svg>
        </div>
      </div>
    </Link>
  )
}

const GroupingTitleTemplate = (): ReactElement => {
  return <div className="text-xs uppercase text-gray-500 m-3 mb-1" data-hs-combo-box-group-title></div>
}

const OutputItemTemplate = (): ReactElement => {
  return (
    <div data-hs-combo-box-output-item>
      <span className="flex items-center cursor-pointer py-2 px-4 w-full text-sm text-gray-800 hover:bg-gray-100">
        <div className="flex items-center w-full">
          <div className="flex items-center justify-center rounded-full w-6 h-6 overflow-hidden mr-2.5">
            <img
              className="shrink-0"
              data-hs-combo-box-output-item-attr='[{"valueFrom": "avatar_url", "attr": "src"}, {"valueFrom": "type", "attr": "alt"}]'
            />
          </div>
          <div
            data-hs-combo-box-output-item-field="name"
            data-hs-combo-box-search-text
            data-hs-combo-box-value
          ></div>
        </div>
        <div className="text-xs uppercase text-gray-500 m-3 mb-1">
          <div data-hs-combo-box-output-item-field="type" />
        </div>
      </span>
    </div>
  )
}

const OutputEmptyTemplate = (): ReactElement => {
  return (
    <div className="py-2 px-4">
      Nie znaleziono wyników
    </div>
  )
}

const OutputLoaderTemplate = (): ReactElement => {
  return (
    <div className="flex justify-center items-center py-2 px-4 text-sm text-gray-800 rounded-lg bg-white">
      <div
        className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
        role="status" aria-label="loading">
        <span className="sr-only">Ładowanie...</span>
      </div>
    </div>
  )
}

export default SearchBar
