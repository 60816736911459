import { type AxiosError } from 'axios'
import { useContext } from 'react'
import { FlashContext } from '../../contexts/flash/FlashContext'
import { FlashType } from '../../constants/types'

export const useHandleApiError = (
  errorMessage = 'Coś poszło nie tak'
): (error: any) => void => {
  const { setFlash } = useContext(FlashContext)

  const handleError = (error: AxiosError): void => {
    switch (error?.response?.status) {
    case 403:
      setFlash(() => (
        {
          // FIXME: Translations
          message: 'Jesteś nieuprawniony do wykonania tej akcji',
          type: FlashType.error
        }
      ))
      window.location.href = '/'
      break
    case 401:
      break
    default:
      setFlash(() => ({
        // FIXME: Translations
        message: errorMessage,
        type: FlashType.error
      }))
      window.location.href = '/'
      break
    }
  }

  return handleError
}
