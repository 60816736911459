import React, { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import TeacherHomeworkForm from '../../components/teachers/homeworks/TeacherHomeworkForm'
import { API_TEACHER_HOMEWORKS_PATH } from '../../constants/apiRoutes'

const TeacherNewHomeworkView = (): ReactElement => {
  const { t } = useTranslation('translations')

  return (
    <div className="grid h-screen" >
      <h1 className="lg:text-3xl text-2xl text-left pb-10 pt-8 mx-1 lg:px-12 px-5">{t('teachers.new_homework_view.header')}</h1>
      <TeacherHomeworkForm url={API_TEACHER_HOMEWORKS_PATH}/>
    </div>
  )
}

export default TeacherNewHomeworkView
