import React, { useRef, type ReactElement } from 'react'
import { type EditorComponentProps } from '../../constants/types'
import { type Editor as TinyMCEEditor } from 'tinymce'
import { Editor } from '@tinymce/tinymce-react'
import { useApiPost } from '../../hooks/api/useApiPost'

const RichTextEditor = ({
  content,
  setContent,
  fileUploadURL,
  validationErrors
}: EditorComponentProps): ReactElement => {
  const editorRef = useRef<TinyMCEEditor | null>(null)

  const { sendDataWithFiles } = useApiPost({ url: fileUploadURL })

  const handleFileUpload = async (file: File): Promise<string> => {
    return sendDataWithFiles({ fileKeys: ['file'], additionalParams: { file } })
      .then((response) => response.link)
  }

  const handleImageUpload = async (file: File): Promise<string> => {
    return sendDataWithFiles({ fileKeys: ['file'], additionalParams: { file } })
      .then((response) => response.link)
  }

  const setup = (editor): void => {
    editor.ui.registry.addButton('customInsertFile', {
      text: 'Insert File',
      icon: 'upload',
      onAction: function () {
        const input = document.createElement('input')
        input.setAttribute('type', 'file')
        input.setAttribute('accept', '.pdf,.doc,.docx,.txt,.xlsx,.xls,.ppt,.pptx,.zip')

        input.onchange = async function () {
          const file = input.files ? input.files[0] : null
          if (file) {
            try {
              const fileUrl = await handleFileUpload(file)
              const linkText = file.name
              editor.insertContent(`<a href="${fileUrl}" download>${linkText}</a>`)
            } catch (error) {
              console.error(error)
            }
          }
        }

        input.click()
      }
    })
  }

  return (
    <div>
      <Editor
        apiKey={process.env.REACT_APP_TINYMC_TOKEN}
        onInit={(_evt, editor) => {
          editorRef.current = editor
          if (content) editor.setContent(content)
        }}
        initialValue={content}
        onBlur={() => {
          if (editorRef.current) {
            const newContent = editorRef.current.getContent()
            setContent((prevInput: any) => ({ ...prevInput, content: newContent }))
          }
        }}
        init={{
          height: 500,
          menubar: true,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'code',
            'help',
            'wordcount'
          ],
          toolbar:
            'undo redo | bold italic | link image customInsertFile | alignleft aligncenter alignright | ' +
            'bullist numlist outdent indent | removeformat | help',
          images_upload_handler: async (blobInfo) => {
            const file = new File([blobInfo.blob()], blobInfo.filename(), {
              type: blobInfo.blob().type,
              lastModified: new Date().getTime()
            })

            return await handleImageUpload(file)
          },
          setup,
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
      />
    </div>
  )
}

export default RichTextEditor
