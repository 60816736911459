import React, { type ReactElement } from 'react'
import HomeworksList from '../../components/homeworks/HomeworksList'
import { API_TEACHER_HOMEWORKS_PATH } from '../../constants/apiRoutes'
import { useApiFetch } from '../../hooks/api/useApiFetch'

const TeacherHomeworkListView = (): ReactElement => {
  const { data: homeworks, fetchData } = useApiFetch({ url: API_TEACHER_HOMEWORKS_PATH, paginated: true })

  return (
    <HomeworksList homeworks={homeworks?.data} paginationMeta={homeworks?.meta} fetchData={fetchData} />
  )
}

export default TeacherHomeworkListView
