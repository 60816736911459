import axios from 'axios'
import { API_REFRESH_TOKEN_PATH } from '../constants/apiRoutes'
import { type AuthTokensResponse } from '../constants/types'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token')
    if (token) config.headers.Authorization = `Bearer ${token}`

    return config
  },
  async (error) => await Promise.reject(error)
)

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config

    // If the error status is 401 and there is no originalRequest._retry flag,
    // it means the token has expired and we need to refresh it
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true

      try {
        const refreshToken = localStorage.getItem('refresh_token') as string
        const response: AuthTokensResponse = await axios.post(
          process.env.REACT_APP_API_URL + API_REFRESH_TOKEN_PATH,
          {},
          { headers: { Authorization: refreshToken } }
        )

        localStorage.setItem('access_token', response.data.access_token)
        localStorage.setItem('refresh_token', response.data.refresh_token)

        // Retry the original request with the new token
        originalRequest.headers.Authorization = `Bearer ${response.data.access_token}`
        return await axios(originalRequest)
      } catch (error) {
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')

        window.location.href = '/login'
      }
    }

    return await Promise.reject(error)
  }
)

export default axiosInstance
