import React, { type ReactElement, useContext } from 'react'
import { type HomeworkData } from '../../constants/types'
import { STUDENT_ROLE, TEACHER_ROLE } from '../../constants/roles'
import { useTranslation } from 'react-i18next'
import { useParseDate } from '../../hooks/useParseDate'
import { Link } from 'react-router-dom'
import { STUDENT_NEW_HOMEWORK_SOLUTION_PATH, STUDENT_HOMEWORK_SOLUTION_EDIT_PATH, STUDENT_HOMEWORK_SOLUTION_PATH } from '../../constants/studentRoutes'
import { TEACHER_HOMEWORK_EDIT_PATH, TEACHER_HOMEWORK_PATH } from '../../constants/teacherRoutes'
import { CurrentUserContext } from '../../contexts/users/CurrentUserContext'

const HomeworkListItem = ({ homework }: { homework: HomeworkData }): ReactElement => {
  const { t } = useTranslation('translations')
  const { currentUser } = useContext(CurrentUserContext)
  const { toLocalDateWithHour } = useParseDate()

  const homeworkSolutionPath = (): string => {
    if (solutionSubmitted() && homework.homework_solutions[0].teacher_review && homework.homework_solutions[0].teacher_review?.length > 0) {
      return (
        STUDENT_HOMEWORK_SOLUTION_PATH.replace(':homeworkId', homework.id.toString()).replace(':id', homework.homework_solutions[0].id.toString())
      )
    }

    if (solutionSubmitted() && canEditSolution()) {
      return (
        STUDENT_HOMEWORK_SOLUTION_EDIT_PATH.replace(':homeworkId', homework.id.toString()).replace(':id', homework.homework_solutions[0].id.toString())
      )
    }

    return STUDENT_NEW_HOMEWORK_SOLUTION_PATH.replace(':homeworkId', homework.id.toString())
  }

  const homeworkSolutionButtonLabel = (): ReactElement => {
    if (solutionSubmitted() && homework.homework_solutions[0].teacher_review && homework.homework_solutions[0].teacher_review?.length > 0) {
      return (
        <div>
          {t('homeworks.students.show_review')}
        </div>
      )
    }

    if (solutionSubmitted() && canEditSolution()) {
      return (
        <div>
          {t('homeworks.students.edit_solution')}
        </div>
      )
    }

    return (
      <div className="flex gap-1">
        {t('homeworks.students.add_solution')}
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
        </svg>
      </div>
    )
  }

  const homeworkEditPath = (): string => {
    return TEACHER_HOMEWORK_EDIT_PATH.replace(':id', homework.id.toString())
  }

  const afterDeadline = (): boolean => {
    return new Date(homework.deadline) < new Date()
  }

  const fileUploadAfterDeadline = (): boolean => {
    return new Date(homework.file_upload_deadline) < new Date()
  }

  const solutionSubmitted = (): boolean => {
    return currentUser?.role === STUDENT_ROLE && homework.homework_solutions.length > 0
  }

  const canEditSolution = (): boolean => {
    return currentUser?.role === STUDENT_ROLE && (!fileUploadAfterDeadline() || solutionSubmitted())
  }

  const itemLink = (): string => {
    if (currentUser?.role === TEACHER_ROLE) {
      return TEACHER_HOMEWORK_PATH.replace(':id', homework.id.toString())
    } else {
      return homeworkSolutionPath()
    }
  }

  return (
    <Link
      to={itemLink()}
      className="card grid lg:grid-cols-12 py-3 px-5 lg:h-16 text-sm flex items-center"
    >
      <div className="col-span-2 hidden lg:block">
        {homework?.lesson?.subject.name}
      </div>
      {currentUser?.role === TEACHER_ROLE &&
        <div className="col-span-2 hidden lg:block">
          {homework?.class_units?.map((unit: { name: string }) => unit.name).join(', ')}
        </div>
      }
      <div className="col-span-3 opacity-50 hidden lg:block">
        {homework.title}
      </div>
      {afterDeadline()
        ? (
          <div className="lg:col-span-2 bg-gray-200 py-1 px-2 rounded-lg w-max hidden lg:block">
            <div className="text-gray-600">
              {t('homeworks.solution_finished')}
            </div>
          </div>
        )
        : (
          <div className="lg:col-span-2 bg-green-200 py-1 px-2 rounded-lg w-max hidden lg:block">
            <div className="text-green-900">
              {t('homeworks.solution_active')}
            </div>
          </div>
        )
      }
      {currentUser?.role === STUDENT_ROLE &&
        <div className="col-span-2 hidden lg:block">
          {solutionSubmitted()
            ? (
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="grey" className="w-7 h-7">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
              </svg>
            )
            : (
              <span>-</span>
            )
          }
        </div>
      }
      <div className="col-span-2 opacity-50 hidden lg:block">
        {toLocalDateWithHour(homework.deadline)}
      </div>
      <div className="hidden lg:block">
        {(currentUser?.role === STUDENT_ROLE) &&
          (
            <Link
              to={homeworkSolutionPath()}
              className="text-blue-800 hover:text-white flex justify-center w-full py-2 px-1 border border-blue-800 rounded-lg hover:bg-blue-800"
            >
              {homeworkSolutionButtonLabel()}
            </Link>
          )
        }
        {(currentUser?.role === TEACHER_ROLE) &&
          (
            <Link
              to={homeworkEditPath()}
              className="text-blue-800 hover:text-white flex justify-center w-full py-2 px-3 border border-blue-800 rounded-lg hover:bg-blue-800"
            >
              {t('homeworks.teachers.edit_homework')}
            </Link>
          )
        }
      </div>
      <div className="block lg:hidden">
        <div className="card bg-white px-4 py-4 shadow-sm rounded-xl border">
          <Link to={TEACHER_HOMEWORK_PATH.replace(':id', homework.id.toString())}>
            <div className="flex justify-between items-center mb-5">
              <div className="font-extrabold text-base">
                {homework?.lesson?.subject.name}
              </div>
              <div className="flex gap-2">
                {currentUser?.role === STUDENT_ROLE && solutionSubmitted()
                  ? (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="grey" className="w-7 h-7">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>
                  )
                  : null
                }
                {afterDeadline()
                  ? (
                    <div className="lg:col-span-2 bg-gray-200 py-1 px-2 rounded-lg w-max lg:hidden">
                      <div className="text-gray-600">
                        {t('homeworks.solution_finished')}
                      </div>
                    </div>
                  )
                  : (
                    <div className="lg:col-span-2 bg-green-200 py-1 px-2 rounded-lg w-max lg:hidden">
                      <div className="text-green-900">
                        {t('homeworks.solution_active')}
                      </div>
                    </div>
                  )
                }
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-7 h-7">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                </svg>
              </div>
            </div>
            <div className="grid gap-2 mb-4">
              {currentUser?.role === TEACHER_ROLE &&
                <div className="inline-flex gap-2">
                  Klasy:
                  <div>
                    {homework?.class_units?.map((unit: { name: string }) => unit.name).join('_')}
                  </div>
                </div>
              }
              <div className="inline-flex gap-2">
                Temat:
                <div>
                  {homework?.title}
                </div>
              </div>
              <div className="inline-flex gap-2">
                Data zakończenia:
                <div className="opacity-50">
                  {toLocalDateWithHour(homework?.deadline)}
                </div>
              </div>
            </div>
            {currentUser?.role === STUDENT_ROLE &&
              (
                <Link
                  to={homeworkSolutionPath()}
                  className="text-blue-800 hover:text-white flex justify-center w-full py-2 px-1 border border-blue-800 rounded-lg hover:bg-blue-800"
                >
                  {homeworkSolutionButtonLabel()}
                </Link>
              )
            }
            {currentUser?.role === TEACHER_ROLE &&
              (
                <Link
                  to={homeworkEditPath()}
                  className="text-blue-800 hover:text-white flex justify-center w-full py-2 px-3 border border-blue-800 rounded-lg hover:bg-blue-800"
                >
                  {t('homeworks.teachers.edit_homework')}
                </Link>
              )
            }
          </Link>
        </div>
      </div>
    </Link>
  )
}

export default HomeworkListItem
