import React, { type ReactElement, useEffect } from 'react'
import { type DropdownItemProps, type DropdownProps } from '../../constants/types'
import { Link } from 'react-router-dom'
import { HSDropdown } from 'preline/preline'

const Dropdown = ({ children, className, id, onBlur }: DropdownProps): ReactElement => {
  useEffect(() => {
    HSDropdown.autoInit()
  }, [])

  return (
    <div
      className={`hs-dropdown relative inline-flex ${className}`}
      id={id}
      onBlur={onBlur}
    >
      {children}
    </div>
  )
}

export default Dropdown

export const DropdownHead = ({ children, className }: { className?: string, children: ReactElement | ReactElement[] }): ReactElement => {
  return (
    <button
      id="hs-dropdown-custom-icon-trigger"
      type="button"
      className={`w-full hs-dropdown-toggle flex justify-center items-center size-9 text-sm font-semibold text-gray-800 disabled:opacity-50 disabled:pointer-events-none ${className}`}
    >
      {children}
    </button>
  )
}

export const DropdownMenu = ({ children, className }: { className?: string, children: ReactElement }): ReactElement => {
  return (
    <div
      className={`hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden min-w-60 bg-white shadow-md rounded-lg p-2 mt-2 z-50 ${className}`}
      aria-labelledby="hs-dropdown-custom-icon-trigger"
    >
      {children}
    </div>
  )
}

export const DropdownItem = ({ text, icon, link, action }: DropdownItemProps): ReactElement => {
  return (
    <Link
      to={link as string}
      onClick={action}
      className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
    >
      {icon}
      {text}
    </Link>
  )
}
