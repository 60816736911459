import React, { type ReactElement, useContext } from 'react'
import MessageListItem from './MessageListItem'
import CreateMessageForm from './CreateMessageForm'
import { type MessageData } from '../../constants/types'
import { MessagesContext } from '../../contexts/messages/MessagesContext'
import InfiniteScroll from '../common/InfiniteScroll'
import { useApiFetch } from '../../hooks/api/useApiFetch'
import { useTranslation } from 'react-i18next'
import { API_MESSAGES_PATH } from '../../constants/apiRoutes'

const MessagesList = (): ReactElement => {
  const {
    messages,
    receiverEmail,
    setMessages,
    messagesPagination,
    setMessagesPagination,
    messagesPaginationMeta,
    setMessagesPaginationMeta
  } = useContext(MessagesContext)

  const { fetchData } = useApiFetch({ url: API_MESSAGES_PATH, onRender: false, paginated: true })
  const { t } = useTranslation('translations')

  const fetchMessages = async (page: number): Promise<void> => {
    if (!receiverEmail) return
    const fetchedMessages = await fetchData({ receiver_email: receiverEmail, page })

    setMessagesPaginationMeta(fetchedMessages.meta)
    setMessages((prevMessages: MessageData[]) => [...prevMessages, ...fetchedMessages.data])
  }

  return (
    <div className="p-3 w-full h-full card">
      {receiverEmail && <div>{`${t('messages_view.conversation_with')} ${receiverEmail}`}</div>}
      <InfiniteScroll
        items={messages}
        fetchMoreItems={fetchMessages}
        renderComponent={(message: MessageData): ReactElement => <MessageListItem message={message} key={message.id}/>}
        paginationMeta={messagesPaginationMeta}
        setPagination={setMessagesPagination}
        pagination={messagesPagination}
      />
      <CreateMessageForm />
    </div>
  )
}

export default MessagesList
