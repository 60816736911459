import React, { type ReactElement } from 'react'
import { useApiFetch } from '../../hooks/api/useApiFetch'
import { useParams } from 'react-router-dom'
import { API_STUDENT_EDIT_HOMEWORK_SOLUTIONS_PATH } from '../../constants/apiRoutes'
import StudentHomeworkSolution from '../../components/students/homework_solutions/StudentHomeworkSolution'

const StudentHomeworkSolutionView = (): ReactElement => {
  const queryParams = useParams()
  const apiUrl = API_STUDENT_EDIT_HOMEWORK_SOLUTIONS_PATH
    .replace(':homeworkId', queryParams.homeworkId as string)
    .replace(':id', queryParams.id as string)
  const { data: homeworkSolution } = useApiFetch({ url: apiUrl })

  return (
    <div className="grid h-screen" >
      <StudentHomeworkSolution url={apiUrl} homeworkSolution={homeworkSolution}/>
    </div>
  )
}

export default StudentHomeworkSolutionView
