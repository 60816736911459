import React, { type ReactElement, useEffect } from 'react'
import { map, pairs } from 'underscore'
import { useApiPost } from '../../../hooks/api/useApiPost'
import { ADMIN_LESSON_SLOTS_PATH } from '../../../constants/adminRoutes'
import { useTranslation } from 'react-i18next'
import { type LessonSlotItemData } from '../../../constants/types'
import { daysOfWeek } from '../../../constants/common'

const LessonSlotForm = ({ url, lessonSlot, method = 'post' }: { url: string, lessonSlot?: LessonSlotItemData, method?: string }): ReactElement => {
  const { setInput, sendData, validationErrors, input } = useApiPost({ url, redirect: ADMIN_LESSON_SLOTS_PATH, successFlashMessage: 'Pomyślnie zapisano' })
  const { t } = useTranslation('translations')

  useEffect(() => {
    if (lessonSlot) setInput((_p: any) => lessonSlot)
  }, [lessonSlot])

  const saveLessonSlot = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault()
    await sendData({ method })
  }

  return (
    <>
      <form onSubmit={saveLessonSlot}>
        <label>{t('admin.lesson_slots.attributes.day_of_week')}</label>
        <br />
        <select
          className="px-2 py-1 border rounded-md w-full"
          value={input.day_of_week}
          onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, day_of_week: e.target.value })) }}
        >
          <option value="">{t('admin.lesson_slots.day_of_week_placeholder')}</option>
          {
            map(pairs(daysOfWeek), (dayOfWeek, index) => {
              return (
                <option
                  key={index}
                  value={dayOfWeek[0]}
                >
                  {dayOfWeek[1]}
                </option>
              )
            })
          }
        </select>
        <div className="text-sm text-red-500">
          {map(validationErrors.day_of_week, (error: string): string => `${t('admin.lesson_slots.attributes.day_of_week')} ${error}`).join(', ')}
        </div>
        <br />
        <label>{t('admin.lesson_slots.attributes.starts_at')}</label>
        <br />
        <input
          value={input.starts_at}
          type="time"
          className="px-2 py-1 border rounded-md w-full"
          onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, starts_at: e.target.value })) }}
        />
        <div className="text-sm text-red-500">
          {map(validationErrors.starts_at, (error: string): string => `${t('admin.lesson_slots.attributes.starts_at')} ${error}`).join(', ')}
        </div>
        <br />
        <label>{t('admin.lesson_slots.attributes.ends_at')}</label>
        <br />
        <input
          value={input.ends_at}
          type="time"
          className="px-2 py-1 border rounded-md w-full"
          onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, ends_at: e.target.value })) }}
        />
        <div className="text-sm text-red-500">
          {map(validationErrors.ends_at, (error: string): string => `${t('admin.lesson_slots.attributes.ends_at')} ${error}`).join(', ')}
        </div>
        <br />
        <br />
        <div className="flex justify-center pt-3">
          <button type="submit" className="bg-blue-400 rounded-md text-white p-2">
            {t('admin.lesson_slots.submit_button')}
          </button>
        </div>
      </form>
    </>
  )
}

export default LessonSlotForm
