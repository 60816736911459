import React, { type ReactElement } from 'react'
import { Outlet } from 'react-router-dom'
import AdminNavbar from './Navbar'

const Layout = (): ReactElement => {
  return (
    <>
      <div className="lg:flex flex-none grid lg:grid-none w-full min-h-screen">
        <AdminNavbar />
        <Outlet />
      </div>
    </>
  )
}

export default Layout
