import React, { type ReactElement, useContext, useEffect, useState } from 'react'
import { FlashContext } from '../../contexts/flash/FlashContext'
import ErrorFlash from './flashes/ErrorFlash'
import SuccessFlash from './flashes/SuccessFlash'
import InfoFlash from './flashes/InfoFlash'
import { FlashType } from '../../constants/types'

const Flash = ({ visible }: { visible: boolean }): ReactElement => {
  const [visibility, setVisibility] = useState(false)
  const { flash } = useContext(FlashContext)

  useEffect(() => {
    setVisibility(true)

    const timer = setTimeout(() => { setVisibility(false) }, 3000)
    return () => { clearTimeout(timer) }
  }, [visible, flash])

  const flashComponent = (): ReactElement => {
    switch (flash.type) {
    case FlashType.error:
      return <ErrorFlash message={flash.message} setVisibility={setVisibility} />
    case FlashType.success:
      return <SuccessFlash message={flash.message} setVisibility={setVisibility} />
    case FlashType.info:
      return <InfoFlash message={flash.message} setVisibility={setVisibility} />
    default:
      return <></>
    }
  }

  return (
    <>
      {visibility && flashComponent()}
    </>
  )
}

export default Flash
