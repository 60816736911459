import { type CalendarEventData, type LessonInstanceData, type CalendarItemData } from '../../constants/types'
import { map } from 'underscore'
import { type ProcessedEvent } from '@aldabil/react-scheduler/types'

class ParseCalendarItemsService {
  call (calendarEvents: CalendarEventData[], lessonInstances: LessonInstanceData[]): ProcessedEvent[] {
    if (!calendarEvents || !lessonInstances) return []

    const mergedItems = [...calendarEvents, ...lessonInstances]

    return map(mergedItems, (calendarItem: CalendarItemData): ProcessedEvent => ({
      event_id: calendarItem.id,
      title: calendarItem.title,
      start: new Date(calendarItem.starts_at),
      end: new Date(calendarItem.ends_at),
      editable: false,
      deletable: false
    }))
  }
}

export default new ParseCalendarItemsService()
