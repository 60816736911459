import React, { type ReactElement } from 'react'
import { map } from 'underscore'
import { useApiFetch } from '../../../hooks/api/useApiFetch'
import { type LessonData } from '../../../constants/types'
import { API_ADMIN_LESSONS_PATH } from '../../../constants/apiRoutes'
import { ADMIN_NEW_LESSON_PATH } from '../../../constants/adminRoutes'
import LessonListItem from '../../../components/admin/lessons/LessonListItem'
import Pagination from '../../../components/common/Pagination'
import Plus from '../../../assets/icons/plus.svg'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const LessonsView = (): ReactElement => {
  const { data: lessons, fetchData } = useApiFetch({ url: API_ADMIN_LESSONS_PATH, paginated: true })
  const { t } = useTranslation('translations')

  return (
    <div className="w-full flex items-center justify-center">
      <div className="w-5/6 pb-10">
        <div className="flex justify-between pb-8 pt-4">
          <div className="text-xl font-bold font-['Inter'] leading-tight">
            {t('admin.lessons.h1')}
          </div>
          <div>
            <Link
              to={ADMIN_NEW_LESSON_PATH}
              className="px-5 py-3 w-max bg-[#3758F9] rounded-lg text-white text-sm flex"
            >
              {t('admin.lessons.add_button')}
              <img src={Plus} alt="plus.svg" className="ml-2" />
            </Link>
          </div>
        </div>
        <div className="shadow-lg rounded-lg">
          <div className="card grid grid-cols-12 rounded-t-lg py-6 px-5 bg-[#F9FAFB]">
            <div className="font-['Inter'] col-span-3">{t('admin.lessons.attributes.class_units')}</div>
            <div className="font-['Inter'] col-span-3">{t('admin.lessons.attributes.groups')}</div>
            <div className="font-['Inter'] col-span-2">{t('admin.lessons.attributes.subject')}</div>
            <div className="font-['Inter'] col-span-2">{t('admin.lessons.attributes.teacher')}</div>
            <div className="col-span-1" />
            <div className="col-span-1" />
          </div>
          <div className="border divide-y">
            {
              map(lessons?.data, (lesson: LessonData): ReactElement => {
                return <LessonListItem lesson={lesson} key={lesson.id} />
              })
            }
          </div>
          <div className="p-4 rounded-lg shadow-lg">
            <Pagination paginationMeta={lessons?.meta} fetchData={fetchData} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default LessonsView
