import React, { type ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { ADMIN_EDIT_LESSON_PATH } from '../../../constants/adminRoutes'
import { type LessonData } from '../../../constants/types'
import { useTranslation } from 'react-i18next'

const LessonListItem = ({ lesson }: { lesson: LessonData }): ReactElement => {
  const { t } = useTranslation('translations')
  const classUnitNames = lesson.class_units && lesson.class_units.length > 0 ? lesson.class_units.map(unit => unit.name).join(', ') : 'brak klas'
  const groupNames = lesson.groups && lesson.groups.length > 0 ? lesson.groups.map(group => group.name).join(', ') : 'brak grup'

  return (
    <div className="card grid grid-cols-12 py-3 px-5 text-sm flex items-center">
      <div className="col-span-3 truncate">{classUnitNames}</div>
      <div className="col-span-3 truncate">{groupNames}</div>
      <div className="col-span-2 truncate">{lesson?.subject?.name}</div>
      <div className="col-span-2 truncate">{lesson?.teacher?.user.name}</div>
      <div className="col-span-1" />
      <div className="col-span-1">
        <Link
          to={ADMIN_EDIT_LESSON_PATH.replace(':id', lesson?.id?.toString())}
          className="px-4 py-1 w-max border border-1 border-[#3758F9] rounded-2xl text-[#3758F9]"
        >
          {t('admin.classrooms.edit_button')}
        </Link>
      </div>
    </div>
  )
}

export default LessonListItem
