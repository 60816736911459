import React, { type ReactElement, useCallback, useState, useEffect } from 'react'
import { useDropzone, type DropEvent, type FileRejection } from 'react-dropzone'
import FileIcon from '../../../assets/icons/file.svg'
import { map } from 'underscore'

const AttachmentsFileInput = (
  { defaultInput, setInput, inputAddKey, inputRemoveKey }: { setInput: (prevInput: any) => any, defaultInput: Array<Record<string, any>>, inputAddKey: string, inputRemoveKey: string }
): ReactElement => {
  const [inputFiles, setInputFiles] = useState<File[] | Array<Record<string, any>>>([])

  useEffect(() => {
    if (defaultInput) setInputFiles(defaultInput)
  }, [defaultInput])

  const onDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[], event?: DropEvent) => {
    event?.preventDefault()

    setInputFiles(currentInputFiles => {
      const updatedFiles = [...currentInputFiles, ...acceptedFiles].slice(0, 6)
      setInput((prevInput: any) => ({ ...prevInput, [inputAddKey]: updatedFiles }))
      return updatedFiles
    })
  }, [setInput, inputAddKey])

  const removeFile = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, fileToRemove: any): void => {
    e.preventDefault()
    e.stopPropagation()
    setInputFiles(currentFiles => currentFiles.filter(file => file !== fileToRemove))

    if (fileToRemove.id) {
      setInput((prevInput: any) => ({
        ...prevInput,
        [inputRemoveKey]: [...(prevInput[inputRemoveKey] || []), fileToRemove.id]
      }))
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
    maxSize: 10 * 1024 * 1024
  })

  const UploadZone = (): ReactElement => {
    return (
      <>
        <div className="p-12 flex justify-center bg-white border border-dashed border-blue-500 rounded-xl">
          <div className="text-center">
            <svg className="w-16 text-gray-400 mx-auto" width="70" height="46" viewBox="0 0 70 46" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.05172 9.36853L17.2131 7.5083V41.3608L12.3018 42.3947C9.01306 43.0871 5.79705 40.9434 5.17081 37.6414L1.14319 16.4049C0.515988 13.0978 2.73148 9.92191 6.05172 9.36853Z" fill="currentColor" stroke="currentColor" strokeWidth="2" className="fill-white stroke-gray-400"/>
              <path d="M63.9483 9.36853L52.7869 7.5083V41.3608L57.6982 42.3947C60.9869 43.0871 64.203 40.9434 64.8292 37.6414L68.8568 16.4049C69.484 13.0978 67.2685 9.92191 63.9483 9.36853Z" fill="currentColor" stroke="currentColor" strokeWidth="2" className="fill-white stroke-gray-400"/>
              <rect x="17.0656" y="1.62305" width="35.8689" height="42.7541" rx="5" fill="currentColor" stroke="currentColor" strokeWidth="2" className="fill-white stroke-gray-400"/>
              <path d="M47.9344 44.3772H22.0655C19.3041 44.3772 17.0656 42.1386 17.0656 39.3772L17.0656 35.9161L29.4724 22.7682L38.9825 33.7121C39.7832 34.6335 41.2154 34.629 42.0102 33.7025L47.2456 27.5996L52.9344 33.7209V39.3772C52.9344 42.1386 50.6958 44.3772 47.9344 44.3772Z" stroke="currentColor" strokeWidth="2" className="stroke-gray-400"/>
              <circle cx="39.5902" cy="14.9672" r="4.16393" stroke="currentColor" strokeWidth="2" className="stroke-gray-400"/>
            </svg>

            <div className="mt-4 flex flex-wrap justify-center text-sm leading-6 text-gray-600">
              <span className="pe-1 font-medium text-gray-800">
                Przeciągnij i upuść pliki lub
              </span>
              <label htmlFor="@@browseID" className="cursor-pointer bg-white font-semibold text-blue-600 hover:text-blue-700 rounded-lg decoration-2 hover:underline focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-600 focus-within:ring-offset-2">
                <span>dodaj</span>
              </label>
              <input id="@@browseID" type="file" className="sr-only"></input>
            </div>

            <p className="mt-1 text-xs text-gray-400">
              CSV, XLS, DOCX
            </p>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div
        {...getRootProps()}
        onDragOver={(e) => { e.preventDefault() }}
        onDragEnter={(e) => { e.preventDefault() }}
        className='min-h-[50px] lg:min-h-[200px] w-full cursor-pointer items-center justify-center'
      >
        <input {...getInputProps()} />
        <UploadZone />
      </div>
      <div className='grid gap-2 max-w-full pt-2'>
        {map(inputFiles, (file: any, index) => (
          <div key={index} className='grid grid-cols-4 max-w-full h-[50px] bg-white cursor-pointer rounded-md border border-gray-300 p-1 items-center relative group'>
            <div className='flex col-span-3 mx-3'>
              <img src={FileIcon} alt='file' className='w-6 h-6 mr-2' />
              <p className='flex truncate text-xs items-center'>{file.name || file.filename}</p>
            </div>
            <div className='flex col-span-1 items-center justify-end mx-3'>
              <button className='hidden group-hover:flex' onClick={async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => { removeFile(e, file) }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="red" className="h-5 w-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                </svg>
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default AttachmentsFileInput
