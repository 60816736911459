import { useState, useContext } from 'react'
import { FlashContext } from '../../contexts/flash/FlashContext'
import { CurrentUserContext } from '../../contexts/users/CurrentUserContext'
import { FlashType, type ValidationError, type UseApiPostReturnParams } from '../../constants/types'
import { useNavigate } from 'react-router-dom'
import GetRootFromRoleService from '../../services/users/GetRootFromRoleService'
import { useHandleApiError } from './useHandleApiError'
import axios from '../../config/axios'
import { each, isEmpty } from 'underscore'

export const useApiPost = (
  {
    url, successFlashMessage = '', failureFlashMessage = 'Coś poszło nie tak', redirect = ''
  }: { url?: string, successFlashMessage?: string, failureFlashMessage?: string, redirect?: string }
): UseApiPostReturnParams => {
  const { setFlash } = useContext(FlashContext)
  const { currentUser } = useContext(CurrentUserContext)
  const handleError = useHandleApiError(failureFlashMessage)
  const [validationErrors, setValidationErrors] = useState({} as ValidationError['details'])
  const [input, setInput] = useState({} as Record<string, any>)
  const navigate = useNavigate()

  const redirectPath = (): string => {
    if (redirect === 'root') return GetRootFromRoleService.call(currentUser)

    return redirect
  }

  const sendData = async ({ callback = async (_response: any) => null, additionalParams = {}, headers = {}, method = 'post', apiUrl = url } = {}): Promise<any> => {
    try {
      const response = await (axios as any)[method](apiUrl, { ...input, ...additionalParams }, { headers })

      await callback(response)

      if (successFlashMessage) setFlash({ message: successFlashMessage, type: FlashType.success })

      if (redirect) {
        navigate(redirectPath())
      } else {
        return response.data.data
      }
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidationErrors(error.response.data.details)
        return
      }

      handleError(error)
    }
  }

  const sendDataWithFiles = async ({
    fileKeys,
    callback = async (_response: any) => null,
    additionalParams = {},
    headers = {},
    method = 'post'
  }: {
    fileKeys: string[]
    callback?: (response: any) => Promise<any>
    additionalParams?: Record<string, any>
    headers?: Record<string, string>
    method?: string
  }): Promise<any> => {
    const formData = new FormData()

    each({ ...input, ...additionalParams }, (value: any, key: string) => {
      if (fileKeys.includes(key) && !isEmpty(input[key])) {
        const files = input[key].filter((file: any) => !file.id)

        each(files, (file) => {
          formData.append(`${key}[]`, file)
        })
      } else {
        formData.append(key, value)
      }
    })

    try {
      const response = await (axios as any)[method](url, formData, { headers: { ...headers, 'Content-Type': 'multipart/form-data' } })

      await callback(response)

      if (successFlashMessage) setFlash({ message: successFlashMessage, type: FlashType.success })

      if (redirect) {
        navigate(redirectPath())
      } else {
        return response.data.data
      }
    } catch (error: any) {
      if (error.response.status === 422) {
        setValidationErrors(error.response.data.details)
        return
      }

      handleError(error)
    }
  }

  return { sendData, sendDataWithFiles, setInput, validationErrors, input }
}
