import React, { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useApiFetch } from '../../../hooks/api/useApiFetch'
import LessonSlotForm from '../../../components/admin/lesson_slots/LessonSlotForm'
import { API_ADMIN_EDIT_LESSON_SLOT_PATH } from '../../../constants/apiRoutes'

const EditLessonSlotView = (): ReactElement => {
  const queryParams = useParams()
  const apiUrl = API_ADMIN_EDIT_LESSON_SLOT_PATH.replace(':id', queryParams.id as string)
  const { data: lessonSlot } = useApiFetch({ url: apiUrl })
  const { t } = useTranslation('translations')

  return (
    <div className="grid h-screen place-items-center w-full" >
      <div className="p-12 card border rounded-md">
        <h1 className="text-3xl font-bold text-center pb-3">{t('admin.lesson_slots.edit_header')}</h1>
        <LessonSlotForm url={apiUrl} lessonSlot={lessonSlot} method={'patch'} />
      </div>
    </div>
  )
}

export default EditLessonSlotView
