export const ADMIN_NAMESPACE_PATH = '/admin'
export const ADMIN_DASHBOARD_PATH = '/admin/dashboard'
export const ADMIN_NEWS_PATH = '/admin/news'
export const ADMIN_EDIT_NEWS_PATH = '/admin/news/:id'
export const ADMIN_NEW_NEWS_PATH = '/admin/news/new'
export const ADMIN_CALENDAR_EVENTS_PATH = '/admin/calendar_events'
export const ADMIN_NEW_CALENDAR_EVENT_PATH = '/admin/calendar_events/new'
export const ADMIN_EDIT_CALENDAR_EVENT_PATH = '/admin/calendar_events/:id'
export const ADMIN_DOCUMENTS_PATH = '/admin/documents'
export const ADMIN_NEW_DOCUMENT_PATH = '/admin/documents/new'
export const ADMIN_EDIT_DOCUMENT_PATH = '/admin/documents/:id'
export const ADMIN_LESSON_SLOTS_PATH = '/admin/lesson_slots'
export const ADMIN_NEW_LESSON_SLOT_PATH = '/admin/lesson_slots/new'
export const ADMIN_EDIT_LESSON_SLOT_PATH = '/admin/lesson_slots/:id'
export const ADMIN_CLASSROOMS_PATH = '/admin/classrooms'
export const ADMIN_NEW_CLASSROOM_PATH = '/admin/classrooms/new'
export const ADMIN_EDIT_CLASSROOM_PATH = '/admin/classrooms/:id'
export const ADMIN_LESSONS_PATH = '/admin/lessons'
export const ADMIN_NEW_LESSON_PATH = '/admin/lessons/new'
export const ADMIN_EDIT_LESSON_PATH = '/admin/lessons/:id'
