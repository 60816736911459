import React, { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useApiFetch } from '../../../hooks/api/useApiFetch'
import CalendarEventForm from '../../../components/admin/calendar_events/CalendarEventForm'
import { API_ADMIN_EDIT_CALENDAR_EVENT_PATH } from '../../../constants/apiRoutes'

const EditCalendarEventView = (): ReactElement => {
  const queryParams = useParams()
  const apiUrl = API_ADMIN_EDIT_CALENDAR_EVENT_PATH.replace(':id', queryParams.id as string)
  const { data: calendarEvent } = useApiFetch({ url: apiUrl })
  const { t } = useTranslation('translations')

  return (
    <div className="grid lg:w-1/2" >
      <div className="px-5 lg:px-14 py-8">
        <h1 className="text-2xl lg:text-3xl text-left pb-12">{t('admin.calendar_events.edit_header')}</h1>
        <CalendarEventForm url={apiUrl} calendarEvent={calendarEvent} method={'patch'} />
      </div>
    </div>
  )
}

export default EditCalendarEventView
