import React, { type ReactElement } from 'react'
import { type SearchResultData } from '../../constants/types'
import DefaultAvatar from '../../assets/default_avatar.svg'
import { Link } from 'react-router-dom'

const AdvancedSearchingResultItem = ({ item }: { item: SearchResultData }): ReactElement => {
  return (
    <Link
      to=""
      className="card grid lg:grid-cols-12 py-3 px-5 lg:h-16 text-sm flex items-center"
    >
      <div className="col-span-3 hidden lg:block">
        <div className="flex items-center gap-2.5">
          <img className="w-5 h-5 rounded-full" src={item.avatar_url || DefaultAvatar} alt={item.name} />
          <span className="text-gray-800 text-sm font-normal leading-normal">{item.name}</span>
        </div>
      </div>
      <div className="col-span-3 hidden lg:block">
        {item.type}
      </div>
      <div className="col-span-3 hidden lg:block">
        {item.category}
      </div>
      <div className="col-span-3 hidden lg:block">
        {item.status === 'archived' ? <ArchivedLabel /> : <ActiveLabel />}
      </div>
      <div className="block lg:hidden">
        <div className="card bg-white px-4 py-4 shadow-sm rounded-xl border">
          <Link to="">
            <div className="flex justify-between items-center mb-4">
              <div className="font-extrabold text-base flex gap-2">
                <img className="w-5 h-5 rounded-full" src={item.avatar_url || DefaultAvatar} alt={item.name} />
                <span className="text-gray-800 text-sm font-normal leading-normal">{item.name}</span>
              </div>
            </div>
            <div className="flex gap-3 items-center font-['DM Sans']">
              RODZAJ: {item.type}
            </div>
            <div className="flex gap-3 items-center font-['DM Sans']">
              TYP: {item.category}
            </div>
            <div className="lg:hidden block grid grid-cols-1 gap-2 pt-5">
              {item.status === 'archived' ? <ArchivedLabel /> : <ActiveLabel />}
            </div>
          </Link>
        </div>
      </div>
    </Link>
  )
}

const ActiveLabel = (): ReactElement => {
  return (
    <div className="px-[6px] py-px flex w-fit bg-green-200 rounded-md">
      <div className="text-green-900 text-sm font-medium leading-normal tracking-tight">Aktywny</div>
    </div>
  )
}

const ArchivedLabel = (): ReactElement => {
  return (
    <div className="h-[26px] justify-start items-start inline-flex">
      <div className="px-[3px] py-px bg-gray-200 rounded-md justify-start items-center gap-px flex">
        <div className="px-[2.50px] justify-start items-center gap-[7px] flex">
          <div className="text-gray-600 text-sm font-medium font-['DM Sans'] leading-normal tracking-tight">Archiwalny</div>
        </div>
      </div>
    </div>
  )
}

export default AdvancedSearchingResultItem
