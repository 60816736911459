import React, { useState, useEffect, useCallback, useContext, type ReactElement } from 'react'
import ReactCrop, { type Crop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { useApiPost } from '../../hooks/api/useApiPost'
import { useApiFetch } from '../../hooks/api/useApiFetch'
import { CurrentUserContext } from '../../contexts/users/CurrentUserContext'
import { API_USERS_PROFILE_PATH } from '../../constants/apiRoutes'
import { useTranslation } from 'react-i18next'
import DefaultAvatar from '../../assets/default_avatar.svg'
import { useParams } from 'react-router-dom'

const UserEditAvatar = (): ReactElement => {
  const { setCurrentUser } = useContext(CurrentUserContext)
  const { t } = useTranslation('translations')
  const queryParams = useParams()

  const [avatarUrl, setAvatarUrl] = useState<string | null>(null)
  const [crop, setCrop] = useState<Crop>({
    unit: 'px',
    x: 0,
    y: 0,
    width: 100,
    height: 100
  })
  const [completedCrop, setCompletedCrop] = useState<Crop | null>(null)
  const [uploadedFile, setUploadedFile] = useState<File | null>(null)

  const { fetchData: fetchProfile } = useApiFetch({ url: API_USERS_PROFILE_PATH.replace(':id', queryParams.id as string), onRender: false })
  const { sendDataWithFiles } = useApiPost({
    url: API_USERS_PROFILE_PATH.replace(':id', queryParams.id as string),
    successFlashMessage: 'Avatar updated successfully'
  })

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const response = await fetchProfile()

      if (response) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { avatar_crop_x, avatar_crop_y, avatar_crop_width, avatar_crop_height } = response
        const avatarUrl = response.avatar?.url

        setAvatarUrl(avatarUrl)
        if (avatar_crop_x !== null && avatar_crop_y !== null && avatar_crop_width !== null && avatar_crop_height !== null) {
          setCrop({
            unit: 'px',
            x: avatar_crop_x,
            y: avatar_crop_y,
            width: avatar_crop_width,
            height: avatar_crop_height
          })
        }
      }
    }

    void fetchData()
  }, [])

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files?.[0]
    if (file) setUploadedFile(file)
  }

  const handleUpload = useCallback(async () => {
    if (completedCrop) {
      const additionalParams = {
        avatar_crop_x: Math.round(completedCrop.x),
        avatar_crop_y: Math.round(completedCrop.y),
        avatar_crop_width: Math.round(completedCrop.width),
        avatar_crop_height: Math.round(completedCrop.height)
      } as any

      if (uploadedFile) additionalParams.avatar = uploadedFile

      await sendDataWithFiles({ fileKeys: uploadedFile ? ['avatar'] : [], additionalParams, method: 'patch' })
      window.location.reload()
    } else {
      console.error('No crop data available to upload')
    }
  }, [uploadedFile, completedCrop, sendDataWithFiles, setCurrentUser])

  const renderAvatar = (): ReactElement => {
    if (uploadedFile) {
      return (
        <div className="relative w-64 h-64">
          <ReactCrop
            crop={crop}
            onChange={(newCrop) => { setCrop(newCrop) }}
            onComplete={setCompletedCrop}
            className="w-full h-full"
            aspect={1}
          >
            <img
              src={URL.createObjectURL(uploadedFile)}
              crossOrigin="anonymous"
              alt="New Avatar"
              className="w-64 h-64 object-contain"
            />
          </ReactCrop>
        </div>
      )
    } else if (avatarUrl) {
      return (
        <div className="relative w-64 h-64">
          <ReactCrop
            crop={crop}
            onChange={(newCrop) => { setCrop(newCrop) }}
            onComplete={setCompletedCrop}
            className="w-64 h-64"
            aspect={1}
          >
            <img
              src={avatarUrl}
              crossOrigin="anonymous"
              alt="User Avatar"
              className="w-64 h-64 object-contain"
            />
          </ReactCrop>
        </div>
      )
    } else {
      return (
        <div className="relative w-64 h-64">
          <img
            src={DefaultAvatar}
            crossOrigin="anonymous"
            alt="User Avatar"
            className="w-64 h-64 object-contain"
          />
        </div>
      )
    }
  }

  return (
    <div className="max-w-lg mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">{t('users.profile.edit_header')}</h2>

      <div className="mb-4 flex justify-center">
        {renderAvatar()}
      </div>

      <div className="mb-4">
        <label className="inline-block px-6 py-2 bg-blue-500 text-white font-semibold rounded-lg cursor-pointer hover:bg-blue-600 transition-colors">
          {t('users.profile.replace_avatar')}
          <input type="file" accept="image/*" onChange={onFileChange} className="hidden" />
        </label>
      </div>

      <div className="flex justify-between items-center mt-4">
        {completedCrop && (
          <button
            onClick={handleUpload}
            className="px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 transition-colors"
          >
            {t('users.profile.submit_button')}
          </button>
        )}
      </div>
    </div>
  )
}

export default UserEditAvatar
