import React, { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { API_ADMIN_LESSON_SLOTS_PATH } from '../../../constants/apiRoutes'
import LessonSlotForm from '../../../components/admin/lesson_slots/LessonSlotForm'

const NewLessonSlotView = (): ReactElement => {
  const { t } = useTranslation('translations')

  return (
    <div className="grid h-screen place-items-center w-full" >
      <div className="p-12 card border rounded-md">
        <h1 className="text-3xl font-bold text-center pb-3">{t('admin.lesson_slots.new_header')}</h1>
        <LessonSlotForm url={API_ADMIN_LESSON_SLOTS_PATH}/>
      </div>
    </div>
  )
}

export default NewLessonSlotView
