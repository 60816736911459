import React, { type ReactElement, useContext } from 'react'
import { FlashContext } from '../../contexts/flash/FlashContext'
import { FORGOT_PASSWORD_PATH, LOGIN_PATH } from '../../constants/routes'
import { map } from 'underscore'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useApiPost } from '../../hooks/api/useApiPost'
import { useApiFetch } from '../../hooks/api/useApiFetch'
import { API_LOGIN_PATH, API_NEWS_NOT_READ_REQUIRED_PATH } from '../../constants/apiRoutes'
import { FlashType } from '../../constants/types'
import { NotReadRequiredNewsContext } from '../../contexts/news/NotReadRequiredNewsContext'

const LoginForm = (): ReactElement => {
  const { sendData, setInput, validationErrors } = useApiPost({ url: API_LOGIN_PATH, redirect: LOGIN_PATH })
  const { fetchData: fetchNotReadNews } = useApiFetch({ url: API_NEWS_NOT_READ_REQUIRED_PATH, onRender: false })
  const { t } = useTranslation('translations')
  const { setFlash } = useContext(FlashContext)
  const { setNotReadRequiredNewsCount } = useContext(NotReadRequiredNewsContext)

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault()
    await sendData({ callback: setFlashAndTokens })
  }

  const setFlashAndTokens = async (response: any): Promise<void> => {
    const responseData = response.data.data
    if (responseData.access_token) localStorage.setItem('access_token', responseData.access_token)
    if (responseData.refresh_token) localStorage.setItem('refresh_token', responseData.refresh_token)

    const notReadNews = await fetchNotReadNews()
    setNotReadRequiredNewsCount(notReadNews.length)
    // FIXME: message translation
    if (notReadNews.length > 0) {
      setFlash({ message: `Masz ${notReadNews.length} nieprzeczytanych aktualności`, type: FlashType.info })
    }
    window.location.reload()
  }

  return (
    <form onSubmit={handleSubmit}>
      <label>{t('labels.username')}</label>
      <br />
      <input
        type="text"
        className="px-2 py-1 border rounded-md"
        onChange={(e) => setInput((prevInput: any) => ({ ...prevInput, username: e.target.value }))}
      />
      <div className="text-sm text-red-500">
        {map(validationErrors.username, (error: string): string => `Nazwa użytkownika ${error}`).join(', ')}
      </div>
      <br />
      <label>{t('labels.password')}</label>
      <br />
      <input
        type="password"
        className="px-2 py-1 border rounded-lg"
        onChange={(e) => setInput((prevInput: any) => ({ ...prevInput, password: e.target.value }))}
      />
      <div className="text-sm text-red-500">
        {map(validationErrors.password, (error: string): string => `Hasło ${error}`).join(', ')}
      </div>
      <br />
      <div className="flex justify-center pt-3">
        <button type="submit" className="bg-blue-400 rounded-md text-white p-2">
          {t('login_view.submit_button')}
        </button>
      </div>
      <div className="pt-3 text-blue-400 hover:text-blue-600">
        <Link to={FORGOT_PASSWORD_PATH}>{t('login_view.forgot_password')}</Link>
      </div>
    </form>
  )
}

export default LoginForm
