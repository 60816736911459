import React, { type FormEvent, type ReactElement, useEffect } from 'react'
import { map } from 'underscore'
import { useTranslation } from 'react-i18next'
import { useApiPost } from '../../../hooks/api/useApiPost'
import { ADMIN_CLASSROOMS_PATH } from '../../../constants/adminRoutes'
import { type ClassroomData } from '../../../constants/types'

const ClassroomForm = (
  { classroom, url, method = 'post' }:
    { url: string, classroom?: ClassroomData, method?: string }
): ReactElement => {
  const { t } = useTranslation('translations')
  const { setInput, sendData, validationErrors, input } = useApiPost({ url, redirect: ADMIN_CLASSROOMS_PATH, successFlashMessage: 'Pomyślnie zapisano' })

  useEffect(() => {
    if (classroom) setInput((_p: any) => classroom)
  }, [classroom])

  const handleSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault()
    await sendData({ method })
  }

  const setNumber = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const number = e.target.value ? parseInt(e.target.value) : null

    setInput((prevInput: any) => {
      return { ...prevInput, number }
    })
  }

  return (
    <form method={method} onSubmit={handleSubmit}>
      <label>{t('admin.classrooms.attributes.number')}</label>
      <br />
      <input
        value={input.number}
        className="px-2 py-1 border rounded-md w-full"
        onChange={setNumber}
      />
      <div className="text-sm text-red-500">
        {map(validationErrors.number, (error: string): string => `${t('admin.classrooms.attributes.number')} ${error}`).join(', ')}
      </div>
      <br />
      <div className="flex justify-center pt-3">
        <button type="submit" className="bg-blue-400 rounded-md text-white p-2">
          {t('admin.classrooms.submit_button')}
        </button>
      </div>
    </form>
  )
}

export default ClassroomForm
