import React, { type FormEvent, type ReactElement, useEffect } from 'react'
import { map } from 'underscore'
import { useTranslation } from 'react-i18next'
import { useParseDate } from '../../../hooks/useParseDate'
import { useApiFetch } from '../../../hooks/api/useApiFetch'
import { API_ADMIN_CALENDAR_EVENT_TYPES_PATH } from '../../../constants/apiRoutes'
import { useApiPost } from '../../../hooks/api/useApiPost'
import { ADMIN_CALENDAR_EVENTS_PATH } from '../../../constants/adminRoutes'
import { type CalendarEventData } from '../../../constants/types'
import DropDownIcon from '../../../assets/icons/dropdown.svg'

const CalendarEventForm = (
  { calendarEvent, url, method = 'post' }:
  { url: string, calendarEvent?: CalendarEventData, method?: string }
): ReactElement => {
  const { t } = useTranslation('translations')
  const { toInputDate } = useParseDate()

  const { setInput, sendData, validationErrors, input } = useApiPost({ url, redirect: ADMIN_CALENDAR_EVENTS_PATH, successFlashMessage: 'Pomyślnie zapisano' })
  const { data: eventTypes } = useApiFetch({ url: API_ADMIN_CALENDAR_EVENT_TYPES_PATH })

  useEffect(() => {
    if (calendarEvent) {
      setInput((_p: any) => calendarEvent)
    }
  }, [calendarEvent])

  const handleSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault()
    await sendData({ method })
  }

  return (
    <form method={method} onSubmit={handleSubmit}>
      <label className='text-lg font-medium ml-1'>{t('admin.calendar_events.attributes.title')}</label>
      <br />
      <input
        value={input.title}
        className='px-2 py-1 border border-gray-300 rounded-lg mb-3 mt-3 h-12 w-full'
        onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, title: e.target.value })) }}
      />
      <div className='text-sm text-red-500'>
        {map(validationErrors.title, (error: string): string => `${t('admin.calendar_events.attributes.title')} ${error}`).join(', ')}
      </div>
      <br />
      <label className='text-lg font-medium ml-1'>{t('admin.calendar_events.attributes.description')}</label>
      <br />
      <textarea
        rows={6}
        value={input.description}
        className='px-2 py-1 border border-gray-300 rounded-lg mt-3 mb-3 py-2 resize-none w-full lg:w-5/6'
        onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, description: e.target.value })) }}
      />
      <div className='text-sm text-red-500'>
        {map(validationErrors.description, (error: string): string => `${t('admin.calendar_events.attributes.description')} ${error}`).join(', ')}
      </div>
      <br />
      <label className='text-lg font-medium ml-1'>{t('admin.calendar_events.attributes.event_type')}</label>
      <br />
      <div className='relative w-full lg:w-2/3'>
        <div className='flex items-center border border-gray-300 rounded py-0.5 mt-3 mb-3'>
          <select
            value={input.event_type}
            className='text-lg bg-transparent pr-10 py-2 pl-4 w-full cursor-pointer outline-none appearance-none'
          >
            <option value=''>{t('admin.calendar_events.event_types.placeholder')}</option>
            {map(eventTypes, (eventType: string) => {
              return <option value={eventType}>{t(`admin.calendar_events.event_types.${eventType}`)}</option>
            })}
          </select>
          <div className='absolute right-2 top-1/2 transform -translate-y-1/2 pointer-events-none mr-5'>
            <img src={DropDownIcon} alt='DropDown'/>
          </div>
        </div>
      </div>
      <div className='text-sm text-red-500'>
        {map(validationErrors.event_type, (error: string): string => `${t('admin.calendar_events.attributes.event_type')} ${error}`).join(', ')}
      </div>
      <br />
      <label className='text-lg font-medium ml-1'>{t('admin.calendar_events.attributes.starts_at')}</label>
      <br />
      <input
        type='date'
        value={toInputDate(input.starts_at)}
        className='border border-gray-300 rounded-lg shadow-sm mt-2 px-5 py-4 w-2/3 cursor-pointer'
        onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, starts_at: e.target.value })) }}
      />
      <div className='text-sm text-red-500'>
        {map(validationErrors.starts_at, (error: string): string => `${t('admin.calendar_events.attributes.starts_at')} ${error}`).join(', ')}
      </div>
      <br />
      <label className='text-lg font-medium ml-1'>{t('admin.calendar_events.attributes.ends_at')}</label>
      <br />
      <input
        type='date'
        value={toInputDate(input.ends_at)}
        className='border border-gray-300 rounded-lg shadow-sm mt-2 px-5 py-4 w-2/3 cursor-pointer'
        onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, ends_at: e.target.value })) }}
      />
      <div className='text-sm text-red-500'>
        {map(validationErrors.ends_at, (error: string): string => `${t('admin.calendar_events.attributes.ends_at')} ${error}`).join(', ')}
      </div>
      <br />
      <div className='grid place-items-end'>
        <button type='submit' className='bg-blue-500 hover:bg-blue-700 text-white py-3 px-6 rounded md:mt-10'>
          {t('admin.calendar_events.submit_button')}
        </button>
      </div>
    </form>
  )
}

export default CalendarEventForm
