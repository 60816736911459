import React, { type ReactElement, useState } from 'react'
import { LessonViewContext } from './LessonViewContext'

const LessonViewContextProvider = ({ children }: { children: ReactElement | null }): ReactElement => {
  const [lessonSubjectAndUnits, setLessonSubjectAndUnits] = useState('')

  return (
    <LessonViewContext.Provider value={{ lessonSubjectAndUnits, setLessonSubjectAndUnits }}>
      {children}
    </LessonViewContext.Provider>
  )
}

export default LessonViewContextProvider
