import { type UseParseDateMethods } from '../constants/types'

export const useParseDate = (): UseParseDateMethods => {
  const toLocalDate = (date: Date): string => {
    if (!date) return ''

    return new Date(date).toLocaleDateString(
      'pl-PL',
      { day: '2-digit', month: '2-digit', year: 'numeric' }
    )
  }

  const toLocalDateWithHour = (date: Date): string => {
    if (!date) return ''

    return new Date(date).toLocaleDateString(
      'pl-PL',
      { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' }
    )
  }

  const toInputDate = (date: Date): string => {
    if (!date) return ''

    return new Date(date).toISOString().split('T')[0]
  }

  const toShortHumanizedDate = (date: Date): string => {
    if (!date) return ''

    return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
  }

  return { toLocalDate, toLocalDateWithHour, toInputDate, toShortHumanizedDate }
}
