import React, { type ReactElement } from 'react'
import ActiveTabFilter from './ActiveTabFilter'
import InactiveTabFilter from './InactiveTabFilter'
import DisabledTabFilter from './DisabledTabFilter'

const TabFilter = (
  { setFilters, text, counter, active }:
  { setFilters: (prevFilers: any) => void, text: string, counter: number | null, active: boolean }
): ReactElement => {
  if (!counter) return <DisabledTabFilter text={text} />
  if (active) return <ActiveTabFilter setFilters={setFilters} text={`${text} (${counter})`} />

  return <InactiveTabFilter setFilters={setFilters} text={`${text} (${counter})`} />
}

export default TabFilter
