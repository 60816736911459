import React, { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useApiPost } from '../../hooks/api/useApiPost'

const CommentForm = ({ setComments, apiUrl, reply = false }: { setComments?: (prevData: any) => void, apiUrl: string, reply?: boolean }): ReactElement => {
  const { sendData, setInput } = useApiPost({ url: apiUrl })
  const { t } = useTranslation('translations')

  const createComment = async (e): Promise<void> => {
    e.preventDefault()
    await sendData()

    window.location.reload()
  }

  return (
    <>
      <form className={reply ? 'p-6 mb-3 ml-6 lg:ml-12' : 'mb-6'} onSubmit={createComment}>
        <div
          className="py-2 px-4 mb-4 bg-white rounded-lg rounded-t-lg border border-gray-200">
          <label htmlFor="comment" className="sr-only">Your comment</label>
          <textarea id="body" rows={reply ? 1 : 6}
            className="px-0 w-full resize-none text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none"
            placeholder={reply ? t('comments.reply_placeholder') : t('comments.placeholder')}
            required={true}
            onChange={(e) => setInput((prevInput: any) => ({ ...prevInput, body: e.target.value }))}
          />
        </div>
        <button type="submit"
          className="inline-flex items-center py-2.5 px-4 text-xs font-medium text-center text-white bg-blue-700 rounded-lg focus:ring-4 focus:ring-blue-200 hover:bg-blue-800">
          {
            reply
              ? t('comments.reply')
              : t('comments.submit')
          }
        </button>
      </form>
    </>
  )
}

export default CommentForm
