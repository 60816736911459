import { STUDENT_ROLE, TEACHER_ROLE } from '../../constants/roles'
import { STUDENT_DASHBOARD_PATH } from '../../constants/studentRoutes'
import { ADMIN_DASHBOARD_PATH } from '../../constants/adminRoutes'
import { TEACHER_DASHBOARD_PATH } from '../../constants/teacherRoutes'
import { type CurrentUserData } from '../../constants/types'

class GetRootFromRoleService {
  call (currentUser: CurrentUserData | null, admin = true): string {
    if (currentUser?.admin && admin) {
      return ADMIN_DASHBOARD_PATH
    } else if (currentUser?.role === STUDENT_ROLE) {
      return STUDENT_DASHBOARD_PATH
    } else if (currentUser?.role === TEACHER_ROLE) {
      return TEACHER_DASHBOARD_PATH
    } else {
      return '/'
    }
  }
}

export default new GetRootFromRoleService()
