import React, { type ReactElement, useContext } from 'react'
import MainBar from './navbar/MainBar'
import SubBar from './navbar/SubBar'
import MobileMenu from './navbar/MobileMenu'
import MobileMainBar from './navbar/MobileMainBar'
import { MobileMenuContext } from '../../contexts/common/MobileMenuContext'

const Navbar = (): ReactElement => {
  const { renderMobileNavbar } = useContext(MobileMenuContext)

  return (
    <>
      {renderMobileNavbar
        ? (
          <>
            <MainBar />
            <SubBar />
          </>
        )
        : (
          <MobileMainBar />
        )}
      <MobileMenu />
    </>
  )
}

export default Navbar
