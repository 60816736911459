import React, { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { map } from 'underscore'
import SchoolListItem from '../../components/schools/SchoolListItem'
import Pagination from '../../components/common/Pagination'
import { API_ADMIN_SCHOOLS_PATH } from '../../constants/apiRoutes'
import { type SchoolListItemData } from '../../constants/types'
import { useApiFetch } from '../../hooks/api/useApiFetch'
import { Link } from 'react-router-dom'
import Plus from '../../assets/icons/plus.svg'

const AdminDashboardView = (): ReactElement => {
  const { data: schools, fetchData } = useApiFetch({ url: API_ADMIN_SCHOOLS_PATH, paginated: true })
  const { t } = useTranslation('translations')

  return (
    <div className="w-full flex justify-center">
      <div className="w-5/6 pb-10 pt-10">
        <div className="flex justify-between pb-8 pt-4">
          <div className="text-xl font-bold font-['Inter'] leading-tight lg:block hidden">
            {t('admin.schools.h1')}
          </div>
          <div>
            <Link
              to=""
              className="px-5 py-3 w-max bg-blue-500 hover:bg-blue-700 rounded py-3 px-5 text-white flex"
            >
              {t('admin.schools.add_button')}
              <img src={Plus} alt="plus.svg" className="ml-2" />
            </Link>
          </div>
        </div>
        <div className="shadow-lg rounded-lg">
          <div className="card border border-gray-100 grid lg:grid-cols-10 rounded-t-lg py-6 px-5 bg-[#F9FAFB]">
            <div className="font-['Inter'] col-span-1 lg:block hidden">{t('admin.schools.attributes.id')}</div>
            <div className="font-['Inter'] col-span-4 lg:block hidden">{t('admin.schools.attributes.name')}</div>
            <div className="font-['Inter'] col-span-4 lg:block hidden">{t('admin.schools.attributes.patron')}</div>
            <div className="font-['Inter'] font-extrabold lg:hidden block">{t('admin.schools.h1')}</div>
            <div className="font-['Inter'] col-span-1" />
          </div>
          <div className="border border-gray-100 divide-y">
            {
              map(schools?.data, (school: SchoolListItemData): ReactElement => {
                return <SchoolListItem school={school} key={school.id} />
              })
            }
          </div>
          <div className="border border-gray-100">
            <div className="p-4 rounded-lg shadow-lg">
              <Pagination paginationMeta={schools?.meta} fetchData={fetchData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminDashboardView
