import React, { useContext } from 'react'
import { type NewsData } from '../../constants/types'
import { NEWS_DETAILS_VIEW } from '../../constants/routes'
import { ADMIN_EDIT_NEWS_PATH } from '../../constants/adminRoutes'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import EllipsisVerticalIcon from '../../assets/icons/ellipsis_vertical.svg'
import Dropdown, { DropdownItem, DropdownHead, DropdownMenu } from '../common/Dropdown'
import { useParseDate } from '../../hooks/useParseDate'
import { useApiPost } from '../../hooks/api/useApiPost'
import { API_NEWS_READINGS_PATH } from '../../constants/apiRoutes'
import { NotReadRequiredNewsContext } from '../../contexts/news/NotReadRequiredNewsContext'
import { CurrentUserContext } from '../../contexts/users/CurrentUserContext'

const NewsListItem = ({ news }: { news: NewsData }): React.ReactElement => {
  const { t } = useTranslation('translations')
  const { toLocalDate } = useParseDate()
  const navigate = useNavigate()
  const { currentUser } = useContext(CurrentUserContext)
  const { notReadRequiredNewsCount, setNotReadRequiredNewsCount } = useContext(NotReadRequiredNewsContext)

  const { sendData: sendMarkAsRead } = useApiPost({ url: API_NEWS_READINGS_PATH.replace(':news_id', news.id.toString()) })
  const newsBackground = news.read ? 'bg-gray-100 hover:bg-gray-200' : 'bg-white hover:bg-gray-100'
  const notReadDotColor = news.required_to_read ? 'bg-red-500' : 'bg-blue-600'
  const newsItemXPadding = news.pinned ? '' : 'px-5'

  const sendMarkAsUnreadRequest = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>): Promise<void> => {
    e.preventDefault()
    e.stopPropagation()

    await sendMarkAsRead({ method: 'delete' })
    if (news.required_to_read) {
      setNotReadRequiredNewsCount(notReadRequiredNewsCount + 1)
    }

    window.location.reload()
  }

  const sendMarkAsReadRequest = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>, redirect: boolean): Promise<void> => {
    e.preventDefault()
    e.stopPropagation()
    await sendMarkAsRead({ additionalParams: { news_id: news.id } })

    if (news.required_to_read) {
      setNotReadRequiredNewsCount(notReadRequiredNewsCount - 1)
    }

    if (redirect) {
      navigate(NEWS_DETAILS_VIEW.replace(':id', news.id.toString()))
    } else {
      window.location.reload()
    }
  }

  const navigateToAdminNewsEditView = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>): Promise<void> => {
    e.preventDefault()
    e.stopPropagation()

    navigate(ADMIN_EDIT_NEWS_PATH.replace(':id', news.id.toString()))
  }

  return (
    <div className={`${newsBackground} border border-gray-200 shadow-sm rounded-xl relative`}>
      {!news.read && (
        <div className={`absolute left-[7px] top-[14px] transform -translate-y-1/2 h-3 w-3 ${notReadDotColor} rounded-full`} />
      )}

      <div className="absolute top-4 right-4 z-10">
        <Dropdown>
          <DropdownHead>
            <img
              src={EllipsisVerticalIcon}
              alt="Ellipsis"
              className="h-5 w-5 cursor-pointer"
              onClick={(e) => { e.stopPropagation() }}
            />
          </DropdownHead>
          <DropdownMenu>
            <>
              <DropdownItem
                text={news.read ? t('news.mark_as_not_read') : t('news.mark_as_read')}
                action={news.read ? sendMarkAsUnreadRequest : async (e) => { await sendMarkAsReadRequest(e, false) }}
              />
              {currentUser?.admin && (
                <DropdownItem
                  text={t('admin.news.edit_button')}
                  action={navigateToAdminNewsEditView}
                />
              )}
            </>
          </DropdownMenu>
        </Dropdown>
      </div>

      <div className="inline-flex w-full">
        {news.pinned && (
          <div className="flex lg:pl-10 pl-5 items-center">
            <svg
              onClick={(e) => { e.stopPropagation() }}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-8 w-8 flex-shrink-0"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z"
              />
            </svg>
          </div>
        )}
        <div
          onClick={async (e) => { await sendMarkAsReadRequest(e, true) }}
          className="p-5 cursor-pointer overflow-hidden w-full"
        >
          <div className="flex justify-between items-start overflow-hidden">
            <div className={`${newsItemXPadding} flex items-center`}>
              <h3 className="lg:text-xl text-md font-semibold text-gray-900 truncate text-ellipsis whitespace-nowrap w-full">
                {news.title}
              </h3>
            </div>
          </div>

          <div className="h-full flex space-y-3 w-full">
            <div className={`${newsItemXPadding} flex flex-col overflow-hidden`}>
              <div className="truncate text-sm text-gray-700 truncate text-ellipsis whitespace-nowrap w-full">
                {news.content_as_text}
              </div>
              <p className="mt-3 text-xs text-gray-500 lg:block hidden">
                {t('news.created_at')} {toLocalDate(news.created_at)},
                {t('news.updated_at')} {toLocalDate(news.updated_at)},
                {t('news.author')} {news.created_by.name}
              </p>
              <div className="lg:hidden flex flex-col text-xs mt-2 text-gray-500">
                {t('news.created_at')} {toLocalDate(news.created_at)}
                <br />
                {t('news.updated_at')} {toLocalDate(news.updated_at)}
                <br />
                {t('news.author')} {news.created_by.name}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewsListItem
