import React, { type ReactElement, type ChangeEvent } from 'react'
import { useApiPost } from '../../hooks/api/useApiPost'
import { LOGIN_PATH } from '../../constants/routes'
import { map } from 'underscore'
import { useTranslation } from 'react-i18next'
import { API_FORGOT_PASSWORD_PATH } from '../../constants/apiRoutes'

const ForgotPasswordForm = (): ReactElement => {
  const { sendData, validationErrors, setInput } = useApiPost({ url: API_FORGOT_PASSWORD_PATH, redirect: LOGIN_PATH })

  const { t } = useTranslation('translations')

  const sendResetPasswordInstructions = async (e: ChangeEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()

    await sendData({ additionalParams: { redirect_url: process.env.REACT_APP_FORGOT_PASSWORD_REDIRECT_BACK_URL } })
  }

  return (
    <form onSubmit={sendResetPasswordInstructions}>
      <label>{t('labels.email')}</label>
      <br />
      <input
        type="email"
        data-test-id="emailInput"
        className="px-2 py-1 border rounded-md w-full"
        onChange={(e) => { setInput({ email: e.target.value }) }}
      />
      <div className="text-sm text-red-500">
        {map(validationErrors.email, (error: string): string => `Email ${error}`).join(', ')}
      </div>
      <br />
      <br />
      <div className="flex justify-center pt-3">
        <button type="submit" className="bg-blue-400 rounded-md text-white p-2">
          {t('forgot_password_view.submit_button')}
        </button>
      </div>
    </form>
  )
}

export default ForgotPasswordForm
