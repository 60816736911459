import type React from 'react'

export interface CalendarEventData {
  id: number
  title: string
  description: string
  starts_at: Date
  ends_at: Date
}

export interface CalendarItemData {
  id: number
  title: string
  description: string
  event_type?: string
  starts_at: Date
  ends_at: Date
}

export interface DocumentItemData {
  id: number
  name: string
  created_at: Date
  files: FileData[]
}

export interface FileData {
  id: number
  filename: string
  url: string
}

export interface SchoolListItemData {
  id: number
  name: string
  patron: string | null
}

export interface ValidationError {
  error: string
  details: Record<string, any>
}

export interface AuthTokensResponse {
  data: {
    access_token: string
    refresh_token: string
  }
}

export interface CurrentUserData {
  user_id: number
  role: string
  name: string
  avatar_url: string
  admin: boolean
}

export interface CurrentUserContextProps {
  currentUser: CurrentUserData | null
  setCurrentUser: (newValue: CurrentUserData | null) => void
  logout: () => void
  fetchCurrentUser: () => Promise<void>
}

export interface ActiveTabContextProps {
  activeTab: string
  setActiveTab: (newValue: string) => void
}

export enum FlashType {
  'info', 'error', 'success', 'warning'
}

export interface FlashData {
  message: string
  type?: FlashType
}

export interface FlashContextProps {
  flash: FlashData
  setFlash: (newValue: any) => void
}

export interface NavbarLinkProps {
  path: string
  className?: string
  activeTabClass?: string
  children: string | JSX.Element | JSX.Element[] | Array<string | JSX.Element>
  setRenderMobileMenu?: React.Dispatch<React.SetStateAction<boolean>>
}

export interface DropdownItemProps {
  action?: (e?: any) => any
  text: string
  link?: string
  icon?: string
}

export interface HomeworkData {
  id: number
  title: string
  description: string
  deadline: Date
  file_upload_deadline: Date
  required: boolean
  graded: boolean
  lesson?: LessonData
  class_units: ClassUnitData[]
  groups: GroupData[]
  homework_solutions: StudentHomeworkSolutionData[]
  students: StudentData[]
}

export interface LessonData {
  id: number
  subject: SubjectData
  teacher: TeacherData
  semester?: SemesterData
  class_units?: ClassUnitData[]
  groups?: GroupData[]
  grades: GradeData[]
  final_grades: GradeData[]
  homeworks?: HomeworkData[]
}

export interface GradeData {
  id: number
  note: string
  value: string
  grade_type: string
  semester: SemesterData
  assessment_category: AssessmentCategoryData
}

export interface AssessmentCategoryData {
  id: number
  name: string
  weight: number
  class_units?: ClassUnitData[]
  groups?: GroupData[]
  homeworks?: HomeworkData[]
}

export interface SemesterData {
  id: number
  year: string
  season: string
}

export interface TeacherData {
  id: number
  user: CurrentUserData
}

export interface ParentData {
  id: number
  user: CurrentUserData
}

export interface StudentData {
  id: number
  user: CurrentUserData
  homework_solution: StudentHomeworkSolutionData
  class_unit: ClassUnitData
}

export interface ClassUnitData {
  id: number
  name: string
  tutor: TeacherData
}

export interface GroupData {
  id: number
  name: string
}

export interface SubjectData {
  id: number
  name: string
}

export interface StudentHomeworkSolutionData {
  id: number
  created_at: Date
  title: string
  internal_teacher_note: string
  teacher_note: string
  autonomous_work_accepted_at: Date
  teacher_review: string
  homework: HomeworkData
  student: StudentData
  student_answer: string
  solution_files: FileData[]
}

export interface LessonInstanceData {
  id: number
  title: string
  description: string
  starts_at: Date
  ends_at: Date
}

export interface LessonSlotItemData {
  id: number
  starts_at: string
  ends_at: string
  day_of_week: string
}

export interface ClassroomData {
  id: number
  number: string
  created_at: Date
}
export interface StudentSubjectData {
  id: number
  name: string
}

export interface MessageData {
  id: number
  body: string
  receiver_email?: string
  read: boolean
  is_sender: boolean
  created_at: string
}

export interface ConversationData {
  id: number
  email: string
}

export interface MessagesContextProps {
  messages: MessageData[]
  setMessages: React.Dispatch<React.SetStateAction<MessageData[]>>
  conversations: ConversationData[]
  setConversations: (newValue: ConversationData[]) => void
  receiverEmail: string
  setReceiverEmail: (newValue: string) => void
  messagesPagination: Record<string, any>
  setMessagesPagination: (newValue: any) => void
  messagesPaginationMeta: Record<string, any>
  setMessagesPaginationMeta: (newValue: any) => void
}

export interface InfiniteScrollProps {
  items: Array<Record<string, any>>
  fetchMoreItems: (page: number) => Promise<void>
  renderComponent: (item: any) => React.ReactElement
  paginationMeta: Record<string, any>
  pagination: Record<string, any>
  setPagination: (newValue: any) => void
}

export interface UseApiPostReturnParams {
  sendData: (params?: { callback?: (response: any) => any | null, additionalParams?: Record<any, any>, method?: string, apiUrl?: string }) => any
  sendDataWithFiles: (params: { fileKeys: string[], callback?: (response: any) => any | null, additionalParams?: Record<any, any>, headers?: Record<string, any>, method?: string }) => any
  setInput: (prevInput: any) => any
  validationErrors: ValidationError['details']
  input: Record<any, any>
}

export interface UseApiGetReturnParams {
  fetchData: (query?: Record<any, any>) => any
  setData: (prevData: any) => void
  data: any
  setQuery: (prevQuery: Record<string, string>) => void
  query: Record<string, string>
}

export interface NewsData {
  id: number
  title: string
  content: string
  content_as_text: string
  created_at: Date
  updated_at: Date
  expires_at: Date
  pinned_until: Date
  pinned: boolean
  expired: boolean

  read: boolean
  required_to_read: boolean
  created_by: UserData
}

export interface SearchResultData {
  id: number
  name: string
  status: string
  type: string
  category: string
  avatar_url: string
}

export interface SchoolYearData {
  id: number
  year: string
}

export interface CommentData {
  id: number
  body: string
  created_at: Date
  written_by: UserData
  replies: CommentData[]
}

export interface UserData {
  id: number
  name: string
  email: string
  avatar: FileData
}

export interface AdvancedSearchingFilters {
  category: string
  query: string
  status: string
  type: string
  sort_by: string
}

export interface AdvancedSearchingCounters {
  users: number
  classunits: number
  lessons: number
  sum: number
}

export interface SelectOptions {
  value: string
  text: string
  disabled?: boolean
}

export interface UseParseDateMethods {
  toLocalDate: (date: Date) => string
  toLocalDateWithHour: (date: Date) => string
  toInputDate: (date: Date) => string
  toShortHumanizedDate: (date: Date) => string
}

export interface DropdownProps {
  className?: string
  id?: string
  onBlur?: (event: React.FocusEvent<HTMLElement>) => any
  parent?: React.ReactElement
  children: React.ReactElement | React.ReactElement[]
}

export interface SearchChangeCountersContextProps {
  changeCounters: boolean
  setChangeCounters: (prevValue: boolean) => void
}

export interface NotReadRequiredNewsContextProps {
  notReadRequiredNewsCount: number
  setNotReadRequiredNewsCount: (prevNewsCount: number) => void
}

export interface EditorComponentProps {
  content: any
  validationErrors: ValidationError['details']
  setContent: (prevInput: any) => any
  fileUploadURL: string
}

export interface MobileMenuContextProps {
  renderMobileMenu: boolean
  setRenderMobileMenu: React.Dispatch<React.SetStateAction<boolean>>
  renderMobileNavbar: boolean
  setRenderMobileNavbar: React.Dispatch<React.SetStateAction<boolean>>
}

export interface LessonViewContextProps {
  lessonSubjectAndUnits: string
  setLessonSubjectAndUnits: (newValue: string) => void
}

export interface PaginationProps {
  paginationMeta: Record<string, any>
  fetchData: (query: Record<string, any>) => Promise<void>
  filters?: Record<string, any>
  sortParams?: Record<string, any>
}

export interface HomeworksCounters {
  sum: number
  checked: number
  not_sent: number
  to_check: number
  sent: number
}

export interface DatePickerWithHourProps {
  selectedDate: Date | null
  onSelectDate: (date: string) => void
  closeDatePicker: () => void
}

export interface AddressData {
  id: number
  address_type: string
  apartment_number: string
  country: string
  postcode: string
  region: string
  street: string
}
