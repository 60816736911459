import React, { type ReactElement, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const ExamsAccordion = ({ lesson }: { lesson: Record<string, any> }): ReactElement => {
  const { t } = useTranslation('translations')
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024)

  useEffect(() => {
    const updateScreenSize = (): void => {
      setIsLargeScreen(window.innerWidth >= 1024)
    }
    window.addEventListener('resize', updateScreenSize)
    return () => {
      window.removeEventListener('resize', updateScreenSize)
    }
  }, [])

  return (
    <div className="w-full hs-accordion-group shadow border border-gray-200 rounded-2xl bg-white">
      <div className={`hs-accordion ${isLargeScreen ? 'active' : ''}`} id="exams-accordion">
        <button className="hs-accordion-toggle py-5 px-4 inline-flex items-center justify-between gap-x-3 w-full font-semibold text-start hover:text-gray-500 rounded-lg disabled:opacity-50 disabled:pointer-events-none" aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-one">
          {t('lessons.links.exams')}
          <svg className="hs-accordion-active:hidden block size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="m6 9 6 6 6-6"></path>
          </svg>
          <svg className="hs-accordion-active:block hidden size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="m18 15-6-6-6 6"></path>
          </svg>
        </button>
        <div id="exams-accordion" className="hs-accordion-content hidden lg:block hs-accordion-active:border-t hs-accordion-active:border-gray-200 w-full px-4 overflow-hidden transition-[height] duration-300" aria-labelledby="exams-accordion">
          <div className="pt-1">
            <div className="self-stretch flex-col justify-start items-start flex">
              <div className="self-stretch py-5 justify-end items-center gap-2 inline-flex">
                <Link
                  to=""
                  className="flex items-center gap-2 text-blue-900 hover:text-blue-500 text-sm font-medium font-['DM Sans'] leading-tight"
                >
                  {t('lessons.links.all_exams')}
                  <div className="w-5 h-5 relative">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                    </svg>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExamsAccordion
