import React, { type ReactElement } from 'react'
import ForgotPasswordForm from '../components/users/ForgotPasswordForm'
import { useTranslation } from 'react-i18next'

const ForgotPasswordView = (): ReactElement => {
  const { t } = useTranslation('translations')

  return (
    <div className="grid h-screen place-items-center" >
      <div className="p-12 card border rounded-md">
        <h1 className="text-3xl font-bold text-center pb-5">{t('forgot_password_view.header')}</h1>
        <ForgotPasswordForm />
      </div>
    </div>
  )
}

export default ForgotPasswordView
