import React, { type ReactElement } from 'react'
import StudentHomeworkSolutionForm from '../../components/students/homework_solutions/StudentHomeworkSolutionForm'
import { API_STUDENT_HOMEWORK_SOLUTIONS_PATH } from '../../constants/apiRoutes'

const StudentNewHomeworkSolutionView = (): ReactElement => {
  return (
    <div className="grid h-screen" >
      <StudentHomeworkSolutionForm url={API_STUDENT_HOMEWORK_SOLUTIONS_PATH}/>
    </div>
  )
}

export default StudentNewHomeworkSolutionView
