import React, { type ReactElement, useState } from 'react'
import { FlashContext } from './FlashContext'
import { type FlashData } from '../../constants/types'

const FlashContextProvider = ({ children }: { children: ReactElement }): ReactElement => {
  const [flash, setFlash] = useState({} as FlashData)

  return (
    <FlashContext.Provider value={{ flash, setFlash }}>
      {children}
    </FlashContext.Provider>
  )
}

export default FlashContextProvider
