import React, { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { API_ADMIN_LESSONS_PATH } from '../../../constants/apiRoutes'
import LessonForm from '../../../components/admin/lessons/LessonForm'

const NewLessonView = (): ReactElement => {
  const { t } = useTranslation('translations')

  return (
    <div className="grid h-screen place-items-center w-full" >
      <div className="p-12 card border rounded-md">
        <h1 className="text-3xl font-bold text-center pb-3">{t('admin.lessons.new_header')}</h1>
        <LessonForm url={API_ADMIN_LESSONS_PATH}/>
      </div>
    </div>
  )
}

export default NewLessonView
