import React, { type ReactElement } from 'react'
import { type LessonData, type GradeData } from '../../constants/types'
import { map, filter, isEmpty, find } from 'underscore'

const StudentGradesView = ({ lessons }: { lessons: LessonData[] }): ReactElement => {
  const semesterGrades = (lesson: LessonData, semester: string): string[] => {
    let semesterGrades = [] as GradeData[]

    if (semester === 'year') {
      semesterGrades = lesson.grades
    } else {
      semesterGrades = filter(lesson.grades, (grade) => grade.semester.season === semester)
    }

    return map(semesterGrades, (grade) => grade.value)
  }

  const semesterGradeList = (lesson: LessonData, semester: string): string => {
    if (isEmpty(lesson.grades)) return 'Brak ocen'

    return semesterGrades(lesson, semester).join(', ')
  }

  const finalSemesterGrade = (lesson: LessonData, season: string): string => {
    const finalGrade = find(lesson.final_grades, (grade) => {
      return grade.semester.season === season && grade.grade_type === 'semester_final'
    })

    return finalGrade?.value ?? ''
  }

  const finalYearGrade = (lesson: LessonData): string => {
    return find(lesson.final_grades, (grade) => grade.grade_type === 'year_final')?.value ?? ''
  }

  return (
    <div className="container mx-auto p-4">
      <table className="min-w-full border-collapse border border-gray-200">
        <thead>
          <tr>
            <th className="border border-gray-300 p-2 bg-gray-100">Przedmiot</th>
            <th className="border border-gray-300 p-2 bg-gray-100">Semester Zimowy</th>
            <th className="border border-gray-300 p-2 bg-gray-100">Śr.I</th>
            <th className="border border-gray-300 p-2 bg-gray-100">Końcowa.I</th>
            <th className="border border-gray-300 p-2 bg-gray-100">Semester Letni</th>
            <th className="border border-gray-300 p-2 bg-gray-100">Końcowa.II</th>
            <th className="border border-gray-300 p-2 bg-gray-100">Śr.R</th>
            <th className="border border-gray-300 p-2 bg-gray-100">K.R</th>
          </tr>
        </thead>
        <tbody>
          {map(lessons, (lesson: LessonData) => {
            return (
              <tr key={lesson.id}>
                <td className="border border-gray-300 p-2">{lesson.subject.name}</td>
                <td className="border border-gray-300 p-2 text-center">
                  {semesterGradeList(lesson, 'fall')}
                </td>
                <td className="border border-gray-300 p-2 text-center">-</td>
                <td className="border border-gray-300 p-2 text-center">{finalSemesterGrade(lesson, 'fall')}</td>
                <td className="border border-gray-300 p-2 text-center">
                  {semesterGradeList(lesson, 'spring')}
                </td>
                <td className="border border-gray-300 p-2 text-center">{finalSemesterGrade(lesson, 'spring')}</td>
                <td className="border border-gray-300 p-2 text-center">-</td>
                <td className="border border-gray-300 p-2 text-center">{finalYearGrade(lesson)}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default StudentGradesView
