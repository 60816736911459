import React, { type ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { ADMIN_EDIT_CALENDAR_EVENT_PATH } from '../../../constants/adminRoutes'
import { type CalendarItemData } from '../../../constants/types'
import { useTranslation } from 'react-i18next'
import { useParseDate } from '../../../hooks/useParseDate'

const CalendarEventsListItem = ({ calendarEvent }: { calendarEvent: CalendarItemData }): ReactElement => {
  const { t } = useTranslation('translations')
  const { toLocalDate } = useParseDate()

  return (
    <div className="card grid grid-cols-4 lg:grid-cols-10 py-3 px-5 text-sm flex items-center">
      <div className="col-span-1 truncate hidden lg:block">{calendarEvent?.id}</div>
      <div className="col-span-2 truncate hidden lg:block">{calendarEvent?.title}</div>
      <div className="col-span-3 opacity-50 truncate hidden lg:block">{calendarEvent?.description}</div>
      <div className="col-span-1 truncate hidden lg:block mr-2">{t(`admin.calendar_events.event_types.${calendarEvent?.event_type}`)}</div>
      <div className="col-span-1 truncate hidden lg:block">{toLocalDate(calendarEvent?.starts_at)}</div>
      <div className="col-span-1 truncate hidden lg:block">{toLocalDate(calendarEvent?.ends_at)}</div>
      <div className="col-span-3 block lg:hidden pr-2">
        ID {calendarEvent?.id}
        <br />
        <div className="truncate">{calendarEvent?.title}</div>
        <div className="opacity-50 truncate">{calendarEvent?.description}</div>
        {t(`admin.calendar_events.event_types.${calendarEvent?.event_type}`)}
        <br />
        {toLocalDate(calendarEvent?.starts_at)} - {toLocalDate(calendarEvent?.ends_at)}
      </div>
      <div className="col-span-1">
        <Link
          to={ADMIN_EDIT_CALENDAR_EVENT_PATH.replace(':id', calendarEvent?.id?.toString())}
          className="px-4 py-1 w-max border border-1 border-[#3758F9] rounded-2xl text-[#3758F9]"
        >
          {t('admin.calendar_events.edit_button')}
        </Link>
      </div>
    </div>
  )
}

export default CalendarEventsListItem
