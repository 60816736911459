import { useState, useEffect } from 'react'
import { useHandleApiError } from './useHandleApiError'
import { type UseApiGetReturnParams } from '../../constants/types'
import { map, pairs } from 'underscore'
import axios from '../../config/axios'

export const useApiFetch = (
  { url, paginated = false, onRender = true }: { url: string, paginated?: boolean, onRender?: boolean }
): UseApiGetReturnParams => {
  const [data, setData] = useState([])
  const [query, setQuery] = useState({})
  const handleError = useHandleApiError()

  const fetchData = async (additionalQuery = {}): Promise<any> => {
    try {
      const requestUrl = buildUrl(url, { ...query, ...additionalQuery })

      const response = await axios.get(requestUrl)
      const responseData = paginated ? response.data : response.data.data

      setData(responseData)
      return responseData
    } catch (error: any) {
      handleError(error)
    }
  }

  const buildUrl = (url: string, query: Record<string, string>): string => {
    const queryString = map(pairs(query), ([key, value]: [string, string]) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    }).join('&')

    return url.includes('?') ? `${url}&${queryString}` : `${url}?${queryString}`
  }

  useEffect(() => {
    if (onRender) void fetchData()
  }, [])

  return { data, setData, fetchData, setQuery, query }
}
