import React, { type ReactElement, useEffect, useState } from 'react'
import AdvancedSearchingResultItem from '../components/search/AdvancedSearchingResultItem'
import EmptySearchTableBody from '../components/search/EmptySearchTableBody'
import TabFilter from '../components/filters/tabs/TabFilter'
import MultiSelect from '../components/common/MultiSelect'
import { useLocation } from 'react-router-dom'
import { useApiFetch } from '../hooks/api/useApiFetch'
import { API_SEARCHES_PATH, API_SEARCHES_TYPES_PATH } from '../constants/apiRoutes'
import { isEmpty, map, omit } from 'underscore'
import { type AdvancedSearchingFilters, type AdvancedSearchingCounters } from '../constants/types'
import Pagination from '../components/common/Pagination'
import HSSelect from '@preline/select'

export const AdvancedSearchingView = (): ReactElement => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const queryString = queryParams.get('query')

  const [filters, setFilters] = useState({} as AdvancedSearchingFilters)
  const [counters, setCounters] = useState({} as AdvancedSearchingCounters)

  const { data: searchResult, fetchData } = useApiFetch({ url: API_SEARCHES_PATH, onRender: false, paginated: true })
  const { data: types } = useApiFetch({ url: API_SEARCHES_TYPES_PATH, paginated: true })

  useEffect(() => {
    fetchData(buildQueryParams()).then((result: any) => {
      if (isEmpty(counters)) setCounters(result.counters)
    })
    HSSelect.autoInit()
  }, [filters, queryString])

  const fetchPaginatedData = async (page: Record<string, number>): Promise<void> => {
    await fetchData({ ...buildQueryParams(), ...page })
  }

  const buildQueryParams = (): AdvancedSearchingFilters => {
    const query = { query: queryString as string } as AdvancedSearchingFilters

    if (filters.category) query.category = filters.category
    if (filters.type) query.type = filters.type
    if (filters.status) query.status = filters.status
    if (filters.sort_by) query.sort_by = filters.sort_by

    return query
  }

  const setSortingParam = (paramName: string, order?: string): void => {
    const sortParam = order
      ? `${paramName}.${order}`
      : filters.sort_by === `${paramName}.desc`
        ? `${paramName}.asc`
        : `${paramName}.desc`

    setFilters((prevVFilters) => ({ ...prevVFilters, sort_by: sortParam }))
  }

  return (
    <div className="w-full flex justify-center mb-10">
      <div className="lg:w-5/6 w-full">
        <div className="h-12 mb-6 lg:px-0 px-10 lg:py-0 py-10 overflow-hidden">
          <div className="text-neutral-800 text-[32px] font-semibold font-['DM Sans'] leading-[48px] tracking-tight truncate overflow-hidden text-ellipsis whitespace-nowrap w-full max-w-full">
            {queryString}
          </div>
        </div>

        <div>
          <div className="flex flex-col lg:flex-row justify-start items-center gap-4 mb-6">
            <div className="justify-start items-center gap-3 inline-flex lg:w-auto w-full">
              <div className="h-fit lg:p-1 px-3 bg-white lg:rounded-xl justify-start items-start gap-2.5 flex w-full shadow-md">
                <div className="overflow-x-auto lg:overflow-visible lg:py-0 py-2">
                  <div className="flex lg:flex-row flex-nowrap lg:w-max w-auto">
                    <TabFilter
                      setFilters={() => { setFilters((prevFilters: any) => omit(prevFilters, 'category')) }}
                      text='Wszystkie'
                      counter={counters?.sum}
                      active={!filters.category}
                    />
                    <TabFilter
                      setFilters={() => { setFilters((prevFilters: any) => ({ ...prevFilters, category: 'lessons' })) }}
                      text='Przedmioty'
                      counter={counters?.lessons}
                      active={filters.category === 'lessons'}
                    />
                    <TabFilter
                      setFilters={() => { setFilters((prevFilters: any) => ({ ...prevFilters, category: 'class_units' })) }}
                      text='Klasy'
                      counter={counters?.classunits}
                      active={filters.category === 'class_units'}
                    />
                    <TabFilter
                      setFilters={() => { setFilters((prevFilters: any) => ({ ...prevFilters, category: 'users' })) }}
                      text='Użytkownicy'
                      counter={counters?.users}
                      active={filters.category === 'users'}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-start items-start gap-4 w-full lg:w-auto lg:px-0 px-5">
              <MultiSelect
                id="type-select"
                search={true}
                options={map(types, (type: string) => ({ text: type, value: type }))}
                placeholder='Rodzaj'
                onChange={(value) => { setFilters((prevFilters: any) => ({ ...prevFilters, type: value })) }}
                toggleClasses="py-1.5"
                className="lg:w-[125px] relative w-full"
              />
              <MultiSelect
                id="status-select"
                options={[{ value: 'active', text: 'Aktywne' }, { value: 'archived', text: 'Zarchiwizowane' }, { value: '', text: 'Wybierz...' }]}
                placeholder={'Status'}
                onChange={(value) => { setFilters((prevFilters: any) => ({ ...prevFilters, status: value })) }}
                toggleClasses="py-1.5"
                className="lg:w-[125px] relative w-full"
              />
            </div>
          </div>

          <div className="lg:hidden block mb-5 px-5">
            <div className="relative">
              <select data-hs-select='{
                  "placeholder": "Sortuj według...",
                  "toggleTag": "<button type=\"button\" aria-expanded=\"false\"></button>",
                  "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 ps-4 pe-9 flex gap-x-2 text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-base focus:outline-none focus:ring-2 focus:ring-blue-500",
                  "dropdownClasses": "mt-2 z-50 w-full max-h-72 p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto shadow-xl",
                  "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100",
                  "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"shrink-0 size-3.5 text-blue-600 \" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" strokeWidth=\"2\" strokeLinecap=\"round\" strokeLinejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
              }'
              onChange={(e) => {
                const [field, order] = e.target.value.split('-')
                setSortingParam(field, order)
              }}
              defaultValue=""
              >
                <option value="" disabled>Sortuj według...</option>
                <option value="name-asc">Nazwa A-Z</option>
                <option value="name-desc">Nazwa Z-A</option>
                <option value="type-asc">Rodzaj A-Z</option>
                <option value="type-desc">Rodzaj Z-A</option>
              </select>

              <div className="absolute top-1/2 end-2.5 -translate-y-1/2">
                <svg className="shrink-0 h-4 w-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="m7 15 5 5 5-5"></path>
                  <path d="m7 9 5-5 5 5"></path>
                </svg>
              </div>
            </div>
          </div>

          <div className="lg:rounded-md lg:shadow-md lg:border border-gray-200 lg:mb-0 mb-36">
            <div className="card grid grid-cols-12 lg:border lg:border-gray-100 border-b-gray-300 lg:px-5 lg:py-3 bg-gray-100 bg-gray-100 text-sm text-gray-500 rounded-t-md">
              <div className="col-span-3 lg:block hidden">
                <div className="flex space-x-2 hover:cursor-pointer" onClick={() => { setSortingParam('name') }}>
                  <div className="font-['DM Sans']">
                    NAZWA
                  </div>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                  </svg>
                </div>
              </div>
              <div className="col-span-3 lg:block hidden">
                <div className="flex space-x-2 hover:cursor-pointer" onClick={() => { setSortingParam('type') }}>
                  <div className="font-['DM Sans']">
                    RODZAJ
                  </div>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                  </svg>
                </div>
              </div>
              <div className="col-span-3 lg:block hidden">
                <div className="font-['DM Sans']">
                  TYP
                </div>
              </div>
              <div className="col-span-3 lg:block hidden">
                STATUS
              </div>
            </div>
            <div className="lg:divide-y">
              {isEmpty(searchResult.data)
                ? (
                  <EmptySearchTableBody />
                )
                : (
                  map(searchResult.data, (item) => (
                    <AdvancedSearchingResultItem item={item} key={item.id} />
                  ))
                )
              }
            </div>
            <div className="p-4 rounded-b-lg border border-transparent border-t-gray-200">
              <Pagination paginationMeta={searchResult.meta} fetchData={fetchPaginatedData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdvancedSearchingView
