// UserProfileView component

import React, { useState, useEffect, type ReactElement } from 'react'
import { map } from 'underscore'
import 'react-image-crop/dist/ReactCrop.css'
import { useApiFetch } from '../../hooks/api/useApiFetch'
import { API_USERS_PROFILE_PATH } from '../../constants/apiRoutes'
import { USER_PROFILE_PATH } from '../../constants/routes'
import { useTranslation } from 'react-i18next'
import DefaultAvatar from '../../assets/default_avatar.svg'
import { useParams, Link } from 'react-router-dom'
import { useParseDate } from '../../hooks/useParseDate'
import { type TeacherData, type ParentData, type AddressData } from '../../constants/types'
import HSAccordion from '@preline/accordion'
import { STUDENT_ROLE, TEACHER_ROLE } from '../../constants/roles'

const UserProfileView = (): ReactElement => {
  const { t } = useTranslation('translations')
  const queryParams = useParams()
  const { toLocalDate } = useParseDate()

  const [avatarUrl, setAvatarUrl] = useState<string | null>(null)
  const { data: user, fetchData: fetchProfile } = useApiFetch({ url: API_USERS_PROFILE_PATH.replace(':id', queryParams.id as string), onRender: false })

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const response = await fetchProfile()

      if (response) {
        const avatarUrl = response.avatar?.url
        setAvatarUrl(avatarUrl)
      }
    }

    void fetchData()
  }, [queryParams.id])

  useEffect(() => {
    HSAccordion.autoInit()
  }, [])

  function formatAddress (address: AddressData): ReactElement {
    return (
      <div>
        {`${address.street} ${address.apartment_number}`}
        <br />
        {`${address.postcode} ${address.country}`}
      </div>
    )
  }

  function getResidentialAddress (addresses: AddressData[]): ReactElement {
    const address = addresses?.find(addr => addr.address_type === 'residential')
    return address ? formatAddress(address) : <div>{t('users.profile.not_available')}</div>
  }

  function getCorrespondenceAddress (addresses: AddressData[]): ReactElement {
    const address = addresses?.find(addr => addr.address_type === 'correspondence')
    return address ? formatAddress(address) : <div>{t('users.profile.not_available')}</div>
  }

  function getRegisteredAddress (addresses: AddressData[]): ReactElement {
    const address = addresses?.find(addr => addr.address_type === 'registered')
    return address ? formatAddress(address) : <div>{t('users.profile.not_available')}</div>
  }

  return (
    <div className="pt-10 pb-32 px-5 lg:py-10 lg:px-20">
      <div className="w-full lg:w-auto mx-auto">
        <div className="flex flex-col items-center lg:flex-row lg:justify-start lg:items-start">
          <div className="relative w-32 h-32 lg:w-40 lg:h-64 flex-shrink-0 mb-5 lg:mb-0">
            <img
              src={avatarUrl ?? DefaultAvatar}
              crossOrigin="anonymous"
              className="w-full h-full object-cover rounded-full lg:rounded-xl"
            />
          </div>
          <div className="lg:px-10 w-full text-center lg:text-left">
            <div className="text-2xl lg:text-3xl font-bold pb-5 w-full border-b">
              {user?.name}
            </div>

            <div className="space-y-5 lg:inline-flex lg:gap-5 lg:space-y-0 lg:px-0 px-5">
              {user?.role === STUDENT_ROLE && (
                <div className="grid lg:grid-cols-2 gap-x-3 gap-y-5 mt-5 max-w-fit self-start lg:max-h-fit">
                  <div className="lg:grid hidden text-zinc-500 lg:text-right">
                    {t('users.profile.class_unit')}:
                  </div>
                  <div className="lg:flex hidden items-start font-extrabold">
                    {user?.class_unit?.name}
                  </div>

                  <div className="flex lg:hidden lg:col-span-1 items-start text-right">
                    <span className="text-zinc-500">{t('users.profile.class_unit')}:</span>
                    <span className="font-extrabold ml-2">{user?.class_unit?.name}</span>
                  </div>

                  <div className="text-zinc-500 lg:text-right text-left">
                    {t('users.profile.tutor')}:
                  </div>
                  <div className="flex flex-col items-start space-y-2">
                    {map(user?.tutors, (tutor: TeacherData): ReactElement => (
                      <Link
                        to={USER_PROFILE_PATH.replace(':id', tutor?.id.toString())}
                        key={tutor?.id}
                        className="flex gap-3 items-center"
                      >
                        <img
                          src={tutor?.user?.avatar_url ?? DefaultAvatar}
                          className="object-cover cursor-pointer h-7 w-7 rounded-full"
                          alt="Avatar"
                        />
                        {tutor?.user?.name}
                      </Link>
                    ))}
                  </div>

                  <div className="font-['DM Sans'] text-zinc-700 text-right text-left">
                    {t('users.profile.parents')}:
                  </div>
                  <div className="flex flex-col items-start space-y-2">
                    {map(user?.parents, (parentUser: ParentData): ReactElement => (
                      <Link
                        to={USER_PROFILE_PATH.replace(':id', parentUser?.id.toString())}
                        key={parentUser?.id}
                        className="flex gap-3 items-center"
                      >
                        <img
                          src={parentUser?.user?.avatar_url ?? DefaultAvatar}
                          className="object-cover cursor-pointer h-7 w-7 rounded-full"
                          alt="Avatar"
                        />
                        {parentUser?.user?.name}
                      </Link>
                    ))}
                  </div>
                </div>
              )}

              {user?.access === 'full' && (
                <div className="flex flex-col pt-5 lg:flex-grow">
                  <div className="text-gray-500 pb-3 border-b flex gap-2 items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5">
                      <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                    </svg>
                    {t('users.profile.addresses')}
                  </div>

                  <div className="hs-accordion-group" data-hs-accordion-always-open="">
                    <div className="hs-accordion" id="hs-residential">
                      <button className="hs-accordion-toggle py-3 inline-flex items-center gap-x-3 w-full font-semibold text-start text-gray-800 hover:text-gray-500 focus:outline-none focus:text-gray-500 rounded-lg" aria-expanded="false" aria-controls="hs-residential-content">
                        <svg className="hs-accordion-active:hidden block h-4 w-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                          <path d="M5 12h14"></path>
                          <path d="M12 5v14"></path>
                        </svg>
                        <svg className="hs-accordion-active:block hidden h-4 w-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                          <path d="M5 12h14"></path>
                        </svg>
                        <div>{t('users.profile.residential_address')}</div>
                      </button>
                      <div id="hs-residential-content" className="hs-accordion-content text-start lg:pl-0 pl-5 hidden w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-residential">
                        {getResidentialAddress(user?.addresses)}
                      </div>
                    </div>

                    <div className="hs-accordion" id="hs-correspondence">
                      <button className="hs-accordion-toggle py-3 inline-flex items-center gap-x-3 w-full font-semibold text-start text-gray-800 hover:text-gray-500 focus:outline-none focus:text-gray-500 rounded-lg" aria-expanded="false" aria-controls="hs-correspondence-content">
                        <svg className="hs-accordion-active:hidden block h-4 w-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                          <path d="M5 12h14"></path>
                          <path d="M12 5v14"></path>
                        </svg>
                        <svg className="hs-accordion-active:block hidden h-4 w-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                          <path d="M5 12h14"></path>
                        </svg>
                        <div>{t('users.profile.correspondence_address')}</div>
                      </button>
                      <div id="hs-correspondence-content" className="hs-accordion-content text-start lg:pl-0 pl-5 hidden w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-correspondence">
                        {getCorrespondenceAddress(user?.addresses)}
                      </div>
                    </div>

                    <div className="hs-accordion" id="hs-registered">
                      <button className="hs-accordion-toggle py-3 inline-flex items-center gap-x-3 w-full font-semibold text-start text-gray-800 hover:text-gray-500 focus:outline-none focus:text-gray-500 rounded-lg" aria-expanded="false" aria-controls="hs-registered-content">
                        <svg className="hs-accordion-active:hidden block h-4 w-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                          <path d="M5 12h14"></path>
                          <path d="M12 5v14"></path>
                        </svg>
                        <svg className="hs-accordion-active:block hidden h-4 w-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                          <path d="M5 12h14"></path>
                        </svg>
                        <div>{t('users.profile.registered_address')}</div>
                      </button>
                      <div id="hs-registered-content" className="hs-accordion-content text-start lg:pl-0 pl-5 hidden w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-registered">
                        {getRegisteredAddress(user?.addresses)}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="flex flex-col pt-5 lg:flex-grow">
                <div className="text-gray-500 pb-3 border-b pr-10 flex gap-2 items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                  </svg>
                  {t('users.profile.contact')}
                </div>
                <div className="lg:grid lg:grid-cols-2 grid-cols-1 gap-x-3 gap-y-5 mt-3 max-w-fit self-start lg:self-auto">
                  <div className="lg:flex hidden justify-end text-zinc-500 font-['DM Sans'] text-right">
                    {t('users.profile.phone_number')}:
                  </div>
                  <div className="lg:flex hidden">{user?.telephone_number}</div>
                  <div className="lg:flex hidden justify-end font-['DM Sans'] text-zinc-500 text-right">
                    {t('users.profile.email')}:
                  </div>
                  <div className="lg:flex hidden">{user?.email}</div>
                  <div className="lg:flex hidden justify-end font-['DM Sans'] text-zinc-500 text-right">
                    {t('users.profile.birthday')}:
                  </div>
                  <div className="lg:flex hidden items-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="gray" className="w-5 h-5">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 8.25v-1.5m0 1.5c-1.355 0-2.697.056-4.024.166C6.845 8.51 6 9.473 6 10.608v2.513m6-4.871c1.355 0 2.697.056 4.024.166C17.155 8.51 18 9.473 18 10.608v2.513M15 8.25v-1.5m-6 1.5v-1.5m12 9.75-1.5.75a3.354 3.354 0 0 1-3 0 3.354 3.354 0 0 0-3 0 3.354 3.354 0 0 1-3 0 3.354 3.354 0 0 0-3 0 3.354 3.354 0 0 1-3 0L3 16.5m15-3.379a48.474 48.474 0 0 0-6-.371c-2.032 0-4.034.126-6 .371m12 0c.39.049.777.102 1.163.16 1.07.16 1.837 1.094 1.837 2.175v5.169c0 .621-.504 1.125-1.125 1.125H4.125A1.125 1.125 0 0 1 3 20.625v-5.17c0-1.08.768-2.014 1.837-2.174A47.78 47.78 0 0 1 6 13.12M12.265 3.11a.375.375 0 1 1-.53 0L12 2.845l.265.265Zm-3 0a.375.375 0 1 1-.53 0L9 2.845l.265.265Zm6 0a.375.375 0 1 1-.53 0L15 2.845l.265.265Z" />
                    </svg>
                    {toLocalDate(user?.birthday)}
                  </div>
                  <div className="lg:flex hidden justify-end font-['DM Sans'] text-zinc-500 text-right">
                    {t('users.profile.birth_place')}:
                  </div>
                  <div className="lg:flex hidden">{user?.birth_place}</div>

                  <div className="lg:hidden flex flex-col space-y-3">
                    <div className="flex gap-2 font-['DM Sans']">
                      <div className="text-zinc-500">
                        {t('users.profile.phone_number')}:
                      </div>
                      {user?.telephone_number}
                    </div>
                    <div className="lg:hidden flex gap-2 font-['DM Sans']">
                      <div className="text-zinc-500">
                        {t('users.profile.email')}:
                      </div>
                      {user?.email}
                    </div>
                    <div className="lg:hidden flex gap-2 font-['DM Sans']">
                      <div className="text-zinc-500">
                        {t('users.profile.birthday')}:
                      </div>
                      <div className="flex items-center gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="gray" className="w-5 h-5">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M12 8.25v-1.5m0 1.5c-1.355 0-2.697.056-4.024.166C6.845 8.51 6 9.473 6 10.608v2.513m6-4.871c1.355 0 2.697.056 4.024.166C17.155 8.51 18 9.473 18 10.608v2.513M15 8.25v-1.5m-6 1.5v-1.5m12 9.75-1.5.75a3.354 3.354 0 0 1-3 0 3.354 3.354 0 0 0-3 0 3.354 3.354 0 0 1-3 0 3.354 3.354 0 0 0-3 0 3.354 3.354 0 0 1-3 0L3 16.5m15-3.379a48.474 48.474 0 0 0-6-.371c-2.032 0-4.034.126-6 .371m12 0c.39.049.777.102 1.163.16 1.07.16 1.837 1.094 1.837 2.175v5.169c0 .621-.504 1.125-1.125 1.125H4.125A1.125 1.125 0 0 1 3 20.625v-5.17c0-1.08.768-2.014 1.837-2.174A47.78 47.78 0 0 1 6 13.12M12.265 3.11a.375.375 0 1 1-.53 0L12 2.845l.265.265Zm-3 0a.375.375 0 1 1-.53 0L9 2.845l.265.265Zm6 0a.375.375 0 1 1-.53 0L15 2.845l.265.265Z" />
                        </svg>
                        {toLocalDate(user?.birthday)}
                      </div>
                    </div>
                    <div className="lg:hidden flex gap-2 font-['DM Sans']">
                      <div className="text-zinc-500">
                        {t('users.profile.birth_place')}:
                      </div>
                      {user?.birth_place}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {user?.role === TEACHER_ROLE && user?.public_board && (
              <div className="px-3 mt-5">
                <div className="border bg-white border-gray-200 rounded-2xl pt-5 pb-7 mb-5 ml-1 shadow-sm px-5 text-left">
                  <label className="text-lg font-medium ml-1 px-3">{t('users.profile.public_board')}</label>
                  <div className="pt-5 px-4 font-thin text-sm h-30 max-h-40 overflow-y-auto">
                    {user.public_board}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserProfileView
