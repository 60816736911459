import React, { type ReactElement } from 'react'

const ActiveTabFilter = ({ setFilters, text }: { setFilters: (prevFilters: any) => void, text: string }): ReactElement => {
  return (
    <div
      className="px-3 py-2 bg-sky-100 rounded-lg shadow justify-start items-center gap-2 flex cursor-pointer min-w-fit"
      onClick={setFilters}
    >
      <div className="justify-center items-center gap-2 flex">
        <div className="text-blue-500 lg:text-sm text-base font-medium font-['DM Sans'] leading-normal">{text}</div>
      </div>
    </div>
  )
}

export default ActiveTabFilter
