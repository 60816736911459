import React, { type ReactElement, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useRolePath } from '../../../hooks/useRolePath'
import DropDownIcon from '../../../assets/icons/dropdown.svg'
import {
  MY_CLASS_UNIT_PATH,
  ATTENDANCE_PATH,
  TESTS_PATH,
  MEETINGS_PATH,
  PAYMENTS_PATH,
  GRADES_PATH
} from '../../../constants/routes'
import { HOMEWORKS_PATH, LESSONS_PATH } from '../../../constants/rolePaths'
import NavbarLink from './NavbarLink'
import { CurrentUserContext } from '../../../contexts/users/CurrentUserContext'

const SubBar = (): ReactElement => {
  const { t } = useTranslation('translations')
  const activeTabClass = 'border-b-2 border-blue-600 py-[12px]'
  const defaultTabClass = 'py-[12px]'
  const { currentUser } = useContext(CurrentUserContext)

  return (
    <div className="h-full px-5 h-[44px] border-b border-zinc-200 lg:block hidden bg-white">
      <div className="flex justify-between items-center">
        <div className="justify-between items-start gap-8 flex">
          <NavbarLink path={MY_CLASS_UNIT_PATH} className={defaultTabClass} activeTabClass={activeTabClass}>
            {t('navbar.my_class_unit')}
          </NavbarLink>
          <NavbarLink path={useRolePath(LESSONS_PATH, currentUser)} className={defaultTabClass} activeTabClass={activeTabClass}>
            {t('navbar.subjects')}
          </NavbarLink>
          <NavbarLink path={GRADES_PATH} className={defaultTabClass} activeTabClass={activeTabClass}>
            {t('navbar.grades')}
          </NavbarLink>
          <NavbarLink path={ATTENDANCE_PATH} className={defaultTabClass} activeTabClass={activeTabClass}>
            {t('navbar.attendance')}
          </NavbarLink>
          <NavbarLink path={useRolePath(HOMEWORKS_PATH, currentUser)} className={defaultTabClass} activeTabClass={activeTabClass}>
            {t('navbar.homeworks')}
          </NavbarLink>
          <NavbarLink path={TESTS_PATH} className={defaultTabClass} activeTabClass={activeTabClass}>
            {t('navbar.tests')}
          </NavbarLink>
          <NavbarLink path={MEETINGS_PATH} className={defaultTabClass} activeTabClass={activeTabClass}>
            {t('navbar.meetings')}
          </NavbarLink>
          <NavbarLink path={PAYMENTS_PATH} className={defaultTabClass} activeTabClass={activeTabClass}>
            {t('navbar.payments')}
          </NavbarLink>
        </div>
        <div className="w-[206px] h-5 px-1.5 justify-start items-center gap-[13px] inline-flex">
          <div className="justify-start items-start gap-2 flex">
            <div className="text-gray-500 text-sm font-normal font-['Inter'] leading-tight">Uczeń:</div>
            <div className="text-gray-900 text-sm font-medium font-['Inter'] leading-tight">Michał Kowalski</div>
          </div>
          <img src={DropDownIcon} alt="DropDown" />
        </div>
      </div>
    </div>
  )
}

export default SubBar
