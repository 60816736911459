import React, { type ReactElement } from 'react'
import CommentSection from '../components/comments/CommentSection'
import {
  API_NEWS_COMMENTS_PATH,
  API_NEWS_DETAILS_PATH
} from '../constants/apiRoutes'
import { useParams } from 'react-router-dom'
import { useApiFetch } from '../hooks/api/useApiFetch'
import { Editor } from '@tinymce/tinymce-react'
import DOMPurify from 'dompurify'

const NewsDetailsView = (): ReactElement => {
  const queryParams = useParams()
  const { data: news } = useApiFetch({
    url: API_NEWS_DETAILS_PATH.replace(
      ':news_id',
      queryParams.id as string
    )
  })

  return (
    <div className="flex w-full justify-center">
      <div className="w-1/2">
        <div className="flex justify-center w-full">
          <Editor
            apiKey={process.env.REACT_APP_TINYMC_TOKEN}
            value={DOMPurify.sanitize(news?.content || '<p>No content available.</p>')}
            init={{
              menubar: false,
              toolbar: false,
              statusbar: false,
              disabled: true,
              plugins: ['autoresize'],
              autoresize_bottom_margin: 0,
              autoresize_overflow_padding: 0,
              autoresize_min_height: 100,
              autoresize_max_height: 0,
              height: '100%',
              min_height: 100,
              width: '100%',
              content_style: `
                body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }
                body:focus { outline: none; }
              `,
              setup: (editor) => {
                editor.on('init', () => {
                  editor.getBody().setAttribute('contenteditable', 'false')
                })
                editor.on('click', (event) => {
                  const target = event.target as HTMLElement
                  if (target.tagName === 'A') {
                    const href = target.getAttribute('href')
                    if (href) {
                      window.open(href, '_blank')
                    }
                  }
                })
              }
            }}
          />

        </div>
        <CommentSection
          commentsApiUrl={API_NEWS_COMMENTS_PATH.replace(
            ':news_id',
            queryParams.id as string
          )}
        />
      </div>
    </div>
  )
}

export default NewsDetailsView
