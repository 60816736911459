import {
  STUDENT_HOMEWORKS_PATH,
  STUDENT_DASHBOARD_PATH,
  STUDENT_GRADES_PATH,
  STUDENT_LESSONS_PATH,
  STUDENT_LESSON_PATH
} from './studentRoutes'
import {
  TEACHER_HOMEWORKS_PATH,
  TEACHER_DASHBOARD_PATH,
  TEACHER_GRADES_PATH,
  TEACHER_LESSONS_PATH,
  TEACHER_LESSON_PATH
} from './teacherRoutes'

export const HOMEWORKS_PATH: Record<string, string> = {
  student: STUDENT_HOMEWORKS_PATH,
  teacher: TEACHER_HOMEWORKS_PATH
}

export const DASHBOARD_PATH: Record<string, string> = {
  student: STUDENT_DASHBOARD_PATH,
  teacher: TEACHER_DASHBOARD_PATH
}

export const GRADES_PATH: Record<string, string> = {
  student: STUDENT_GRADES_PATH,
  teacher: TEACHER_GRADES_PATH
}

export const LESSONS_PATH: Record<string, string> = {
  student: STUDENT_LESSONS_PATH,
  teacher: TEACHER_LESSONS_PATH
}

export const LESSON_PATH: Record<string, string> = {
  student: STUDENT_LESSON_PATH,
  teacher: TEACHER_LESSON_PATH
}
