import React, { type ReactElement, useContext } from 'react'
import { ActiveTabContext } from '../../../contexts/common/ActiveTabContext'
import { MobileMenuContext } from '../../../contexts/common/MobileMenuContext'
import { Link } from 'react-router-dom'
import { type NavbarLinkProps } from '../../../constants/types'

const NavbarLink = ({ path, className, activeTabClass, children }: NavbarLinkProps): ReactElement => {
  const { activeTab, setActiveTab } = useContext(ActiveTabContext)
  const { setRenderMobileMenu } = useContext(MobileMenuContext)

  const handleClick = (): void => {
    setActiveTab(path)
    if (setRenderMobileMenu) {
      setRenderMobileMenu(false)
    }
  }

  return (
    <Link
      className={`${activeTab === path ? activeTabClass : className}`}
      to={path}
      onClick={handleClick}
    >
      {children}
    </Link>
  )
}

export default NavbarLink
