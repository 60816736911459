import React, { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { API_ADMIN_DOCUMENTS_PATH } from '../../../constants/apiRoutes'
import DocumentForm from '../../../components/admin/documents/DocumentForm'

const NewDocumentView = (): ReactElement => {
  const { t } = useTranslation('translations')

  return (
    <div className="grid lg:w-1/2" >
      <div className="px-5 lg:px-14 py-8">
        <h1 className="text-2xl lg:text-3xl text-left pb-12">{t('admin.documents.new_header')}</h1>
        <DocumentForm url={API_ADMIN_DOCUMENTS_PATH}/>
      </div>
    </div>
  )
}

export default NewDocumentView
