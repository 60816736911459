import React, { type ReactElement, useState } from 'react'
import { ActiveTabContext } from './ActiveTabContext'

const ActiveTabContextProvider = ({ children }: { children: ReactElement | null }): ReactElement => {
  const [activeTab, setActiveTab] = useState(window.location.pathname)

  return (
    <ActiveTabContext.Provider value={{ activeTab, setActiveTab }}>
      {children}
    </ActiveTabContext.Provider>
  )
}

export default ActiveTabContextProvider
