import React, { type ReactElement } from 'react'
import MessagesList from '../components/messages/MessagesList'
import MessagesSideBar from '../components/messages/MessagesSideBar'
import MessagesContextProvider from '../contexts/messages/MessagesContextProvider'

const MessagesView = (): ReactElement => {
  return (
    <MessagesContextProvider>
      <div className="grid h-[calc(100vh-110px)] place-items-center" >
        <div className="p-8 card border max-h-[calc(100vh-110px)] rounded-md h-3/4 w-3/4 flex divide-x">
          <MessagesSideBar />
          <MessagesList />
        </div>
      </div>
    </MessagesContextProvider>
  )
}

export default MessagesView
