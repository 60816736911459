export const LOGIN_PATH = '/login'
export const MESSAGES_PATH = '/messages'
export const FORGOT_PASSWORD_PATH = '/forgot_password'
export const RESET_PASSWORD_PATH = '/reset_password'
export const MY_CLASS_UNIT_PATH = '/my_class_unit'
export const GRADES_PATH = '/grades'
export const ATTENDANCE_PATH = '/attendance'
export const TESTS_PATH = '/tests'
export const MEETINGS_PATH = '/meetings'
export const PAYMENTS_PATH = '/payments'
export const NEWS_PATH = '/news'
export const NEWS_DETAILS_VIEW = '/news/:id'
export const SETTINGS_PATH = '/settings'
export const USER_PROFILE_PATH = '/users/:id'
export const USER_EDIT_AVATAR_PATH = '/users/:id/edit_avatar'
export const CLASS_UNIT_PATH = '/class_units/:id'
export const ADVANCED_SEARCHING_PATH = '/advanced_searching'
