import React, { useState, useEffect, type ReactElement, type ChangeEvent } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useApiPost } from '../../hooks/api/useApiPost'
import { LOGIN_PATH } from '../../constants/routes'
import { map } from 'underscore'
import { useTranslation } from 'react-i18next'
import { API_RESET_PASSWORD_PATH, API_VALIDATE_RESET_PASSWORD_TOKEN_PATH } from '../../constants/apiRoutes'

const ForgotPasswordForm = (): ReactElement => {
  const { sendData, validationErrors, setInput } = useApiPost({ url: API_RESET_PASSWORD_PATH, redirect: LOGIN_PATH })
  const { sendData: validateToken } = useApiPost({ url: API_VALIDATE_RESET_PASSWORD_TOKEN_PATH })

  const [showPassword, setShowPassword] = useState(false)
  const [searchParams] = useSearchParams()
  const { t } = useTranslation('translations')

  useEffect(() => {
    const validateResetPasswordToken = async (): Promise<void> => {
      const resetPasswordToken = searchParams.get('token') as string
      await validateToken({ additionalParams: { reset_password_token: resetPasswordToken } })
    }

    void validateResetPasswordToken()
  }, [])

  const resetUserPassword = async (e: ChangeEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()

    const resetPasswordToken = searchParams.get('token') as string
    await sendData({ additionalParams: { reset_password_token: resetPasswordToken } })
  }

  return (
    <form onSubmit={resetUserPassword}>
      <label>{t('labels.password')}</label>
      <br />
      <input
        type={showPassword ? 'text' : 'password'}
        data-test-id="passwordInput"
        className="px-2 py-1 border rounded-md w-full"
        onChange={(e) => { setInput({ password: e.target.value }) }}
      />
      <div onClick={() => { setShowPassword(!showPassword) }} className='cursor-pointer'>
        {t('reset_password_view.show_password')}
      </div>
      <div className="text-sm text-red-500">
        {map(validationErrors.password, (error: string): string => `Password ${error}`).join(', ')}
      </div>
      <br />
      <br />
      <div className="flex justify-center pt-3">
        <button type="submit" className="bg-blue-400 rounded-md text-white p-2">
          {t('reset_password_view.submit_button')}
        </button>
      </div>
    </form>
  )
}

export default ForgotPasswordForm
