import React, { type ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { ADMIN_EDIT_CLASSROOM_PATH } from '../../../constants/adminRoutes'
import { type ClassroomData } from '../../../constants/types'
import { useTranslation } from 'react-i18next'
import { useParseDate } from '../../../hooks/useParseDate'

const ClassroomListItem = ({ classroom }: { classroom: ClassroomData }): ReactElement => {
  const { t } = useTranslation('translations')
  const { toLocalDate } = useParseDate()

  return (
    <div className="card grid grid-cols-12 py-3 px-5 text-sm flex items-center">
      <div className="col-span-3 truncate">{classroom?.id}</div>
      <div className="col-span-4 truncate">{classroom?.number}</div>
      <div className="col-span-4 truncate">{toLocalDate(classroom?.created_at)}</div>
      <div className="col-span-1">
        <Link
          to={ADMIN_EDIT_CLASSROOM_PATH.replace(':id', classroom?.id?.toString())}
          className="px-4 py-1 w-max border border-1 border-[#3758F9] rounded-2xl text-[#3758F9]"
        >
          {t('admin.classrooms.edit_button')}
        </Link>
      </div>
    </div>
  )
}

export default ClassroomListItem
